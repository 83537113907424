import React, { FC } from 'react'
//
import { CircularProgress } from '../../atoms'
import { CenterAlignmentTemplate } from '../../templates'

/**
 * Presenter
 */

export const InitializePagePresenter: FC = () => {
  return (
    <CenterAlignmentTemplate>
      <CircularProgress />
    </CenterAlignmentTemplate>
  )
}
