import * as Domain from '../domain'

type Products = Array<Domain.Product.Entity>

export function createInitialState(): Products {
  return []
}

export const SET_PRODUCTS = 'products/set' as const

export function setProducts(products: Products) {
  return {
    type: SET_PRODUCTS,
    payload: {
      products,
    },
  }
}

type SetAction = ReturnType<typeof setProducts>

export type Action = SetAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_PRODUCTS:
      return action.payload.products
    default:
      return state
  }
}
