export default {
  '@global': {
    '*, *::before, *::after': {
      boxSizing: 'border-box'
    },
    '.selectbox-label': {
      cursor: 'pointer',
      userSelect: 'none'
    },
    '.nav-link': {
      textDecoration: 'underline',
      cursor: 'pointer',
      textUnderlineOffset: '3px'
    }
  }
}