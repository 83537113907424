import * as Domain from '../domain'

type Pagination = Domain.Pagination.Entity

export function createInitialState(): Pagination {
  return {
    currentPage: 0,
    perPage: 50,
    totalPage: 0,
    totalCount: 0,
    rowsPerPageOptions: [25, 50, 100],
  }
}

export const SET_PAGINATION = 'pagination/set' as const

export function setPagination(pagination: Pagination) {
  return {
    type: SET_PAGINATION,
    payload: {
      pagination,
    },
  }
}

type SetAction = ReturnType<typeof setPagination>

export type Action = SetAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_PAGINATION:
      return action.payload.pagination
    default:
      return state
  }
}
