import { combineReducers } from "redux";
import * as types from '../../constants/types';

const initialState: Array<string> = []

export function notice(state = initialState, action: {type: string, payload: any}) {
  switch (action.type) {
    case types.SET_NOTICE:
      const found = state.find(er => er === action.payload)
      if (!found) {
        state.push(action.payload)
      }
      return [...state]
    case types.REMOVE_NOTICE:
      const temp = state.filter((err) => err !== action.payload)
      return temp
    case types.CLEAR_NOTICE:
      return []
    default:
      return state
  }
}

const noticeReducer = combineReducers({
  notice
})

export default noticeReducer
