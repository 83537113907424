import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Prompt, useHistory } from 'react-router-dom'
import { post } from '../../../../libs/api'
import { Box, Button, DataObjectTable, Divider, Typography, Icon } from '../../../atoms'
import { ObjectData } from '../../../atoms/DataObjectTable'
import { SectionPanel } from '../../../molecules'
import { hooks } from '../../../../libs'
import { AUDIENCE_LABELS, AUDIENCE_CONDITIONS } from '../utility/AudienceData'
import { ModalsContext, NoticeContext } from '../../../../contexts'
import { useTranslation } from 'react-i18next'
import { AudienceInfo } from '../../../../stores/audience/audience.action'
import { NoticeCreateAudienceFailedModal, NoticeCreateAudienceSuccessModal } from './modals'
import { NoticeProvider } from '../../../../contexts/NoticeContext'
import { setLoading } from '../../../../stores/loading/loading.action'
import { dropByCacheKey } from 'react-router-cache-route'

const labelsForAudienceCardType = {
  audienceName: 'オーディエンス名',
  audienceType: '条件',
  audienceUsers: '抽出数',
}

const labelsForAudienceStoreType = {
  ...labelsForAudienceCardType,
  audienceStoresQuantity: '店舗数',
  audienceStores: '店舗コード\n店舗名'
}

class ObjectDataConverter {
  audienceName: string
  audienceStores?: string
  audienceStoresQuantity?: number
  audienceType: string
  audienceUsers: number

  constructor(data: any) {
    this.audienceName = data.audienceName
    this.audienceType = AUDIENCE_LABELS[data.audienceType]
    this.audienceUsers = data.count
    if ([AUDIENCE_CONDITIONS.PATTERN1, AUDIENCE_CONDITIONS.PATTERN2].includes(+data.audienceType)) {
      this.audienceStores = data.audienceStores.map((x: any) => `${x.storeCd}_${x.storeName}`).join('\n')
      this.audienceStoresQuantity = data.audienceStores.length
    }
  }
}

class ConfirmCreateAudienceBody {
  audienceName: string
  type: string
  audienceSize: number
  pathFile: string
  storeCds: string[]
  storeNames: string[]

  constructor(data: AudienceInfo) {
    this.audienceName = data.audienceName
    this.type = data.audienceType
    this.audienceSize = data.count
    this.pathFile = data.fileS3
    this.storeCds = data.audienceStores.map(x => x.storeCd)
    this.storeNames = data.audienceStores.map(x => x.storeName)
  }
}

export const AudienceConfirmContent = () => {
  const { t } = useTranslation('audience')
  const { tenantId } = hooks.useTenant()
  const { accessToken } = hooks.useAuth()
  const { setNoticeTitle, clearNotice } = React.useContext(NoticeContext)
  const { closeAllModals, changeModal } = React.useContext(ModalsContext)
  const [data, setData] = React.useState<ObjectData>({})
  const [labels, setLabels] = React.useState({})
  const history = useHistory()
  const dispatch = useDispatch()
  const [isBlocking, setIsBlocking] = React.useState<boolean>(false)
  const newAudienceInfo = useSelector((state: any) => state?.audienceReducer?.newAudienceInfo?.data)

  React.useEffect(() => {
    if (newAudienceInfo) {
      setData(new ObjectDataConverter(newAudienceInfo))
      if ([AUDIENCE_CONDITIONS.PATTERN1, AUDIENCE_CONDITIONS.PATTERN2].includes(+newAudienceInfo.audienceType)) {
        setLabels(labelsForAudienceStoreType)
      } else if ([AUDIENCE_CONDITIONS.PATTERN3, AUDIENCE_CONDITIONS.PATTERN4].includes(+newAudienceInfo.audienceType)) {
        setLabels(labelsForAudienceCardType)
      }
      setIsBlocking(true)
      window.onbeforeunload = () => (true)
    } else {
      history.push('./create')
    }
  }, [newAudienceInfo])

  React.useEffect(() => {
    return (() => {
      window.onbeforeunload = null
    })
  }, [])

  const gotoAudienceList = () => {
    closeAllModals()
    history.push('./')
  }

  const confirmCreateAudienceApi = async () => {
    const body = new ConfirmCreateAudienceBody(newAudienceInfo)
    dispatch(setLoading(true))
    try {
      await post('audiences/create', accessToken, tenantId, body)
      clearNotice()
      window.onbeforeunload = null
      setIsBlocking(false)
      changeModal(<NoticeCreateAudienceSuccessModal pieces={body.audienceSize} onSubmit={gotoAudienceList}/>)
      dispatch(setLoading(false))
      dropByCacheKey('AudienceCreatePage')
    } catch(e) {
      if (e.errorCode === '40076') {
        changeModal(<NoticeCreateAudienceFailedModal/>)
      } else {
        setNoticeTitle(t('error.confirm.title'))
      }
      dispatch(setLoading(false))
    }
  }

  return (
    <>
      <Box>
        <Prompt
          when={isBlocking}
          message='条件の指定へ戻るとオーディエンス抽出結果が失われます。よろしいですか？'
        />
        <Box pb={3}>
          <Typography variant="h6">
            以下の内容でオーディエンスを設定します。
          </Typography>
        </Box>
        <Box pb={3}>
          <Divider />
        </Box>
        <Box>
          <DataObjectTable data={data} labels={labels}/>
        </Box>
      </Box>
      <Box pt={2} pb={9} display="flex" justifyContent="flex-end">
        <Typography component="div" align="right">
          <Box pb={2}>
            <Button
              startIcon={<Icon iconName="list-ul"/>}
              variant="contained"
              onClick={confirmCreateAudienceApi}
              color="primary">
              オーディエンスを設定する
            </Button>
          </Box>
          <Box color="primary.main" clone>
            <Typography component={Link} to={'./create'} className="nav-link">
              ＜ 条件の指定へ戻る
            </Typography>
          </Box>
        </Typography>
      </Box>
    </>
  )
}


export const AudienceConfirmPage = () => {
  return (
    <Box mb={3}>
      <SectionPanel>
        <Box px={7} py={3}>
          <NoticeProvider>
            <AudienceConfirmContent/>
          </NoticeProvider>
        </Box>
      </SectionPanel>
    </Box>
  )
}