import React, { FC } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
//
import { Box, Button, Icon } from '../../atoms'

/**
 * interface
 */

export interface LoginSectionPresenterProps {
  actions: {
    handleLogin: () => void
  }
}

export interface LoginSectionProps {
  handleLogin: () => void
}

/**
 * Style
 */

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    logoType: {
      textAlign: 'center',
    },
    inner: {
      width: '400px',
      padding: '40px',
    },
    sectionTitle: {
      textAlign: 'center',
      marginBottom: '2rem',
    },
    sectionBody: {
      textAlign: 'left',
      marginBottom: '2rem',
    },
  }),
)

/**
 * Presenter
 */

export const LoginSectionPresenter: FC<LoginSectionPresenterProps> = props => {
  const {
    actions: { handleLogin },
  } = props
  const classes = useStyles({})
  return (
    <div>
      <h1 className={classes.logoType}>LINE管理画面</h1>
      <Box boxShadow={2}>
        <div className={classes.inner}>
          <section>
            <h1 className={classes.sectionTitle}>ログイン</h1>
            <p className={classes.sectionBody}>アカウントをお持ちの方は、 こちらよりログインしてください。</p>
            <Button
              variant="contained"
              color="primary"
              fullWidth={true}
              size="large"
              startIcon={<Icon iconName="sign-in-alt" size="xs" />}
              onClick={handleLogin}
            >
              ログイン
            </Button>
          </section>
        </div>
      </Box>
    </div>
  )
}

/**
 * Combine
 */

export const LoginSection: FC<LoginSectionProps> = props => {
  const { handleLogin } = props
  return <LoginSectionPresenter actions={{ handleLogin }} />
}
