import { COLORS } from '../constants'

export default {
  primary: {
    light: COLORS.CXD_BLUE_600,
    main: COLORS.CXD_BLUE_700,
    dark: COLORS.CXD_BLUE_800,
    contrastText: COLORS.CXD_WHITE,
  },
  secondary: {
    light: COLORS.RED_1,
    main: COLORS.RED_1,
    dark: COLORS.CXD_RED_900,
    contrastText: COLORS.CXD_WHITE,
  },
  default: {
    50: COLORS.CXD_GRAY_50,
    100: COLORS.CXD_GRAY_100,
    200: COLORS.CXD_GRAY_200,
    300: COLORS.CXD_GRAY_300,
    400: COLORS.CXD_GRAY_400,
    500: COLORS.CXD_GRAY_500,
    600: COLORS.CXD_GRAY_600,
    700: COLORS.CXD_GRAY_700,
    800: COLORS.CXD_GRAY_800,
    900: COLORS.CXD_GRAY_900,
  },
  brand: {
    50: COLORS.CXD_BRAND_50,
    100: COLORS.CXD_BRAND_100,
    200: COLORS.CXD_BRAND_200,
    300: COLORS.CXD_BRAND_300,
    400: COLORS.CXD_BRAND_400,
    500: COLORS.CXD_BRAND_500,
    600: COLORS.CXD_BRAND_600,
    700: COLORS.CXD_BRAND_700,
    800: COLORS.CXD_BRAND_800,
    900: COLORS.CXD_BRAND_900,
  },
  informative: {
    50: COLORS.CXD_BLUE_50,
    100: COLORS.CXD_BLUE_100,
    200: COLORS.CXD_BLUE_200,
    300: COLORS.CXD_BLUE_300,
    400: COLORS.CXD_BLUE_400,
    500: COLORS.CXD_BLUE_500,
    600: COLORS.CXD_BLUE_600,
    700: COLORS.CXD_BLUE_700,
    800: COLORS.CXD_BLUE_800,
    900: COLORS.CXD_BLUE_900,
  },
  negative: {
    50: COLORS.CXD_RED_50,
    100: COLORS.CXD_RED_100,
    200: COLORS.CXD_RED_200,
    300: COLORS.CXD_RED_300,
    400: COLORS.CXD_RED_400,
    500: COLORS.CXD_RED_500,
    600: COLORS.CXD_RED_600,
    700: COLORS.CXD_RED_700,
    800: COLORS.CXD_RED_800,
    900: COLORS.CXD_RED_900,
  },
  notice: {
    50: COLORS.CXD_ORANGE_50,
    100: COLORS.CXD_ORANGE_100,
    200: COLORS.CXD_ORANGE_200,
    300: COLORS.CXD_ORANGE_300,
    400: COLORS.CXD_ORANGE_400,
    500: COLORS.CXD_ORANGE_500,
    600: COLORS.CXD_ORANGE_600,
    700: COLORS.CXD_ORANGE_700,
    800: COLORS.CXD_ORANGE_800,
    900: COLORS.CXD_ORANGE_900,
  },
  positive: {
    50: COLORS.CXD_GREEN_50,
    100: COLORS.CXD_GREEN_100,
    200: COLORS.CXD_GREEN_200,
    300: COLORS.CXD_GREEN_300,
    400: COLORS.CXD_GREEN_400,
    500: COLORS.CXD_GREEN_500,
    600: COLORS.CXD_GREEN_600,
    700: COLORS.CXD_GREEN_700,
    800: COLORS.CXD_GREEN_800,
    900: COLORS.CXD_GREEN_900,
  },
  grey: {
    50: COLORS.CXD_GRAY_50,
    100: COLORS.CXD_GRAY_100,
    200: COLORS.CXD_GRAY_200,
    300: COLORS.CXD_GRAY_300,
    400: COLORS.CXD_GRAY_400,
    500: COLORS.CXD_GRAY_500,
    600: COLORS.CXD_GRAY_600,
    700: COLORS.CXD_GRAY_700,
    800: COLORS.CXD_GRAY_800,
    900: COLORS.CXD_GRAY_900,
  },
  text: {
    primary: COLORS.CXD_GRAY_900,
    secondary: COLORS.CXD_GRAY_700,
    disabled: COLORS.CXD_GRAY_500,
    hint: COLORS.CXD_GRAY_600,
  },
}
