import React from 'react'
import { functions } from '../../../../../libs'
import { ErrorOutline, TabletMacOutlined, CalendarToday } from '@material-ui/icons'
import { Typography, makeStyles } from '@material-ui/core'
import * as EVENT from '../../../../../constants/event'
import * as COLORS from '../../../../../constants/color'
import { format } from 'date-fns'
import { useSelector } from 'react-redux'
const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 20,
  },
  icon: {
    fontSize: 20,
    marginRight: 5,
    display: 'flex',
  },
}))

interface EventStatusProps {
  buttonName?: string
  updatedAt?: string
  startDate?: string
}

export const EventStatus = (props: EventStatusProps) => {
  const { updatedAt, startDate, buttonName } = props
  const classes = useStyles({})
  const dateFormat = 'yyyy/MM/dd HH:mm'

  const [messageParam, setMessageParam] = React.useState({
    icon: <></>,
    color: COLORS.CXD_GRAY_500,
    text: '',
  })

  const storeEvent = useSelector((state: any) => state?.eventReducer?.event)

  React.useEffect(() => {
    switch (buttonName) {
      case EVENT.SAVE_BTN_TITLE:
        setMessageParam({
          icon: <TabletMacOutlined />,
          color: COLORS.CXD_GRAY_500,
          text: EVENT.SAVE_MESSAGE,
        })
        break
        case EVENT.RESERVE_OPEN_BTN_TITLE:
          setMessageParam({
          icon: <CalendarToday />,
          color: COLORS.CXD_BLUE_600,
          text: EVENT.RESERVED_MSG,
        })
        break
      case EVENT.RESERVE_CANCEL_BTN_TITLE:
        setMessageParam({
          icon: <ErrorOutline />,
          color: COLORS.CXD_GRAY_500,
          text: EVENT.CANCELED_MSG,
        })
        break
      default:
        setMessageParam({
          icon: <></>,
          color: '',
          text: '',
        })
        break
    }
  }, [storeEvent])

  return (
    <>
      {updatedAt && buttonName && (
        <div className={classes.wrapper} style={{ color: messageParam.color }}>
          <div className={classes.icon}>{messageParam.icon}</div>
          <Typography variant="body2">
            {functions.common.changText(
              messageParam.text,
              format(new Date(updatedAt), dateFormat),
              startDate ? format(new Date(startDate), dateFormat) : '',
            )}
          </Typography>
        </div>
      )}
    </>
  )
}
