import React from 'react'
import { Link } from 'react-router-dom'
import { makeStyles, createStyles, Button, Box } from '@material-ui/core'
const BACK_BTN_LABEL = '戻る'

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      position: 'relative',
      height: '100vh',
      width: '100vw'
    },
    errSection: {
      position: 'absolute',
      top: '45%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    },
    message: {
      textAlign: 'center',
      fontSize: 20
    },
    btnWrap: {
      display: 'flex',
    },
    btn: {
      margin: 'auto'
    }
  }),
)

const LinkComponent = (props: any) => <Link to="/" {...props} />

const UnauthorizedPage = (props: any) => {
  const classes = useStyles({})
  return (
    <div className={classes.container}>
      <section className={classes.errSection}>
        <p className={classes.message}>{props.location.state.errorDescription}</p>
        <Box className={classes.btnWrap}>
          <Button
            variant="contained"
            color="primary"
            component={LinkComponent}
            className={classes.btn}
          >
            { BACK_BTN_LABEL }
          </Button>
        </Box>
      </section>
    </div>
  )
}

export default UnauthorizedPage
