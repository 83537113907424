import React, { FC, forwardRef } from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { NavLink, NavLinkProps, useLocation } from 'react-router-dom'
import { hooks } from '../../../libs'
import { TenantRoleLabel } from '../../molecules'
import { List, Divider, Typography, Box, ListItem, ListItemIcon, ListItemText, Icon } from '../../atoms'
import { Domain } from '../../../features'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import { useTranslation } from 'react-i18next'

const ACCOUNT_TITLE = 'アカウント'
const AUTHORITY = '権限'

const tenantSidebarMenu: Array<Domain.Navigation.SidebarMenuItems> = [
  {
    name: 'title.richmenu',
    path: `richmenu`,
    icon: 'th-list',
    key: 'richmenu',
    type: 1,
  },
  {
    name: 'title.audience',
    path: `audience`,
    icon: 'th-list',
    key: 'audience',
    type: 2,
    submenu: [
      {
        label: 'create',
        path: 'create',
      },
      {
        label: 'history',
        path: '',
      },
    ],
  },
  {
    name: 'title.event',
    path: `events`,
    icon: 'th-list',
    key: 'event',
    type: 3,
    submenu: [
      {
        label: 'create',
        path: 'create',
      },
      {
        label: 'list',
        path: '',
      },
    ],
  },
]

const useStyles = makeStyles(theme =>
  createStyles({
    textLabel: {
      fontSize: 14,
      marginBottom: 5,
    },
    authority: {
      fontSize: 14,
      margin: '20px 0 10px',
    },
    menuItem: {
      '&.active': {
        background: 'rgba(0, 0, 0, 0.08)',
      },
    },
    menuParentLabel: {
      marginTop: 24,
      paddingLeft: 16,
    },
    menuItemIcon: {
      minWidth: 32,
    },
    menuItemText: {
      '& > span': {
        fontSize: '14px',
      },
    },
    menuItemChildren: {
      paddingTop: 8,
      paddingBottom: 8,
      backgroundColor: theme.palette.background.default,
    },
    submenuSidebar: {
      minWidth: '8rem',
      transform: 'translateX(5rem) !important',
    },
  }),
)

const ListItemDropdown = (propsC: any) => {
  const location = useLocation()
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const { t } = useTranslation()

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <ListItem button selected={location.pathname.includes(propsC.menu.path)} onClick={handleClick} component={Button}>
        {propsC.menu.icon && (
          <ListItemIcon className={classes.menuItemIcon}>
            <Icon size="1x" iconName={propsC.menu.icon} />
          </ListItemIcon>
        )}
        <ListItemText className={classes.menuItemText} primary={t(propsC.menu.name)} />
      </ListItem>
      <Menu
        classes={{ paper: classes.submenuSidebar }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        onClose={handleClose}
      >
        {propsC.menu.submenu.map((sub: Domain.Navigation.SidebarChildMenuItem, index: number) => (
          <MenuItem
            key={`a-${index}`}
            onClick={handleClose}
            to={`/${propsC.currentTenant.tenantId}/${propsC.menu.path}/${sub.path}`}
            component={NavLink}
          >
            {t(sub.label)}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export const SidebarMenuPresenter: FC = () => {
  const classes = useStyles()
  const {
    history: {
      location: { pathname },
    },
  } = hooks.useReactRouter()
  const { tenantId } = hooks.useTenant()
  const { tenants } = hooks.useAuth()
  const { t } = useTranslation()
  const [currentTenant, setCurrentTenant] = React.useState<Domain.Auth.TenantAuth0Interface>()

  React.useEffect(() => {
    if (tenants && tenants.length) {
      const temp = tenants.find((tenant: any) => {
        return tenant.tenantId === tenantId
      })
      setCurrentTenant(temp)
    }
  }, [tenants, tenantId])

  const ListItemRender = (propsC: any) => {
    if (propsC.menu.type === 1) {
      return (
        <ListItem
          button
          to={`/${propsC.currentTenant.tenantId}/${propsC.menu.path}`}
          selected={pathname.includes(propsC.menu.path)}
          component={forwardRef((propsT: NavLinkProps, ref: any) => (
            <NavLink {...propsT} ref={ref} exact />
          ))}
        >
          {propsC.menu.icon && (
            <ListItemIcon className={classes.menuItemIcon}>
              <Icon size="1x" iconName={propsC.menu.icon} />
            </ListItemIcon>
          )}
          <ListItemText className={classes.menuItemText} primary={t(propsC.menu.name)} />
        </ListItem>
      )
    } else if (propsC.menu.type === 2) {
      return <ListItemDropdown menu={propsC.menu} currentTenant={currentTenant} />
    } else if (propsC.menu.type === 3) {
      return <ListItemDropdown menu={propsC.menu} currentTenant={currentTenant} />
    } else {
      return <></>
    }
  }

  return (
    <>
      <Box p={2}>
        <Box mb={1}>
          <Typography className={classes.textLabel} display="block">
            {ACCOUNT_TITLE}
          </Typography>
          <Typography variant="subtitle1" display="block">
            {currentTenant && currentTenant.tenantNameJP}
          </Typography>
        </Box>
        <Box>
          <Typography className={classes.authority} display="block">
            {AUTHORITY}
          </Typography>
          <TenantRoleLabel />
        </Box>
      </Box>
      <Divider />
      <Box py={1}>
        {currentTenant && (
          <List component="nav" disablePadding>
            {tenantSidebarMenu.map((menu: Domain.Navigation.SidebarMenuItems, index) => {
              return (
                currentTenant.permissions[menu.key] && (
                  <ListItemRender key={index} menu={menu} currentTenant={currentTenant} />
                )
              )
            })}
          </List>
        )}
      </Box>
    </>
  )
}

export const SidebarMenu: FC = () => {
  return <SidebarMenuPresenter />
}
