import React, { FC } from 'react'
//
import { SidebarMenu } from '../../molecules'

export const SidebarPresenter: FC = () => {
  return <SidebarMenu />
}

export const Sidebar: FC = () => {
  return <SidebarPresenter />
}
