import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core'
import * as React from 'react'
import { Controller } from 'react-hook-form'
import { NoticeContext } from '../../../../../contexts'
import { useTranslation } from 'react-i18next'
import { Box } from '../../../../atoms'
import { AudienceStoreSelect } from '../components/AudienceStoreSelect'
import Renderer from '../../../../molecules/Renderer'
import { NoticeProvider } from '../../../../../contexts/NoticeContext'

interface AudienceStoreTplProps {
  control: any
  errors: any
  setValue: any
  watchAudienceStores: any
  storesHandler: any
}

const AudienceStoreTpl: React.FC<AudienceStoreTplProps> = props => {
  const { control, errors, setValue, watchAudienceStores, storesHandler } = props
  const { t } = useTranslation(['audience', 'translation'])
  const { setNoticeTitle, pushNoticeContent, clearNotice } = React.useContext(NoticeContext)

  React.useEffect(() => {
    if (storesHandler.hasError) {
      setNoticeTitle(t('translation:notice.fetchData.title'))
      pushNoticeContent(t('translation:notice.fetchData.content.errorFetch'))
    }
    if (!storesHandler.hasError && storesHandler.data) {
      clearNotice()
    }
  }, [storesHandler])

  const selectAllStores = (event: any) => {
    event.persist()
    if (storesHandler?.data) {
      if (event.target.checked) {
        setValue('audienceStores', storesHandler.data?.storeList, { shouldValidate: true })
      } else {
        setValue('audienceStores', [], { shouldValidate: true })
      }
    }
  }

  return (
    <>
      {!storesHandler.hasError && (
        <>
          <Box pb={3} clone>
            <Typography variant="h6">{t('storeSection.storeSelect')}</Typography>
          </Box>
          <Box bgcolor="grey.300">
            <Grid container spacing={1}>
              <Box display="flex" alignItems="center" clone>
                <Grid item xs={6}>
                  <Box pl={8} clone>
                    <Typography variant="h6">{t('storeSection.storeName')}</Typography>
                  </Box>
                </Grid>
              </Box>
              <Box display="flex" alignItems="center" justifyContent="flex-end" clone>
                <Grid item xs={6}>
                  <Box pr={3}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          onChange={selectAllStores}
                          checked={
                            storesHandler.data?.storeList.length === watchAudienceStores.length &&
                            watchAudienceStores.length > 0
                          }
                        />
                      }
                      label={t('storeSection.checkAll')}
                    />
                  </Box>
                </Grid>
              </Box>
            </Grid>
          </Box>
          <Controller
            name="audienceStores"
            control={control}
            render={({ field }) => <AudienceStoreSelect {...field} stores={storesHandler.data?.storeList} />}
          />
        </>
      )}
    </>
  )
}

const AudienceStoreRenderer = (props: any) => {
  return (
    <NoticeProvider>
      <AudienceStoreTpl {...props} />
    </NoticeProvider>
  )
}

export const AudienceStoreSection = Renderer(AudienceStoreRenderer)
