// redux/root-reducer.js
import { combineReducers } from 'redux';

import richmenuReducer from './richmenu/richmenu.reducer';
import authReducer from './auth/auth.reducer';
import loadingReducer from './loading/loading.reducer';
import noticeReducer from './notice/notice.reducer';
import audienceReducer from './audience/audience.reducer';
import eventReducer from './event/event.reducer';

const rootReducer = combineReducers({
  richmenuReducer,
  audienceReducer,
  eventReducer,
  authReducer,
  loadingReducer,
  noticeReducer
});

export default rootReducer
