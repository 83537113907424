import React from 'react'
import querystring from 'query-string'
import { SubmenuType } from '../../../../features/domain/richmenu'
import { RedirectUriType } from '../../../../features/enum'
import { SubmenuInterface } from '../RichmenuCreatePage'
import { useTenant } from '../../../../libs/hooks'
import { Divider, makeStyles, Tooltip, Typography } from '@material-ui/core'
import { COLORS, RICHMENU } from '../../../../constants'
import * as EVENT from '../../../../constants/event'

const useStyles = makeStyles({
  containerActive: {
    width: '20%',
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
    height: '70px',
  },
  containerDisable: {
    width: '20%',
    display: 'flex',
    justifyContent: 'center',
    height: '70px',
  },
  wrapper: {
    maxWidth: '70px',
    maxHeight: '70px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  imageWrapper: {
    lineHeight: '0',
    padding: '3px',
  },
  imageWrapperSelected: {
    lineHeight: '0',
    padding: '3px',
    backgroundColor: '#096bcc',
  },
  image: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  toolTipImage: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  noneSubMenuList: {
    paddingLeft: '32px',
  },
  customWidth: {
    minWidth: 350,
    maxWidth: 350,
  },
  container: {
    display: 'flex',
  },
  leftContent: {
    padding: 20,
  },
  rightContent: {
    padding: '20px 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'start',
    overflow: 'hidden',
  },
  rightContentWrapper: {
    width: 160,
  },
  contentWrapper: {
    padding: '12px',
    height: '100%',
  },
  imageBackground: {
    width: 120,
    height: 120,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  divider: {
    backgroundColor: '#ccc',
    minWidth: '100%',
    margin: '8px 0',
  },
  label: {
    fontSize: 10,
    margin: '0',
    color: '#eee',
  },
  subMenuId: {
    fontSize: '14px',
    margin: '5px 0',
    whiteSpace: 'nowrap',
  },
  fileName: {
    fontSize: '14px',
    margin: '5px 0',
    width: '100%',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 3,
    overflow: 'hidden',
    overflowWrap: 'break-word',
    wordBreak: 'break-all',
  },
})

type SubMenuListProps = {
  subMenu: SubmenuType[]
  subMenuId: string
  setSubMenuId: React.Dispatch<React.SetStateAction<string>>
  areaData: any
  setAreaData: any
}
export const SubMenuList: React.FC<SubMenuListProps> = props => {
  const { subMenu, subMenuId, setSubMenuId, areaData, setAreaData } = props
  const { tenantId } = useTenant()
  const classes = useStyles()

  const onClickSubmenu = (currentSubmenu: SubmenuInterface) => {
    if (!!areaData.isDisabled) return
    const radioValue = areaData.redirectUriType
    if (radioValue === RedirectUriType.SUBMENU) {
      setSubMenuId(currentSubmenu.subMenuId)
      setAreaData({
        ...areaData,
        uri: '',
        type: 'postback',
        data: querystring.stringify({ action: 'subMenu', subMenuId: currentSubmenu.subMenuId, tenantId }),
      })
    }
  }

  const toolTipTitle: React.FC<SubmenuInterface> = props => {
    const tempArray = props.baseUrl.split('/').reverse()
    const fileName = tempArray ? tempArray[1] : ''

    return (
      <>
        <div className={classes.container}>
          <div className={classes.leftContent}>
            <div className={classes.imageBackground}>
              <img alt="" src={props.baseUrl} className={classes.toolTipImage} />
            </div>
          </div>
          <div className={classes.rightContent}>
            <div className={classes.rightContentWrapper}>
              <p className={classes.label}>{EVENT.TOOL_TIP_SUB_MENU_ID}</p>
              <p className={classes.subMenuId}>{props.subMenuId}</p>
              <Divider className={classes.divider} />
              <p className={classes.label}>{EVENT.TOOL_TIP_IMAGE_FILE_NAME}</p>
              <p className={classes.fileName}>{fileName}</p>
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {!!subMenu && subMenu.length > 0 ? (
          subMenu.map((item, index) => {
            return (
              <div
                className={`${
                  areaData.redirectUriType === RedirectUriType.SUBMENU && !areaData.isDisabled
                    ? classes.containerActive
                    : classes.containerDisable
                } disabled-submenu`}
                onClick={() => onClickSubmenu(item)}
                key={item.subMenuId}
              >
                <div className={classes.wrapper}>
                  <div
                    className={`
                      ${
                        subMenuId === item.subMenuId &&
                        areaData.redirectUriType === RedirectUriType.SUBMENU &&
                        !areaData.isDisabled
                          ? classes.imageWrapperSelected
                          : classes.imageWrapper
                      }`}
                  >
                    {areaData.redirectUriType === RedirectUriType.SUBMENU && !areaData.isDisabled ? (
                      <Tooltip
                        title={toolTipTitle(item)}
                        classes={{ tooltip: classes.customWidth }}
                        placement={index < 5 ? 'top' : 'bottom'}
                        arrow
                      >
                        <img src={item.baseUrl} className={classes.image}></img>
                      </Tooltip>
                    ) : (
                      <img src={item.baseUrl} className={classes.image}></img>
                    )}
                  </div>
                </div>
              </div>
            )
          })
        ) : (
          <div>
            <Typography
              variant="body2"
              className={classes.noneSubMenuList}
              style={!!areaData.isDisabled ? { color: COLORS.CXD_GRAY_2 } : {}}
            >
              {RICHMENU.NONE_SUBMENU_MSG}
            </Typography>
          </div>
        )}
      </div>
    </>
  )
}
