import { format } from 'date-fns'
import React, { FC } from 'react'
import { MODAL_SUBMIT_NOTI_CONTENT, MODAL_SUBMIT_NOTI_TITLE,  } from '../../../../constants/richmenu'
import { JP_DATE_FORMAT } from '../../../../constants/utility'
import { changText } from '../../../../libs/functions/common'
import { PrimaryNoticeModal } from '../../../molecules/ModalContainer/PrimaryDialogTemplate/PrimaryNoticeDialog'

interface NoticeSubmitModalProps {
  triggerTime: string
}

export const NoticeSubmitModal: React.FC<NoticeSubmitModalProps> = props => {
  return (
    <PrimaryNoticeModal
      header={MODAL_SUBMIT_NOTI_TITLE}
      content={changText(MODAL_SUBMIT_NOTI_CONTENT, format(new Date(props.triggerTime), JP_DATE_FORMAT))}
    />
  )
}
