import { Pagination } from '@material-ui/lab'
import * as React from 'react'
import { Box, Grid, Icon, Typography } from '../../../atoms'
import { hooks } from '../../../../libs'
import { Link, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { makeStyles, MenuItem, Paper, Select, Button } from '@material-ui/core'
import { Divider, FormControl, IconButton, InputAdornment, InputBase, List, ListItem } from '@material-ui/core'
import { getThunk } from '../../../../libs/api'
import { useDispatch, useSelector } from 'react-redux'
import { formatDateTime } from '../../../../libs/date'
import NoticeContext, { NoticeProvider } from '../../../../contexts/NoticeContext'
import { setLoading } from '../../../../stores/loading/loading.action'
import * as EVENT from '../../../../constants/event'
import { ModalsContext } from '../../../../contexts'
import { ConfirmDownloadCsvModal } from '../../RichmenuPage/modals/ConfirmDownloadCsvModal'
import { useAuth, useTenant } from '../../../../libs/hooks'
import { AlertMessage } from '../../../molecules'
import { clearNotice, setNotice } from '../../../../stores/notice/notice.action'

const useStyles = makeStyles({
  iconPos: {
    marginRight: '-12px',
    padding: '12px',
  },
  filterEvent: {
    '& input::placeholder': {
      fontSize: '14px',
    },
  },
  nameEvent: {
    fontSize: '14px',
    width: '100%',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 3,
    overflow: 'hidden',
    overflowWrap: 'break-word',
  },
  descriptionEvent: {
    fontSize: '14px',
    lineHeight: '24px',
    margin: 0,
  },
  sectionHeight: {
    height: '100%',
  },
  formControl: {
    margin: '16px',
    minWidth: '120',
  },
  statusWrapper: {
    textAlign: 'center',
    '& p': {
      color: '#ffffff',
      borderRadius: '3px',
      width: '70px',
      height: '24px',
      lineHeight: '24px',
      fontSize: '13px',
      fontWeight: '300',
      '&.created': {
        backgroundColor: '#E67E22',
      },
      '&.reserved': {
        backgroundColor: '#27AE60',
      },
      '&.opened': {
        backgroundColor: '#1776D1',
      },
      '&.ended': {
        backgroundColor: '#999999',
      },
    },
  },
  eventImage: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'top',
  },
  list: {
    display: 'flex',
    '& .image': {
      width: '80px',
    },
    '& .id': {
      width: '120px',
      marginLeft: '20px',
    },
    '& .headline-text': {
      width: '150px',
      marginLeft: '20px',
    },
    '& .status': {
      width: '120px',
      marginLeft: '20px',
    },
    '& .event-date': {
      width: '100px',
      marginLeft: '20px',
    },
    '& .update-by': {
      width: '150px',
      marginLeft: '20px',
    },
    '& .update-at': {
      width: '100px',
      marginLeft: '20px',
    },
  },
  csvButton: {
    marginTop: '5px',
  },
  alertWrap: {
    paddingBottom: 20,
  },
  noticeMessage: {
    whiteSpace: 'pre-wrap',
  },
})

interface EventListContentProps {
  events: any[] | null
  accessToken: string
  limit: number
  errors: any
  currentPage: number
  onFilterChange: any
}

export const EventListContent: React.FC<EventListContentProps> = props => {
  const { events, accessToken, limit, errors, currentPage, onFilterChange } = props
  const { t } = useTranslation(['event', 'translation'])
  const classes = useStyles()
  const [eventsPerPage, setEventsPerPage] = React.useState<any[]>([])
  const [eventsFilter, setEventsFilter] = React.useState<any[]>([])
  const [eventEntryCsvDl, setEventEntryCsvDl] = React.useState(false)
  const [searchProps, setSearchProps] = React.useState({
    id: '',
    name: '',
    status: '',
  })
  const [cacheSearchProps, setCacheSearchProps] = React.useState({
    id: '',
    name: '',
    status: '',
  })
  const history = useHistory()
  const auth = useAuth()
  const { tenantId } = useTenant()
  const dispatch = useDispatch()
  const { appendModal, closeAllModals, changeModal } = React.useContext(ModalsContext)

  React.useEffect(() => {
    return () => dispatch(clearNotice())
  }, [])

  const renderEventsPerPage = (page: number) => {
    setEventsPerPage(eventsFilter!.slice(limit * (page - 1), limit * page))
  }

  const getSortValue = (status: string) => {
    switch (status) {
      case 'created':
        return 0
      case 'reserved':
        return 1
      case 'opened':
        return 2
      case 'ended':
        return 3
      default:
        return 0
    }
  }

  React.useEffect(() => {
    if (events) {
      // set status name
      events.map(event => {
        event.statusName = t(`translation:eventStatus.${event.status}`)
      })

      // sort
      const sortedEvents = events.sort((n1, n2) => {
        if (getSortValue(n1.status) > getSortValue(n2.status)) return 1
        if (getSortValue(n1.status) < getSortValue(n2.status)) return -1
        if (!n1.startDate && n2.startDate) return 1
        if (n1.startDate && !n2.startDate) return -1

        const now = +new Date()
        const a = +new Date(n1.startDate) - now
        const b = +new Date(n2.startDate) - now
        if (0 <= a && 0 <= b) {
          return a - b
        } else if (a < 0 && b < 0) {
          return b - a
        } else if (a > b) {
          return -1
        } else if (b > a) {
          return 1
        } else {
          return 0
        }
      })
      setEventsFilter(sortedEvents)
    }
  }, [events])

  React.useEffect(() => {
    if (currentPage > 0) {
      renderEventsPerPage(currentPage)
    }
  }, [eventsFilter, currentPage, limit])

  React.useEffect(() => {
    if (errors) {
      dispatch(setNotice(t('translation:notice.fetchData.content.errorFetch')))
    } else {
      dispatch(clearNotice())
    }
  }, [errors])

  React.useEffect(() => {
    if (events) {
      if (cacheSearchProps.id || cacheSearchProps.name || cacheSearchProps.status) {
        let temp = []
        temp = events.filter(event => {
          if (cacheSearchProps.id && !cacheSearchProps.name && !cacheSearchProps.status) {
            return event.eventId.toLowerCase().includes(cacheSearchProps.id.toLowerCase())
          }
          if (!cacheSearchProps.id && cacheSearchProps.name && !cacheSearchProps.status) {
            return event.headLineText.toLowerCase().includes(cacheSearchProps.name.toLowerCase())
          }
          if (!cacheSearchProps.id && !cacheSearchProps.name && cacheSearchProps.status) {
            return event.status === cacheSearchProps.status
          }
          if (cacheSearchProps.id && cacheSearchProps.name && !cacheSearchProps.status) {
            return (
              event.eventId.toLowerCase().includes(cacheSearchProps.id.toLowerCase()) &&
              event.headLineText.toLowerCase().includes(cacheSearchProps.name.toLowerCase())
            )
          }
          if (cacheSearchProps.id && !cacheSearchProps.name && cacheSearchProps.status) {
            return (
              event.eventId.toLowerCase().includes(cacheSearchProps.id.toLowerCase()) &&
              event.status === cacheSearchProps.status
            )
          }
          if (!cacheSearchProps.id && cacheSearchProps.name && cacheSearchProps.status) {
            return (
              event.headLineText.toLowerCase().includes(cacheSearchProps.name.toLowerCase()) &&
              event.status === cacheSearchProps.status
            )
          }
          if (cacheSearchProps.id && cacheSearchProps.name && cacheSearchProps.status) {
            return (
              event.eventId.toLowerCase().includes(cacheSearchProps.id.toLowerCase()) &&
              event.headLineText.toLowerCase().includes(cacheSearchProps.name.toLowerCase()) &&
              event.status === cacheSearchProps.status
            )
          }
        })
        setEventsFilter(temp)
        onFilterChange(temp)
      } else {
        setEventsFilter(events)
        onFilterChange(events)
      }
    }
  }, [cacheSearchProps])

  React.useEffect(() => {
    const currentTenant = auth.tenants.find(tenant => tenant.tenantId === tenantId)
    setEventEntryCsvDl(!currentTenant ? false : currentTenant.permissions.eventEntryCsvDl)
  }, [auth])

  const triggerSearch = () => {
    setCacheSearchProps(searchProps)
  }

  const triggerSearchEnter = (event: any) => {
    if (event.keyCode === 13) {
      event.preventDefault()
      triggerSearch()
    }
  }

  const handleSearch = (key: string, e: any) => {
    setSearchProps({
      ...searchProps,
      [key]: e.target.value,
    })
  }

  const onChangeStatusSelectBox = (e: any) => {
    setSearchProps(pre => {
      return {
        ...pre,
        ['status']: e.target.value,
      }
    })
    setSearchProps(pre => {
      setCacheSearchProps(pre)
      return pre
    })
  }

  const onClickCsv = (event: any) => {
    appendModal(<ConfirmDownloadCsvModal event={event} accessToken={accessToken} tenantId={tenantId} />)
  }

  const onClickDuplicate = (event: any) => {
    history.push({ pathname: './create', state: { event: event } })
  }

  const Title = () => {
    let content = ''
    if (events?.length > 0 && eventsFilter.length > 0) {
      content = t('list.title')
    } else if (events?.length === 0) {
      content = t('list.empty')
    } else if (events?.length > 0 && eventsFilter.length === 0) {
      content = t('list.filterEmpty')
    }
    return <>{content}</>
  }

  const EventEntryId = ({ id, isReservedOpen }: { id: string; isReservedOpen: boolean | undefined }) => {
    if (isReservedOpen === undefined) {
      return <Typography className={classes.nameEvent}>{id}</Typography>
    } else {
      return (
        <Typography color="primary" component={Link} to={`edit/${id}`} className={classes.nameEvent}>
          {id}
        </Typography>
      )
    }
  }

  return (
    <>
      <Box pb={3}>
        {!errors && (
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h6">
              <Title />
            </Typography>
          </Box>
        )}
      </Box>
      {events?.length > 0 && (
        <Box bgcolor="grey.300">
          <Box display="flex" className={classes.list} style={{ padding: '16px 0' }}>
            {/* イメージ */}
            <Box className="image"></Box>
            {/* ID */}
            <Box display="flex" className="id">
              <FormControl>
                <Box clone>
                  <InputBase
                    className={classes.filterEvent}
                    placeholder={t('list.placeholderEventId')}
                    onChange={e => handleSearch('id', e)}
                    onKeyUp={triggerSearchEnter}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton className={classes.iconPos} onClick={triggerSearch}>
                          <Icon iconName="search" />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </Box>
              </FormControl>
            </Box>
            {/* タイトル */}
            <Box display="flex" className="headline-text">
              <FormControl>
                <Box>
                  <InputBase
                    className={classes.filterEvent}
                    placeholder={t('list.placeholderEventName')}
                    onChange={e => handleSearch('name', e)}
                    onKeyUp={triggerSearchEnter}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton className={classes.iconPos} onClick={triggerSearch}>
                          <Icon iconName="search" />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </Box>
              </FormControl>
            </Box>

            {/* ステータス */}
            <Box display="flex">
              <FormControl>
                <Box>
                  <Select
                    value={searchProps.status}
                    style={searchProps.status === '' ? { color: 'rgba(117, 117, 117, 0.58)', fontSize: '14px' } : {}}
                    className="status"
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    disableUnderline={true}
                    onChange={e => onChangeStatusSelectBox(e)}
                  >
                    <MenuItem value="" style={{ color: 'rgba(117, 117, 117, 0.58)' }}>
                      {t('list.placeholderStatus')}
                    </MenuItem>
                    <MenuItem value={'created'}>{t(`translation:eventStatus.created`)}</MenuItem>
                    <MenuItem value={'reserved'}>{t(`translation:eventStatus.reserved`)}</MenuItem>
                    <MenuItem value={'opened'}>{t(`translation:eventStatus.opened`)}</MenuItem>
                    <MenuItem value={'ended'}>{t(`translation:eventStatus.ended`)}</MenuItem>
                  </Select>
                </Box>
              </FormControl>
            </Box>
            {/* イベント期間 */}
            <Box className="event-date">
              <FormControl>
                <Box>
                  <Typography>{t('list.placeholderEventDate')}</Typography>
                </Box>
              </FormControl>
            </Box>
            {/* 更新者 */}
            <Box className="update-by">
              <FormControl>
                <Box>
                  <Typography>{t('list.placeholderUpdateUser')}</Typography>
                </Box>
              </FormControl>
            </Box>
            {/* 更新日 */}
            <Box className="update-at">
              <FormControl>
                <Box>
                  <Typography>{t('list.placeholderUpdateAt')}</Typography>
                </Box>
              </FormControl>
            </Box>
            {/* ボタン */}
            <Box></Box>
          </Box>
        </Box>
      )}
      <List component="ul">
        {eventsPerPage &&
          eventsPerPage!.map((event, index) => {
            return (
              <ListItem key={`l-${index}`} disableGutters>
                <Box width="100%">
                  <Box className={classes.list}>
                    {/* イメージ */}
                    <Box className="image" style={{ height: '90px', paddingBottom: '10px' }}>
                      <Box>
                        {() =>
                          event.eventImageDetail && <img className={classes.eventImage} src={event.eventImageDetail} />
                        }
                      </Box>
                    </Box>
                    {/* ID */}
                    <Box className="id">
                      <EventEntryId id={event.eventId} isReservedOpen={event.isReservedOpen} />
                    </Box>
                    {/* タイトル */}
                    <Box className="headline-text">
                      <Typography className={classes.nameEvent}>{event.headLineText || '-'}</Typography>
                    </Box>
                    {/* ステータス */}
                    <Box className="status">
                      <Box className={classes.statusWrapper}>
                        <Typography className={event.status}>{event.statusName || '-'}</Typography>
                      </Box>
                    </Box>
                    {/* イベント期間 */}
                    <Box className="event-date">
                      <Typography className={classes.nameEvent}>
                        {event.startDate ? formatDateTime(event.startDate, 'yyyy/MM/dd') : '-'}
                      </Typography>
                      <Typography className={classes.nameEvent}>
                        {event.endDate ? formatDateTime(event.endDate, 'yyyy/MM/dd') : '-'}
                      </Typography>
                    </Box>
                    {/* 更新者 */}
                    <Box className="update-by">
                      <Typography className={classes.nameEvent}>{event.updatedBy || '-'}</Typography>
                    </Box>
                    {/* 更新日 */}
                    <Box className="update-at">
                      <Typography className={classes.nameEvent}>
                        {event.updatedAt ? formatDateTime(event.updatedAt, 'yyyy/MM/dd') : '-'}
                      </Typography>
                    </Box>
                    <Box height="100%" display="flex" flexDirection="column" justifyContent="space-evenly">
                      <Box hidden={event.isReservedOpen === undefined}>
                        <Button variant="outlined" color="primary" onClick={() => onClickDuplicate(event)}>
                          {EVENT.DUPLICATE_BTN_TITLE}
                        </Button>
                      </Box>
                      <Box hidden={event.status !== 'opened' && event.status !== 'ended'}>
                        <Box hidden={!eventEntryCsvDl}>
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => onClickCsv(event)}
                            className={classes.csvButton}
                          >
                            {EVENT.CSV_BTN_TITLE}
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    <Divider />
                  </Box>
                </Box>
              </ListItem>
            )
          })}
      </List>
    </>
  )
}

export const EventListPage = () => {
  const { tenantId } = hooks.useTenant()
  const { accessToken } = hooks.useAuth()
  const [events, setEvents] = React.useState<any[] | null>(null)
  const [currentPage, setCurrentPage] = React.useState(1)
  const [totalFilterPages, setTotalFilterPages] = React.useState(0)
  const [limit, setLimit] = React.useState(10)
  const [errors, setErrors] = React.useState(null)
  const loading = useSelector((state: any) => state?.loadingReducer?.loading)
  const notice = useSelector((state: any) => state?.noticeReducer?.notice)
  const classes = useStyles()
  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(setLoading(true))
    dispatch(getThunk('events', accessToken, tenantId))
      .then((res: any) => {
        if (res) {
          setEvents(res.data)
        }
      })
      .catch((e: any) => {
        setErrors(e)
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }, [])

  const onPaginationChange = (_e: any, page: any) => {
    setCurrentPage(page)
    setTimeout(() => {
      window.scrollTo({
        top: document.body.scrollHeight,
        left: 0,
        behavior: 'smooth',
      })
    })
  }

  const filterHandler = (items: any) => {
    setTotalFilterPages(Math.ceil(items.length / limit))
    setCurrentPage(1)
  }

  return (
    <>
      <NoticeSection />
      <Box pb={7} height={notice.length === 0 ? 'calc(100% - 57px)' : 'calc(100% - 179px)'}>
        <section className={classes.sectionHeight}>
          <Paper className={classes.sectionHeight}>
            <Box px={4} py={3} height="100%">
              <NoticeProvider>
                {!loading && (
                  <EventListContent
                    events={events}
                    accessToken={accessToken}
                    limit={limit}
                    errors={errors}
                    currentPage={currentPage}
                    onFilterChange={filterHandler}
                  />
                )}
              </NoticeProvider>
            </Box>
          </Paper>
        </section>
        {totalFilterPages > 0 && (
          <Box mt={2} pb={3} display="flex" alignItems="center" justifyContent="center">
            <Pagination page={currentPage} onChange={onPaginationChange} count={totalFilterPages} color="primary" />
          </Box>
        )}
      </Box>
    </>
  )
}

const NoticeSection = () => {
  const notice = useSelector((state: any) => state?.noticeReducer?.notice)
  const classes = useStyles()

  return (
    <>
      {!!notice.length && (
        <Box className={classes.alertWrap}>
          <AlertMessage type="error" title={EVENT.ERR_GET_DATA}>
            <ul>
              {notice.map((err: string, index: number) => {
                return (
                  <li key={index}>
                    <Typography className={classes.noticeMessage}>{err}</Typography>
                  </li>
                )
              })}
            </ul>
          </AlertMessage>
        </Box>
      )}
    </>
  )
}
