import React from 'react'
import { TabletMacOutlined } from '@material-ui/icons'
import QRCode from 'qrcode.react'
import { PreviewModal } from '.'

type Props = {
  header: string
  content: string
}
export const NoticePreviewModal = ({ header, content }: Props) => {
  const Content = () => {
    return (
      <div style={{ width: '100%', textAlign: 'center' }}>
        <QRCode value={content} />
      </div>
    )
  }

  const Header = () => {
    return (
      <div style={{display: 'flex'}}>
        <TabletMacOutlined style={{marginRight: '8px'}} />
        {header}
      </div>
    )
  }

  return <PreviewModal header={<Header />} content={<Content />} />
}
