import React, { FC } from 'react'
import { CANCEL_BTN_TITLE, MODAL_CANCEL_CONTENT, MODAL_CANCEL_TITLE } from '../../../../constants/richmenu'
import { PrimaryConfirmModal } from '../../../molecules/ModalContainer/PrimaryDialogTemplate/PrimaryConfirmDialog'

interface ConfirmCancelModalProps {
  onSubmit: any
}

export const ConfirmCancelModal: React.FC<ConfirmCancelModalProps> = props => {
  return (
    <PrimaryConfirmModal
      header={MODAL_CANCEL_TITLE}
      content={MODAL_CANCEL_CONTENT}
      onSubmit={props.onSubmit}
      label={CANCEL_BTN_TITLE}
      skipButton={true}
    />
  )
}
