import * as React from 'react'
import { Redirect, Route, Router, Switch } from 'react-router-dom'
import { createBrowserHistory } from 'history'

import RouteGroupSwitch from './libs/RouteGroupSwitch'
import { NavigationProvider } from './contexts/NavigationContext'
//
import TenantContainer from './containers/TenantContainer'
//
import CallbackPage from './components/pages/CallbackPage'
import LoginPage from './components/pages/LoginPage'
import UnauthorizedPage from './components/pages/UnauthorizedPage'

//
import DashboardPage from './components/pages/DashboardPage'
import NotFoundErrorPage from './components/pages/NotFoundErrorPage'
import { hooks } from './libs'
import { Domain } from './features'
import { useSelector } from 'react-redux'
import { Suspense } from 'react'
import { CacheSwitch } from 'react-router-cache-route'

const history = createBrowserHistory()

const PublicRoutes: React.FC = () => {
  return (
    <CacheSwitch>
      <Route path="/callback" component={CallbackPage} />
      <Route exact path="/login" component={LoginPage} />
      <Route
        path="/unauthorized"
        render={props => {
          return <UnauthorizedPage {...props} />
        }}
      />
      <Route
        render={_props => {
          return <Redirect to="/login" />
        }}
      />
    </CacheSwitch>
  )
}

const PrivateRoutes: React.FC = () => {
  const { tenantIds, tenants } = hooks.useAuth()

  // List constraint tenantIds can access route
  const tenantIdsConstraint = tenantIds?.join('|')
  const getCurrentTenant = (currentTenantId: string) => {
    return tenants?.find((tenant: Domain.Auth.TenantAuth0Interface) => {
      return tenant.tenantId === currentTenantId
    })
  }

  const CheckPermission = (props: any) => {
    return getCurrentTenant(props.tenantId).permissions[props.permission] ? <>{props.comp}</> : <NotFoundErrorPage />
  }

  window.scrollTo(0, 0)
  return (
    <NavigationProvider>
      <CacheSwitch>
        <Route path="/callback" component={CallbackPage} />
        <Route exact path={['/', '/select-account']} component={DashboardPage} />
        <Route
          exact
          path="/login"
          render={() => {
            return <Redirect to="/" />
          }}
        />
        <Route path={`/:tenantId(${tenantIdsConstraint})`} render={() => <TenantContainer />} />
        <Route component={NotFoundErrorPage} />
      </CacheSwitch>
    </NavigationProvider>
  )
}

export default function Routes() {
  const { logout } = hooks.useAuth()
  const isAuth = useSelector((state: any) => state?.authReducer?.auth?.forceLogout)
  React.useEffect(() => {
    if (isAuth) {
      logout()
    }
  }, [isAuth])

  return (
    <Router history={history}>
      <Suspense fallback={<div>Loading...</div>}>
        <RouteGroupSwitch>
          {({ isLoggedIn }) => {
            if (isLoggedIn) {
              return <PrivateRoutes />
            } else {
              return <PublicRoutes />
            }
          }}
        </RouteGroupSwitch>
      </Suspense>
    </Router>
  )
}
