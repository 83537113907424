export enum AUDIENCE_CONDITIONS {
  PATTERN1 = 1,
  PATTERN2,
  PATTERN3,
  PATTERN4
}

export enum AUDIENCE_LABELS {
  '利用店舗１～３のいずれかに該当店舗を登録している友だち' = 1,
  '利用店舗１に該当店舗を登録している友だちを除外',
  'TOKYUカード連携済み',
  'TOKYUカード未連携'
}

export enum AUDIENCE_STATUS {
  'error' = 0,
  'success' = 1
}

export enum AUDIENCE_STATUS_LABELS {
  'creationStatus.error' = 0,
  'creationStatus.success' = 1
}