//import * as Domain from '../domain'

export function createInitialState() {
  return {
    tenantId: '',
  }
}

export const SET_TENANT_ID = 'app/setTenantId' as const

export function setTenantId(tenantId: string) {
  return {
    type: SET_TENANT_ID,
    payload: {
      tenantId,
    },
  }
}

type SetTenantIdAction = ReturnType<typeof setTenantId>

export type Action = SetTenantIdAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_TENANT_ID:
      return {
        ...state,
        tenantId: action.payload.tenantId,
      }
    default:
      return state
  }
}
