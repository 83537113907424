export function changText(text: string, ...others: any) {
  let textTemp = text;
  others.forEach((result: string, index: number) => {
      textTemp = textTemp.replace(`{value${index}}`, result)
  })
  return textTemp
}

export function getEnumKeys(enumVar: any) {
  return Object.keys(enumVar).filter(key => !isNaN(Number(enumVar[key])))
}

export function getEnumIndexs(enumVar: any) {
  return Object.keys(enumVar).filter(key => isNaN(Number(enumVar[key]))).map(x => +x)
}
