import { Box, makeStyles, Typography } from '@material-ui/core'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { PrimaryNoticeModal } from '../../../../molecules/ModalContainer/PrimaryDialogTemplate/PrimaryNoticeDialog'

const useStyles = makeStyles(() => ({
  desc: {
    whiteSpace: 'pre-wrap'
  }
}))

export const NoticeCreateAudienceFailedModal = () => {
  const { t } = useTranslation('audience')
  const classes = useStyles({})

  const Content = () => {
    return <>
      <p className={classes.desc}>{t('modals.noticeCreateAudienceDupName.content')}</p>
    </>
  }
  
  return (
    <PrimaryNoticeModal
      header={t('modals.noticeCreateAudienceDupName.title')}
      content={<Content/>}
    />
  )
}
