import React, { FC } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
//
import { CircularProgress, Card, Box, Typography } from '../../atoms'
import { useSelector } from 'react-redux'

/**
 * Styles
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100vh',
      background: 'rgba(255, 255, 255, 0.5)',
      position: 'fixed',
      right: 0,
      // 256px is sidebar width
      left: 256,
      top: 0,
      bottom: 0,
      zIndex: 10,
      overflow: 'hidden'
    },
    panel: {
      width: '240px',
    },
    panelInnter: {
      height: '120px',
    },
    progress: {
      display: 'block',
      color: theme.palette.brand[500],
    },
    text: {
      marginTop: theme.spacing(2),
      color: theme.palette.grey[700],
      fontSize: '12px',
    },
  }),
)

/**
 * Presenter Component
 */

export const LoadingPanel: FC = _props => {
  const classes = useStyles({})
  const loading = useSelector((state: any) => state?.loadingReducer?.loading)

  return (
    loading && <Box className={classes.root} display="flex" justifyContent="center" alignItems="center">
      <Card className={classes.panel}>
        <Box
          className={classes.panelInnter}
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <CircularProgress className={classes.progress} size={32} />
          <Typography className={classes.text} display="block">
            読み込み中
          </Typography>
        </Box>
      </Card>
    </Box>
  )
}
