import { combineReducers } from "redux";
import * as types from '../../constants/types';

const initialState: any = {
  isLoading: false,
  isProcessing: false,
  hasError: false,
  data: undefined,
  subMenu: undefined,
  error: undefined,
};

export function richmenu(state = initialState, action: {type: string, payload: any}) {
  switch (action.type) {
    case types.GET_RICHMENU:
      return {
        ...state,
        isLoading: true,
      }
    case types.SET_RICHMENU:
      return {
        ...state,
        hasError: false,
        data: action.payload
      }
    case types.RESET_RICHMENU:
      return {
        ...initialState
      }
    case types.GET_RICHMENU_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload
      }
    case types.GET_RICHMENU_ERROR:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        error: action.payload.error,
        subMenu: action.payload.subMenu
      }
      case types.GET_SUBMENU:
        return {
          ...state,
          isLoading: true,
        }
      case types.GET_SUBMENU_SUCCESS:
        return {
          ...state,
          isLoading: false,
          data: action.payload.data,
          subMenu: action.payload.subMenu
        }
      case types.GET_SUBMENU_ERROR:
        return {
          ...state,
          isLoading: false,
          hasError: true,
          error: action.payload
        }
      default:
        return state
  }
}

const richmenuReducer = combineReducers({
  richmenu
})

export default richmenuReducer
