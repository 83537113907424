import { FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from '@material-ui/core'
import * as React from 'react'
import { Controller } from 'react-hook-form'
import { getEnumIndexs } from '../../../../../libs/functions/common'
import { useTranslation } from 'react-i18next'
import { Box } from '../../../../atoms'
import { AUDIENCE_LABELS } from '../../utility/AudienceData'

interface AudienceBasicSectionProps {
  control: any
}

export const AudienceBasicSection: React.FC<AudienceBasicSectionProps> = props => {
  const { control } = props
  const { t } = useTranslation('audience')

  return (
    <>
      <Box pb={3}>
        <Box pb={2} clone>
          <Typography variant="h6">{t('basicSection.condition')}</Typography>
        </Box>
        <FormControl component="fieldset">
          <Controller
            name="audienceType"
            control={control}
            render={({ field }) => (
              <RadioGroup
                ref={field.ref}
                value={field.value}
                onChange={e => {
                  field.onChange(e.target.value)
                }}
              >
                {getEnumIndexs(AUDIENCE_LABELS).map((x: number) => (
                  <FormControlLabel
                    key={`a-${x}`}
                    value={`${x}`}
                    control={<Radio color="primary" />}
                    label={AUDIENCE_LABELS[x]}
                    ref={field.ref}
                  />
                ))}
              </RadioGroup>
            )}
          />
        </FormControl>
      </Box>
      <Box>
        <Box pb={2} clone>
          <Typography variant="h6">{t('basicSection.inputName')}</Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Controller
              name="audienceName"
              control={control}
              render={({ field }) => (
                <TextField {...field} fullWidth variant="outlined" inputProps={{ maxLength: 120 }} />
              )}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}
