import { format } from 'date-fns'

export function formatDate(dateString: string): string {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  }
  return new Intl.DateTimeFormat('ja-JP', options).format(new Date(dateString))
}

export function formatDateTime(date: any, pattern = 'yyyy/MM/dd HH:mm'): string {
  if (date) {
    return format(new Date(date), pattern)
  } else {
    return ''
  }
}
