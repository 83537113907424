//import React, { FC, useState, useCallback, createRef } from 'react'
import React, { FC } from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
//
import { Button, Icon } from '../../atoms'

const LOGOUT = 'ログアウト'

/**
 * Interface
 */

export interface ProfileMenuPresenterProps {
  actions: {
    handleLogout: (event: React.MouseEvent<HTMLButtonElement>) => void
  }
}

export interface ProfileMenuContainerProps {
  children: (props: ProfileMenuPresenterProps) => React.ReactElement
  actions: {
    handleLogout: (event: React.MouseEvent<HTMLButtonElement>) => void
  }
}

export interface ProfileMenuProps {
  handleLogout: (event: React.MouseEvent<HTMLButtonElement>) => void
}

/**
 * Styles
 */

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      display: 'flex',
      alignItems: 'center'
    },
    logoutBtn: {
      marginLeft: 5
    }
  }),
)

/**
 * Container
 */

export const ProfileMenuContainer: FC<ProfileMenuContainerProps> = props => {
  const {
    children,
    actions: { handleLogout },
  } = props

  return children({
    actions: { handleLogout },
  })
}

/**
 * Presenter
 */

export const ProfileMenuPresenter: FC<ProfileMenuPresenterProps> = props => {
  const {
    actions: { handleLogout },
  } = props
  const classes = useStyles({})
  return (
    <div className={classes.wrapper}>
      <Icon size="lg" iconName="user-circle" />
      <Button className={classes.logoutBtn} onClick={handleLogout}>{ LOGOUT }</Button>
    </div>
  )
}

/**
 * Combine
 */

export const ProfileMenu: FC<ProfileMenuProps> = props => {
  const { handleLogout } = props
  return (
    <ProfileMenuContainer actions={{ handleLogout }}>
      {props => {
        return <ProfileMenuPresenter {...props} />
      }}
    </ProfileMenuContainer>
  )
}
