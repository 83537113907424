import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Button,
  CardActions,
  CardContent,
  Container,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  Paper,
  Switch,
  TextField,
  Theme,
  Tooltip,
} from '@material-ui/core'
import {
  Add,
  CalendarToday,
  TabletMacOutlined,
  Save,
  EventBusy,
  DeleteForever,
  FilterNone,
  LensTwoTone,
} from '@material-ui/icons'
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { Controller, UseFormRegister, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import CopyToClipBoard from 'react-copy-to-clipboard'
import { Typography } from '../../../atoms'
import { AlertMessage, UploadFileName } from '../../../molecules'
import { COLORS } from '../../../../constants'
import { createEvent, updateEvent, removeEvent } from '../../../../stores/event/event.middleware'
import { PutEventBody, PostEventBody } from '../../../../stores/event/event.reducer'
import { ModalsContext } from '../../../../contexts'
import {
  ConfirmReservedModal,
  ConfirmDeleteModal,
  NoticePreviewModal,
  ConfirmCancelReservedModal,
  ConfirmUpdateOpenedModal,
} from '../modals/index'
import { clearNotice, setNotice } from '../../../../stores/notice/notice.action'
import * as EVENT from '../../../../constants/event'
import { getThunk } from '../../../../libs/api'
import { setLoading } from '../../../../stores/loading/loading.action'
import { EventStatus } from './components/EventStatus'
import { functions } from '../../../../libs'
import { useAuth } from '../../../../libs/hooks'
import { ConfirmDownloadCsvModal } from '../../RichmenuPage/modals/ConfirmDownloadCsvModal'

const MAX_FILE_SIZE_LIST = 1024 * 1000 * 1
const MAX_FILE_SIZE_DETAIL = 1024 * 1000 * 2

// style
const useStyles = makeStyles((theme: Theme) => ({
  alertWrap: {
    paddingBottom: 20,
  },
  logWrap: {
    width: 'auto',
    height: '100px',
  },
  statusWrapper: {
    textAlign: 'center',
    '& p': {
      color: '#ffffff',
      borderRadius: '3px',
      width: '70px',
      height: '24px',
      lineHeight: '24px',
      fontSize: '13px',
      fontWeight: '300',
      '&.created': {
        backgroundColor: '#E67E22',
      },
      '&.reserved': {
        backgroundColor: '#27AE60',
      },
      '&.opened': {
        backgroundColor: '#1776D1',
      },
      '&.ended': {
        backgroundColor: '#999999',
      },
    },
  },
  container: {
    textAlign: 'center',
  },
  uploadImgBtn: {
    borderRadius: theme.spacing(5),
    padding: '6px 25px',
  },
  media: {
    height: 0,
    paddingTop: '67%',
    marginBottom: 12,
  },
  fileNameWrapper: {
    width: 'auto',
    padding: 0,
  },
  uploadImgBtnWrapper: {
    justifyContent: 'center',
    paddingTop: 12,
    paddingBottom: 20,
  },
  previewBtnGroup: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    flexDirection: 'column-reverse',
    marginTop: 20,
  },
  previewTitle: {
    marginRight: 15,
  },
  submitSection: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
    marginTop: 20,
    marginBottom: 50,
  },
  iconButton: {
    width: 130,
    '& .wrapper': {
      display: 'flex',
      width: 100,
    },
    '& .icon': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 30,
    },
    '& .label': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 70,
    },
  },
  eventId: {
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: '0',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: '0',
    },
  },
  eventDate: {
    display: 'flex',
  },
  between: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 16px',
  },
  label: {
    color: '#2c2c2c',
  },
  helperText: {
    color: '#979797',
  },
  eventImageSectionWrapper: {
    padding: '30px 30px',
  },
  eventImageSection: {
    width: '300px',
    margin: 'auto',
  },
  eventImageCaption: {
    textAlign: 'left',
  },
  eventImageWrapper: {
    height: '300px',
    width: 'auto',
    backgroundColor: '#0000001f',
    margin: 'auto',
    marginBottom: '36px',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    '& > img': {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
  },
  eventImageWrapperLabelHidden: {
    height: '300px',
    width: 'auto',
    backgroundColor: '#0000001f',
    margin: 'auto',
    marginBottom: '36px',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    '& > img': {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
  },
  eventImageFileNameWrapper: {
    width: 'auto',
    padding: 0,
  },
  eventImageButtonWrapper: {
    justifyContent: 'center',
    paddingTop: 12,
    paddingBottom: 20,
  },
  eventImageUploadButton: {
    borderRadius: theme.spacing(5),
    padding: '6px 25px',
  },
  requiredLabel: {
    display: 'flex',
    '& p:nth-of-type(2)': {
      color: '#00000060',
      marginLeft: '10px',
    },
  },
  openUrlSection: {
    '& .label': {
      marginTop: '10px',
    },
    '& .paper': {
      backgroundColor: '#e8f2fa',
    },
    '& .url': {
      color: '#1776D1',
      fontSize: '16px',
    },
  },
  noticeMessage: {
    whiteSpace: 'pre-wrap',
  },
}))

// Validate
const validateSchema = yup.object().shape({
  eventId: yup.string().required(),
  headLineText: yup.string().required(),
  eventImage: yup.string().when('isDisableIndex', {
    is: true,
    then: yup.string().required(),
    otherwise: yup.string(),
  }),
  eventImageDetail: yup.string().required(),
  eventDescription: yup.string().required(),
  isDisplayUploadImage: yup.boolean().required(),
  isDisableIndex: yup.boolean().required(),
  isDisableDuration: yup.boolean().required(),
  checkRegisterCard: yup.boolean().required(),
  imageText: yup.string().when('isDisplayUploadImage', {
    is: true,
    then: yup.string().required(),
    otherwise: yup.string(),
  }),
  startDate: yup.date().required(),
  endDate: yup.date().required(),
  displayEndDate: yup.date().when('isDisableIndex', {
    is: true,
    then: yup.date().required(),
    otherwise: yup.date().nullable(),
  }),
})

// default values of form
const defaultValues = {
  eventId: '',
  eventImage: '',
  eventImageDetail: '',
  headLineText: '',
  eventDescription: '',
  imageText: '',
  isDisplayUploadImage: false,
  isDisableIndex: false,
  isDisableDuration: true,
  checkRegisterCard: true,
  startDate: null,
  endDate: null,
  displayEndDate: null,
}

type FormValues = {
  eventId: string
  eventImage: string
  eventImageFileName: string
  eventImageDetail: string
  eventImageDetailFileName: string
  headLineText: string
  eventDescription: string
  imageText: string
  isDisplayUploadImage: boolean
  isDisableIndex: boolean
  isDisableDuration: boolean
  checkRegisterCard: boolean
  startDate: any
  endDate: any
  displayEndDate: any
}

/**
 * 画面上部にスクロールさせる
 */
const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  })
}

type OpenUrlSectionProps = {
  tenantId: string
}
export const OpenUrlSection: React.FC<OpenUrlSectionProps> = props => {
  const { tenantId } = props
  const classes = useStyles()
  const storeEvent = useSelector((state: any) => state?.eventReducer?.event)
  const [openTip, setOpenTip] = useState<boolean>(false)
  const handleCloseTip = (): void => {
    setOpenTip(false)
  }

  const handleClickButton = (): void => {
    setOpenTip(true)
  }
  return (
    <>
      {(storeEvent.data?.status === 'created' ||
        storeEvent.data?.status === 'reserved' ||
        storeEvent.data?.status === 'opened') && (
        <Box className={classes.openUrlSection}>
          <Paper className="paper">
            <Box pr={3} pl={3} pt={3} pb={2}>
              <Typography variant="body2">公開URL</Typography>
              <Grid container direction="row" alignItems="center">
                <Grid item>
                  <Typography variant="body2" className="url">
                    {`${functions.account.generalAccountLiffDomain(tenantId, 'submit')}/events/${
                      storeEvent.data?.eventId
                    }`}
                  </Typography>
                </Grid>
                <Grid item>
                  <Tooltip
                    arrow
                    open={openTip}
                    onClose={handleCloseTip}
                    disableHoverListener
                    placement="top"
                    title="Copied!"
                  >
                    <CopyToClipBoard
                      text={`${functions.account.generalAccountLiffDomain(tenantId, 'submit')}/events/${
                        storeEvent.data?.eventId
                      }`}
                    >
                      <IconButton onClick={handleClickButton} color="primary">
                        <FilterNone />
                      </IconButton>
                    </CopyToClipBoard>
                  </Tooltip>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>
      )}
    </>
  )
}

type EventCreatePagePresenterProps = {
  id: string | null
  originalId: string | null | undefined
  accessToken: string
  tenantId: string
}
export const EventCreatePagePresenter: React.FC<EventCreatePagePresenterProps> = props => {
  const { id, accessToken, tenantId, originalId } = props
  const { t } = useTranslation(['event', 'translation'])
  const classes = useStyles()

  const dispatch = useDispatch()
  const { appendModal, closeAllModals } = React.useContext(ModalsContext)
  const storeEvent = useSelector((state: any) => state?.eventReducer?.event)
  const history = useHistory()
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    setError,
    clearErrors,
    register,
    reset,
    watch,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(validateSchema),
    defaultValues,
  })
  const watchIsDisplayUploadImage = watch('isDisplayUploadImage')
  const watchIsDisableIndex = watch('isDisableIndex')

  const [isDisableForm, setIsDisableForm] = useState(false)
  const [buttonName, setButtonName] = useState<string>()
  const [logButtonName, setLogButtonName] = useState<string>()
  const [logMessage, setLogMessage] = useState({
    buttonName: '',
    updatedAt: '',
    startDate: '',
  })

  /**
   * サブミットボタン押下後、バリデーションが全て通った時
   * @param data フォームの各値
   */
  const onSubmit = async (data: FormValues) => {
    dispatch(clearNotice())
    clearErrors()
    setButtonName('')

    // 排他制御
    let tableEventVersion: number | undefined = 0
    if (id) {
      dispatch(setLoading(true))
      await dispatch(getThunk(`events/${id}`, accessToken, tenantId))
        .then((res: any) => {
          tableEventVersion = res.data.eventVersion
        })
        .catch(() => {
          dispatch(setNotice(EVENT.ERR_GET_EVENT))
          dispatch(setLoading(false))
          scrollToTop()
          return
        })
        .finally(() => {})
    }
    if (id && tableEventVersion && storeEvent.data.eventVersion < tableEventVersion) {
      dispatch(setNotice(EVENT.ERR_UPDATE_CONFLICT))
      dispatch(setLoading(false))
      scrollToTop()
      return
    }

    // 現在のステータスが予約以降もしくは公開予約ボタン押下時、公開予約フラグがtrue
    const isReservedOpen = storeEvent.data?.isReservedOpen || buttonName === EVENT.RESERVE_OPEN_BTN_TITLE ? true : false

    if (id) {
      // 更新
      const { eventId, ...rest } = data
      const body: PutEventBody = {
        ...rest,
        isReservedOpen,
        imageText: data.isDisplayUploadImage ? data.imageText : '',
        eventVersion: storeEvent.data.eventVersion || 1,
        isDisableIndex: data.isDisableIndex ? false : true,
        isDisableDuration: data.isDisableDuration ? false : true,
        startDate: data.startDate ? data.startDate.toISOString() : '',
        endDate: data.endDate ? data.endDate.toISOString() : '',
        displayEndDate: data.displayEndDate ? data.displayEndDate.toISOString() : '',
      }

      const callback = {
        success: () => (isReservedOpen && buttonName === EVENT.RESERVE_OPEN_BTN_TITLE ? scrollToTop() : {}),
        error: () => scrollToTop(),
      }
      // 現在のステータスが公開中の場合、確認ダイアログを表示
      if (storeEvent.data?.status === 'opened') {
        dispatch(setLoading(false))
        appendModal(
          <ConfirmUpdateOpenedModal
            onSubmit={() => {
              closeAllModals()
              dispatch(updateEvent(id, accessToken, tenantId, body, callback))
            }}
          />,
        )
      } else if (isReservedOpen && buttonName === EVENT.RESERVE_OPEN_BTN_TITLE) {
        dispatch(setLoading(false))
        appendModal(
          <ConfirmReservedModal
            onSubmit={() => {
              closeAllModals()
              dispatch(updateEvent(id, accessToken, tenantId, body, callback))
            }}
          />,
        )
      } else {
        dispatch(updateEvent(id, accessToken, tenantId, body, callback))
      }
    } else {
      // 追加
      const body: PostEventBody = {
        ...data,
        isReservedOpen,
        imageText: data.isDisplayUploadImage ? data.imageText : '',
        isDisableIndex: data.isDisableIndex ? false : true,
        isDisableDuration: data.isDisableDuration ? false : true,
        startDate: data.startDate ? data.startDate.toISOString() : '',
        endDate: data.endDate ? data.endDate.toISOString() : '',
        displayEndDate: data.displayEndDate ? data.displayEndDate.toISOString() : '',
      }
      const callback = {
        success: () => history.push(`./edit/${body.eventId}`),
        error: () => scrollToTop(),
      }
      if (isReservedOpen && buttonName === EVENT.RESERVE_OPEN_BTN_TITLE) {
        appendModal(
          <ConfirmReservedModal
            onSubmit={() => {
              closeAllModals()
              dispatch(createEvent(body, accessToken, tenantId, callback))
            }}
          />,
        )
      } else {
        dispatch(createEvent(body, accessToken, tenantId, callback))
      }
    }
  }

  /**
   * サブミット時、バリデーションが通らなかった場合の処理
   * @param error エラー内容
   */
  const onError = () => {
    dispatch(clearNotice())
    setButtonName('')

    Object.keys(getValues()).map(item => {
      getValues(item as any) || setError(item as any, { type: 'required' })
    })
    dispatch(
      setNotice(buttonName === EVENT.SAVE_BTN_TITLE ? EVENT.ERR_SUBMIT_SAVE_VALIDATE : EVENT.ERR_SUBMIT_VALIDATE),
    )
    scrollToTop()
  }

  /**
   * 保存を行う
   * イベントIDが未入力の場合はエラーを発生させる
   */
  const save = () => {
    clearErrors()
    setButtonName('')

    const data = getValues()
    let hasError = false

    // キャンペーンエントリーID必須チェック、形式チェック
    if (!data.eventId || !(data.eventId.match(/\d+/g)[0] === data.eventId)) {
      setError('eventId', { type: 'required' })
      dispatch(setNotice(EVENT.ERR_SAVE_VALIDATE))
      hasError = true
    }

    if (hasError) {
      scrollToTop()
      return
    }

    onSubmit(data)
  }

  /** フォームの値をstoreの値で初期化する */
  const resetFormValues = async () => {
    reset({
      ...storeEvent.data,
      eventImage: storeEvent.data.eventImage,
      eventImageDetail: storeEvent.data.eventImageDetail,
      eventImageFileName: storeEvent.data.eventImage && storeEvent.data.eventImage.split('/').reverse()[0],
      eventImageDetailFileName:
        storeEvent.data.eventImageDetail && storeEvent.data.eventImageDetail.split('/').reverse()[0],
      isDisableIndex: storeEvent.data.isDisableIndex ? false : true,
      isDisableDuration: storeEvent.data.isDisableDuration ? false : true,
      startDate: storeEvent.data.startDate ? new Date(storeEvent.data.startDate) : null,
      endDate: storeEvent.data.endDate ? new Date(storeEvent.data.endDate) : null,
      displayEndDate: storeEvent.data.displayEndDate ? new Date(storeEvent.data.displayEndDate) : null,
    })
  }

  React.useEffect(() => {
    // 保存ボタン押下時で、新規作成または作成中のキャンペーン画面で保存ボタン押下時は、IDのみのバリデーションで保存を行う
    if (buttonName === EVENT.SAVE_BTN_TITLE && (!storeEvent.data?.eventId || storeEvent.data?.status === 'created'))
      save()
  }, [buttonName])

  React.useEffect(() => {
    setIsDisableForm(storeEvent.data?.status === 'ended')

    if ((id || originalId) && storeEvent.data) {
      resetFormValues()
    }
  }, [storeEvent.data])

  const statusName = (status?: string) => {
    if (!status) return undefined
    return t(`translation:eventStatus.${status}`)
  }

  const LogMessage = (props: typeof logMessage) => {
    const { buttonName, updatedAt, startDate } = props
    return <EventStatus buttonName={buttonName} updatedAt={updatedAt} startDate={startDate} />
  }

  React.useEffect(() => {
    if (storeEvent.updateComplete > 0) {
      setLogMessage({
        buttonName: logButtonName,
        updatedAt: storeEvent.data?.updatedAt,
        startDate: storeEvent.data?.startDate,
      })
    } else {
      setLogMessage({
        buttonName: '',
        updatedAt: '',
        startDate: '',
      })
    }
  }, [storeEvent.updateComplete])

  return (
    <>
      <NoticeSection />
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <Paper>
          <Box px={4} py={3}>
            <Box display="flex" justifyContent="space-between">
              <div>
                <LogMessage {...logMessage} />
              </div>
              {storeEvent.data?.status ? (
                <Box className="status">
                  <Box className={classes.statusWrapper}>
                    <Typography className={storeEvent.data?.status}>{statusName(storeEvent.data?.status)}</Typography>
                  </Box>
                </Box>
              ) : (
                <></>
              )}
            </Box>
            <ImageSection
              setValue={setValue}
              register={register}
              errors={errors}
              watch={watch}
              clearErrors={clearErrors}
              isDisableForm={isDisableForm}
            />
            <InputSection
              control={control}
              errors={errors}
              isDisableForm={isDisableForm}
              isDisableIndex={watchIsDisableIndex}
              isDisplayUploadImage={watchIsDisplayUploadImage}
            />
          </Box>
        </Paper>
        <ButtonSection
          id={id}
          accessToken={accessToken}
          tenantId={tenantId}
          setButtonName={setButtonName}
          setLogButtonName={setLogButtonName}
        />
        <OpenUrlSection tenantId={tenantId} />
      </form>
    </>
  )
}

type EventImageProps = {
  name: 'eventImage' | 'eventImageDetail'
  setValue: any
  register: UseFormRegister<FormValues>
  errors: any
  watch: any
  clearErrors: any
  isDisableForm: boolean
}
const EventImage = (props: EventImageProps) => {
  const { name, setValue, register, errors, watch, clearErrors, isDisableForm } = props
  const classes = useStyles()
  const fileName = React.useMemo(() => name + 'FileName', [name])
  const dispatch = useDispatch()
  const watchImage = watch(name)
  const watchImageFileName = watch(fileName)

  const onChangeEventImage = e => {
    const file = (e.target.files as FileList)[0]
    if (file) {
      dispatch(clearNotice())
      const maxFileSize = name === 'eventImage' ? MAX_FILE_SIZE_LIST : MAX_FILE_SIZE_DETAIL
      const errorMessage =
        name === 'eventImage' ? EVENT.ERR_EVENT_IMAGE_FILE_SIZE : EVENT.ERR_EVENT_IMAGE_DETAIL_FILE_SIZE
      if (file.size > maxFileSize) {
        dispatch(setNotice(errorMessage))
        scrollToTop()
      } else {
        setValue(fileName, file.name)
        const reader = new FileReader()
        reader.onload = e => {
          setValue(name, e.target.result)
          clearErrors(name)
        }
        reader.readAsDataURL(file)
      }
      e.target.value = null
    }
  }

  return (
    <>
      {/* 画像 */}
      <Box className={classes.eventImageSectionWrapper}>
        <Box className={classes.eventImageSection}>
          <Typography className={classes.eventImageCaption}>
            {name === 'eventImage' ? EVENT.LIST_IMAGE_LABEL : EVENT.DETAIL_IMAGE_LABEL}
          </Typography>
          <Box className={watchImage ? classes.eventImageWrapperLabelHidden : classes.eventImageWrapper}>
            <Box>
              <Box
                style={{
                  width: '85%',
                  color: '#828282',
                  textAlign: 'left',
                  margin: '20px auto 0',
                }}
              >
                <Box hidden={!!watchImage}>
                  <Typography variant="body2">{EVENT.EMPTY_LIST_IMAGE_LABEL_01}</Typography>
                  <br />
                  {name === 'eventImage' ? (
                    <>
                      <Typography variant="body2">{EVENT.EMPTY_LIST_IMAGE_LABEL_02}</Typography>
                      <Typography variant="body2">{EVENT.EMPTY_LIST_IMAGE_LABEL_03}</Typography>
                      <Typography variant="body2">{EVENT.EMPTY_LIST_IMAGE_LABEL_04}</Typography>
                      <Typography variant="body2">{EVENT.EMPTY_LIST_IMAGE_LABEL_05}</Typography>
                      <Typography variant="body2">{EVENT.EMPTY_LIST_IMAGE_LABEL_06}</Typography>
                      <Typography variant="body2">{EVENT.EMPTY_LIST_IMAGE_LABEL_07}</Typography>
                      <Typography variant="body2">{EVENT.EMPTY_LIST_IMAGE_LABEL_08}</Typography>
                      <Typography variant="body2">{EVENT.EMPTY_LIST_IMAGE_LABEL_09}</Typography>
                      <Typography variant="body2">{EVENT.EMPTY_LIST_IMAGE_LABEL_10}</Typography>
                    </>
                  ) : (
                    <>
                      <Typography variant="body2">{EVENT.EMPTY_DETAIL_IMAGE_LABEL_02}</Typography>
                      <Typography variant="body2">{EVENT.EMPTY_DETAIL_IMAGE_LABEL_03}</Typography>
                      <Typography variant="body2">{EVENT.EMPTY_DETAIL_IMAGE_LABEL_04}</Typography>
                      <Typography variant="body2">{EVENT.EMPTY_DETAIL_IMAGE_LABEL_05}</Typography>
                      <Typography variant="body2">{EVENT.EMPTY_DETAIL_IMAGE_LABEL_06}</Typography>
                      <Typography variant="body2">{EVENT.EMPTY_DETAIL_IMAGE_LABEL_07}</Typography>
                    </>
                  )}
                </Box>
              </Box>
            </Box>
            {watchImage && <img src={watchImage} />}
            <input {...register(name)} hidden={true} />
          </Box>
          {/* ファイル名 */}
          <CardContent className={classes.eventImageFileNameWrapper}>
            <UploadFileName
              fileName={watchImageFileName || EVENT.EMPTY_IMAGE_FILE_NAME_LABEL}
              error={Boolean(errors)}
              type={1}
            />
            <input {...register(fileName as any)} hidden={true} />
          </CardContent>
          {/* 画像選択ボタン */}
          <CardActions className={classes.eventImageButtonWrapper} disableSpacing>
            <Button
              variant="contained"
              component="label"
              startIcon={<Add />}
              color="primary"
              className={`${classes.uploadImgBtn} disabled disabled-img-btn`}
              disabled={isDisableForm}
            >
              {EVENT.ADD_IMAGE}
              <input type="file" accept=".jpg, .jpeg, .png" style={{ display: 'none' }} onChange={onChangeEventImage} />
            </Button>
          </CardActions>
        </Box>
      </Box>
    </>
  )
}

type EventDateProps = {
  control: any
  name: string
  errors: any
  isDisableForm: boolean
}
const EventDate = (props: EventDateProps) => {
  const { control, name, errors, isDisableForm } = props
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <DateTimePicker
            format="yyyy/MM/dd HH:mm"
            error={Boolean(errors)}
            helperText={errors?.message || ''}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <CalendarToday />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            fullWidth
            disabled={isDisableForm}
            onChange={onChange}
            value={value}
          />
        )}
      />
    </MuiPickersUtilsProvider>
  )
}

const NoticeSection = () => {
  const notice = useSelector((state: any) => state?.noticeReducer?.notice)
  const classes = useStyles()

  return (
    <>
      {!!notice.length && (
        <Box className={classes.alertWrap}>
          <AlertMessage type="error" title={EVENT.ERR_TITLE}>
            <ul>
              {notice.map((err: string, index: number) => {
                return (
                  <li key={index}>
                    <Typography className={classes.noticeMessage}>{err}</Typography>
                  </li>
                )
              })}
            </ul>
          </AlertMessage>
        </Box>
      )}
    </>
  )
}

type ImageSectionProps = {
  setValue: any
  register: UseFormRegister<FormValues>
  errors: any
  watch: any
  clearErrors: any
  isDisableForm: any
}
const ImageSection = (props: ImageSectionProps) => {
  const { setValue, register, errors, watch, clearErrors, isDisableForm } = props
  const classes = useStyles()

  return (
    <>
      <Container className={classes.container}>
        <Grid container>
          <Grid item xs={6}>
            <EventImage
              name="eventImage"
              setValue={setValue}
              register={register}
              errors={errors.eventImage}
              watch={watch}
              clearErrors={clearErrors}
              isDisableForm={isDisableForm}
            />
          </Grid>
          <Grid item xs={6}>
            <EventImage
              name="eventImageDetail"
              setValue={setValue}
              register={register}
              errors={errors.eventImageDetail}
              watch={watch}
              clearErrors={clearErrors}
              isDisableForm={isDisableForm}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

type InputSectionProps = {
  control: any
  errors: any
  isDisableForm: boolean
  isDisableIndex: boolean
  isDisplayUploadImage: boolean
}
const InputSection = (props: InputSectionProps) => {
  const { control, errors, isDisableForm, isDisableIndex, isDisplayUploadImage } = props
  const storeEvent = useSelector((state: any) => state?.eventReducer?.event)
  const classes = useStyles()

  const [isDisableId, setIsDisableId] = useState(false)

  React.useEffect(() => {
    setIsDisableId(storeEvent?.data?.eventId ? true : false)
  }, [storeEvent?.data?.eventId])

  return (
    <>
      <Grid container spacing={4}>
        {/* キャンペーンID */}
        <Grid item xs={6}>
          <Box className={classes.requiredLabel}>
            <Typography>{EVENT.ID_LABEL}</Typography>
            <Typography>{EVENT.REQUIRED_LABEL}</Typography>
          </Box>
          <Controller
            name="eventId"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                className={classes.eventId}
                variant="outlined"
                autoComplete="off"
                type="number"
                fullWidth
                disabled={isDisableId}
                error={Boolean(errors.eventId)}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}></Grid>

        {/* 開始日と終了日 */}
        <Grid item xs={6}>
          <Typography>{EVENT.EVENT_START_DATE_LABEL}</Typography>
          <Box mt={1} className={classes.eventDate}>
            <EventDate control={control} name="startDate" errors={errors.startDate} isDisableForm={isDisableForm} />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Typography>{EVENT.EVENT_END_DATE_LABEL}</Typography>
          <Box mt={1} className={classes.eventDate}>
            <EventDate control={control} name="endDate" errors={errors.endDate} isDisableForm={isDisableForm} />
          </Box>
        </Grid>

        {/* タイトル */}
        <Grid item xs={12}>
          <Typography>{EVENT.HEADLINE_TEXT_LABEL}</Typography>
          <Controller
            name="headLineText"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                multiline
                variant="outlined"
                autoComplete="off"
                fullWidth
                error={Boolean(errors.headLineText)}
                disabled={isDisableForm}
              />
            )}
          />
        </Grid>

        {/* 本文 */}
        <Grid item xs={12}>
          <Typography>{EVENT.DESCRIPTION_LABEL}</Typography>
          <Controller
            name="eventDescription"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                multiline
                variant="outlined"
                fullWidth
                error={Boolean(errors.eventDescription)}
                disabled={isDisableForm}
              />
            )}
          />
          <Box mt={1}>
            <Typography variant="body2" className={classes.helperText}>
              {EVENT.DESCRIPTION_HELPER_TEXT_1}
            </Typography>
            <Typography variant="body2" className={classes.helperText}>
              {EVENT.DESCRIPTION_HELPER_TEXT_2}
            </Typography>
            <Typography variant="body2" className={classes.helperText}>
              {EVENT.DESCRIPTION_HELPER_TEXT_3}
            </Typography>
            <Typography variant="body2" className={classes.helperText}>
              {EVENT.DESCRIPTION_HELPER_TEXT_4}
            </Typography>
          </Box>
        </Grid>

        {/* 一覧への表示不可 */}
        <Grid item xs={6}>
          <Typography>{EVENT.DISPLAY_INDEX_LABEL}</Typography>
          <FormControlLabel
            control={
              <Controller
                name="isDisableIndex"
                control={control}
                render={({ field }) => <Switch {...field} color="primary" disabled={isDisableForm} checked={field.value}  />}
              />
            }
            label={EVENT.DISPLAY_INDEX_ON_LABEL}
          />
          <Typography variant="body2" className={classes.helperText}>
            {EVENT.DISPLAY_INDEX_HELPER_TEXT}
          </Typography>
        </Grid>

        {/* 掲載終了日時 */}
        <Grid item xs={6}>
          <Box hidden={!isDisableIndex}>
            <Box className={classes.requiredLabel}>
              <Typography>{EVENT.DISPLAY_END_DATE_LABEL}</Typography>
              <Typography>{EVENT.REQUIRED_LABEL}</Typography>
            </Box>
            <EventDate
              control={control}
              name="displayEndDate"
              errors={errors.displayEndDate}
              isDisableForm={isDisableForm}
            />
          </Box>
        </Grid>

        {/* キャンペーンエントリー受付不可 */}
        <Grid item xs={12}>
          <Typography>{EVENT.DISPLAY_DURATION_LABEL}</Typography>
          <FormControlLabel
            control={
              <Controller
                name="isDisableDuration"
                control={control}
                render={({ field }) => <Switch {...field} color="primary" disabled={isDisableForm} checked={field.value}  />}
              />
            }
            label={EVENT.DISPLAY_DURATION_ON_LABEL}
          />
          <Typography variant="body2" className={classes.helperText}>
            {EVENT.DISPLAY_DURATION_HELPER_TEXT}
          </Typography>
        </Grid>

        {/* カード登録可否 */}
        <Grid item xs={12}>
          <Typography>{EVENT.CHECK_REGISTER_LABEL}</Typography>
          <FormControlLabel
            control={
              <Controller
                name="checkRegisterCard"
                control={control}
                render={({ field }) => <Switch {...field} color="primary" disabled={isDisableForm} checked={field.value}  />}
              />
            }
            label={EVENT.CHECK_REGISTER_ON_LABEL}
          />
          <Typography variant="body2" className={classes.helperText}>
            {EVENT.CHECK_REGISTER_HELPER_TEXT}
          </Typography>
        </Grid>

        {/* 写真投稿可能 */}
        <Grid item xs={6}>
          <Typography>{EVENT.DISPLAY_UPLOAD_LABEL}</Typography>
          <FormControlLabel
            control={
              <Controller
                name="isDisplayUploadImage"
                control={control}
                render={({ field }) => (
                  <Switch {...field} color="primary" disabled={isDisableForm} checked={field.value} />
                )}
              />
            }
            label={EVENT.DISPLAY_UPLOAD_ON_LABEL}
          />
          <Typography variant="body2" className={classes.helperText}>
            {EVENT.DISPLAY_UPLOAD_HELPER_TEXT}
          </Typography>
        </Grid>

        {/* 画像投稿エリアのタイトル */}
        <Grid item xs={6}>
          <Box hidden={!isDisplayUploadImage}>
            <Box className={classes.requiredLabel}>
              <Typography>{EVENT.IMAGE_TEXT_LABEL}</Typography>
              <Typography>{EVENT.REQUIRED_LABEL}</Typography>
            </Box>
            <Controller
              name="imageText"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="outlined"
                  autoComplete="off"
                  error={Boolean(errors.imageText)}
                  disabled={isDisableForm}
                  fullWidth
                />
              )}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

const initialIsVisibleButton = {
  save: false,
  reserveOpen: false,
  reserveCancel: false,
  delete: false,
  preview: false,
  duplicate: false,
  csv: false,
}
type ButtonSectionProps = {
  id: string | null
  accessToken: string
  tenantId: string
  setButtonName: any
  setLogButtonName: any
}
const ButtonSection = (props: ButtonSectionProps) => {
  const { id, accessToken, tenantId, setButtonName, setLogButtonName } = props

  const storeEvent = useSelector((state: any) => state?.eventReducer?.event)
  const { appendModal, closeAllModals } = React.useContext(ModalsContext)
  const [isVisibleButton, setIsVisibleButton] = useState(initialIsVisibleButton)
  const [eventEntryCsvDl, setEventEntryCsvDl] = React.useState(false)
  const dispatch = useDispatch()
  const history = useHistory()
  const classes = useStyles()
  const auth = useAuth()

  /**
   * ステータスによってボタンの表示を制御する
   */
  const setIsDisableButtons = React.useCallback((status = '') => {
    switch (status) {
      case 'created':
        setIsVisibleButton({
          ...initialIsVisibleButton,
          save: true,
          reserveOpen: true,
          delete: true,
          preview: true,
          duplicate: true,
        })
        break
      case 'reserved':
        setIsVisibleButton({
          ...initialIsVisibleButton,
          save: true,
          reserveCancel: true,
          preview: true,
          duplicate: true,
        })
        break
      case 'opened':
        setIsVisibleButton({
          ...initialIsVisibleButton,
          save: true,
          preview: true,
          duplicate: true,
          csv: true,
        })
        break
      case 'ended':
        setIsVisibleButton({
          ...initialIsVisibleButton,
          duplicate: true,
          csv: true,
        })
        break
      default:
        setIsVisibleButton({
          ...initialIsVisibleButton,
          save: true,
          reserveOpen: true,
          preview: true,
        })
        break
    }
  }, [])

  /**
   * ボタン押下後、押されたボタン名をstateに保持する
   * 保存ボタンはサブミットされないので、別途保存処理を行う
   * @param buttonName ボタン名
   */
  const onClickButton = (buttonName: string) => {
    dispatch(clearNotice())
    setButtonName(buttonName)
    setLogButtonName(buttonName)
  }
  /** 削除確認モーダルの確認後、削除を行う */
  const onAgreeOfConfirmDeleteModal = () => {
    closeAllModals()

    const callback = () => {
      history.push('../')
    }
    dispatch(removeEvent(id, accessToken, tenantId, callback))
  }
  /** 予約取消確認モーダルの確認後、予約取消を行う */
  const onAgreeOfConfirmCancelReservedModal = async () => {
    closeAllModals()
    setLogButtonName(EVENT.RESERVE_CANCEL_BTN_TITLE)
    const {
      createdAt,
      createdBy,
      updatedAt,
      updatedBy,
      tenantId,
      status,
      eventListPreviewUrl,
      eventDetailPreviewUrl,
      eventId,
      ...rest
    } = storeEvent.data

    const body: PutEventBody = {
      ...rest,
      isReservedOpen: false,
    }
    const callback = {
      success: () => scrollToTop(),
      error: () => scrollToTop(),
    }
    dispatch(updateEvent(id, accessToken, tenantId, body, callback))
  }
  /** 削除ボタンが押された場合、確認モーダルを表示する */
  const onClickDelete = () => {
    appendModal(<ConfirmDeleteModal onSubmit={() => onAgreeOfConfirmDeleteModal()} />)
  }
  /** 予約キャンセルボタンが押された場合、確認モーダルを表示する */
  const onClickCancelReserved = () => {
    appendModal(<ConfirmCancelReservedModal onSubmit={() => onAgreeOfConfirmCancelReservedModal()} />)
  }
  /** 一覧プレビュー押下時、 一覧プレビュー用のQRコードを表示する */
  const onClickPreviewList = () => {
    appendModal(
      <NoticePreviewModal header={EVENT.MODAL_PREVIEW_LIST_TITLE} content={storeEvent.data.eventListPreviewUrl} />,
    )
  }
  /** 詳細プレビュー押下時、 詳細プレビュー用のQRコードを表示する */
  const onClickPreviewDetail = () => {
    appendModal(
      <NoticePreviewModal header={EVENT.MODAL_PREVIEW_DETAIL_TITLE} content={storeEvent.data.eventDetailPreviewUrl} />,
    )
  }

  const onClickDuplicate = (event: any) => {
    history.push({ pathname: '../create', state: { event: event } })
  }

  const onClickCsv = (event: any) => {
    appendModal(<ConfirmDownloadCsvModal event={event} accessToken={accessToken} tenantId={tenantId} />)
  }

  React.useEffect(() => {
    setIsDisableButtons(storeEvent.data?.status || '')
  }, [storeEvent.data])

  React.useEffect(() => {
    const currentTenant = auth.tenants.find(tenant => tenant.tenantId === tenantId)
    setEventEntryCsvDl(!currentTenant ? false : currentTenant.permissions.eventEntryCsvDl)
  }, [auth])

  return (
    <>
      <Grid container>
        {/* プレビューエリア */}
        <Grid item xs={6}>
          {/* 一覧プレビューボタン */}
          {isVisibleButton.preview && (
            <Box pb={3}>
              <Box className={classes.previewBtnGroup}>
                <Typography className={classes.previewTitle} variant="body2">
                  {EVENT.PREVEW_DESCRIPTION}
                </Typography>
                <Box mb={1}>
                  <Button
                    type="button"
                    startIcon={<TabletMacOutlined />}
                    variant="contained"
                    color="primary"
                    onClick={() => onClickPreviewList()}
                    disabled={storeEvent.data && storeEvent.data.status ? false : true}
                  >
                    {EVENT.PREVIEW_LIST_BTN_TITLE}
                  </Button>
                  <Button
                    type="button"
                    style={{ marginLeft: '10px' }}
                    startIcon={<TabletMacOutlined />}
                    variant="contained"
                    color="primary"
                    onClick={() => onClickPreviewDetail()}
                    disabled={storeEvent.data && storeEvent.data.status ? false : true}
                  >
                    {EVENT.PREVIEW_DETAIL_BTN_TITLE}
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
        </Grid>
        {/* ボタンエリア */}
        <Grid item xs={6}>
          <Box className={classes.submitSection}>
            {/* 保存ボタン */}
            {isVisibleButton.save && (
              <Box pb={3}>
                <StartIconButton
                  icon={<Save />}
                  type={
                    storeEvent.data?.status === 'reserved' || storeEvent.data?.status === 'opened' ? 'submit' : 'button'
                  }
                  color="primary"
                  onClick={() => onClickButton(EVENT.SAVE_BTN_TITLE)}
                >
                  {EVENT.SAVE_BTN_TITLE}
                </StartIconButton>
              </Box>
            )}

            {/* 公開 */}
            {isVisibleButton.reserveOpen && (
              <Box pb={3}>
                <StartIconButton
                  icon={<CalendarToday />}
                  type="submit"
                  color="primary"
                  backgroundColor={COLORS.SUBMIT_BTN_BLUE}
                  onClick={() => onClickButton(EVENT.RESERVE_OPEN_BTN_TITLE)}
                >
                  {EVENT.RESERVE_OPEN_BTN_TITLE}
                </StartIconButton>
              </Box>
            )}

            {/* 予約取消 */}
            {isVisibleButton.reserveCancel && (
              <Box pb={3}>
                <StartIconButton
                  icon={<EventBusy />}
                  type="submit"
                  color="secondary"
                  backgroundColor={COLORS.RED_1}
                  onClick={onClickCancelReserved}
                >
                  {EVENT.RESERVE_CANCEL_BTN_TITLE}
                </StartIconButton>
              </Box>
            )}

            {/* 削除 */}
            {isVisibleButton.delete && (
              <Box pb={3}>
                <StartIconButton
                  icon={<DeleteForever />}
                  type="button"
                  color="secondary"
                  backgroundColor={COLORS.RED_1}
                  onClick={onClickDelete}
                >
                  {EVENT.DELETE_BTN_TITLE}
                </StartIconButton>
              </Box>
            )}

            {/* 複製・CSV */}
            <Box pb={3} style={{ display: 'flex', gap: '3px' }}>
              {isVisibleButton.duplicate && (
                <Button variant="outlined" color="primary" onClick={() => onClickDuplicate(storeEvent.data)}>
                  {EVENT.DUPLICATE_BTN_TITLE}
                </Button>
              )}
              {isVisibleButton.csv && (
                <Box hidden={!eventEntryCsvDl}>
                  <Button variant="outlined" color="primary" onClick={() => onClickCsv(storeEvent.data)}>
                    {EVENT.CSV_BTN_TITLE}
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

interface StartIconButtonProps {
  icon: React.ReactNode
  type: 'button' | 'reset' | 'submit'
  color?: 'inherit' | 'primary' | 'secondary' | 'default'
  backgroundColor?: string
  onClick: any
  children: React.ReactChild
}
const StartIconButton: React.FC<StartIconButtonProps> = props => {
  const { icon, type, color, onClick, backgroundColor, children } = props
  const classes = useStyles()

  return (
    <>
      <Button
        type={type}
        variant="contained"
        color={color}
        className={classes.iconButton}
        style={{ backgroundColor: backgroundColor }}
        onClick={onClick}
      >
        <div className="wrapper">
          <div className="icon">{icon}</div>
          <div className="label">{children}</div>
        </div>
      </Button>
    </>
  )
}
