import React from 'react'
import {
  CardMedia,
  CardContent,
  CardActions,
  Button,
  Box,
  List,
  ListItem,
  Theme,
  makeStyles,
  Typography,
  CircularProgress,
} from '@material-ui/core'
import { Add } from '@material-ui/icons'
import { Domain } from '../../../../features'
import { RichmenuAreaInput } from './RichmenuAreaInput'
import { Controller, FieldValues, UseFormRegister } from 'react-hook-form'
import { hooks, api } from '../../../../libs'
import { UploadFileName } from '../../../molecules'
import { COLORS, RICHMENU } from '../../../../constants'
import { RichMenuTypeKey, RichMenuType } from '../../../../features/enum'
import { useDispatch } from 'react-redux'
import { removeNotice, setNotice } from '../../../../stores/notice/notice.action'
import { SubmenuType } from '../../../../features/domain/richmenu'

const ADD_IMAGE = 'イメージを選択する'
const IMAGE_MIN_WIDTH = 800
const IMAGE_MAX_WIDTH = 2500
const IMAGE_MIN_HEIGHT = 250
const IMAGE_MIN_RATIO = 1.45
const IMAGE_MAX_SIZE = 1000000

const useStyles = makeStyles((theme: Theme) => ({
  media: {
    height: 0,
    paddingTop: '67%',
    marginBottom: 12,
  },
  fileNameWrapper: {
    width: 'auto',
    padding: 0,
  },
  uploadImgBtnWrapper: {
    justifyContent: 'center',
    paddingTop: 12,
    paddingBottom: 20,
  },
  uploadImgBtn: {
    borderRadius: theme.spacing(5),
    padding: '6px 25px',
  },
  areaItem: {
    display: 'block',
    padding: '0 0 20px 0',
  },
  areaList: {
    padding: '0 0 34px 0',
  },
  listItemDefault: {
    paddingLeft: 34,
    paddingRight: 36,
  },
  listItemRegist: {
    paddingLeft: 36,
    paddingRight: 34,
  },
  spinner: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%)`,
  },
  spinnerWrap: {
    position: 'relative',
    paddingTop: '67%',
    marginBottom: 10,
    height: 0,
    backgroundColor: COLORS.CXD_GRAY_100,
  },
  richmenuName: {
    marginBottom: 10,
  },
  error: {
    fontWeight: 'bold',
    color: COLORS.RED_1,
  },
}))

interface Props {
  register: UseFormRegister<FieldValues>
  control: any
  setValue: any
  status: number
  data: Domain.Richmenu.RichmenuType | undefined
  fields: any[]
  errors: any
  setError: any
  clearErrors: any
  subMenu: SubmenuType[]
  getValues: any
}

export const DefaultBound = (props: any) => {
  const { value, onChange } = props

  React.useEffect(() => {
    onChange(value)
  }, [value, onChange])

  return <></>
}

export const Richmenu = (props: Props) => {
  const { status, data, register, control, fields, setValue, errors, setError, clearErrors, subMenu, getValues } = props

  const classes = useStyles()
  const { accessToken } = hooks.useAuth()
  const { tenantId } = hooks.useTenant()
  const [image, setImage] = React.useState({
    src: '../assets/images/richmenu-default.jpg',
    name: RICHMENU.NOT_SELECT_IMAGE_TITLE,
  })

  const dispatch = useDispatch()

  const fetchImage = (path: string, name?: string) => {
    dispatch(api.getThunk(`rich-menu/images?path=${path}`, accessToken, tenantId))
      .then((res: any) => res.blob())
      .then((res: any) => {
        const temp = URL.createObjectURL(res)
        const imageName = name ? name : decodeURIComponent(path).split('/').pop()
        setImage({
          name: imageName || RICHMENU.NOT_SELECT_IMAGE_TITLE,
          src: temp,
        })
        setValue('image', imageName)
      })
  }

  const handleClickRichmenuImage = (e: any) => {
    e.target.value = ''
  }

  React.useEffect(() => {
    if (data && data.image) {
      fetchImage(data.image)
    }
  }, [data])

  const [uploading, setUploading] = React.useState(false)
  const handleChangeRichmenuImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file: any = (e.target.files as FileList)[0]
    if (file) {
      const imageTemp = new Image()
      imageTemp.src = URL.createObjectURL(file)
      imageTemp.onload = async () => {
        const width = imageTemp.width
        const height = imageTemp.height
        if (
          width >= IMAGE_MIN_WIDTH &&
          width <= IMAGE_MAX_WIDTH &&
          height >= IMAGE_MIN_HEIGHT &&
          width / height >= IMAGE_MIN_RATIO &&
          file.size <= IMAGE_MAX_SIZE
        ) {
          setUploading(true)
          try {
            const response = await dispatch(
              api.postFileThunk(
                `rich-menu/upload/${RichMenuTypeKey[props.status]}?path=${encodeURIComponent(file.name)}`,
                accessToken,
                tenantId,
                file,
                file.type,
              ),
            )
            await fetchImage(response.path, response.fullName)
            setUploading(false)
            await setValue(`richmenuImages[${RichMenuTypeKey[props.status]}]`, response.path)
            await dispatch(removeNotice(RICHMENU.UPLOAD_IMG_FAILED_MSG))
          } catch {
            setUploading(false)
            dispatch(setNotice(RICHMENU.UPLOAD_IMG_FAILED_MSG))
          }
        } else {
          setImage({
            name: file.name || RICHMENU.NOT_SELECT_IMAGE_TITLE,
            src: imageTemp.src,
          })
          await setValue(`richmenuImages[${RichMenuTypeKey[props.status]}]`, 'invalid')
        }
      }
    }
  }
  return (
    <>
      <input {...register(`richmenuData[${RichMenuTypeKey[props.status]}].chatBarText`)} hidden={true} />
      <input {...register(`richmenuData[${RichMenuTypeKey[props.status]}].name`)} hidden={true} />
      <input {...register(`richmenuData[${RichMenuTypeKey[props.status]}].selected`)} hidden={true} />
      <input {...register(`richmenuData[${RichMenuTypeKey[props.status]}].size.width`)} hidden={true} />
      <input {...register(`richmenuData[${RichMenuTypeKey[props.status]}].size.height`)} hidden={true} />
      <Box paddingX={7}>
        <Typography className={classes.richmenuName}>
          {RICHMENU.RICH_MENU_NAME[RichMenuTypeKey[props.status]]}
        </Typography>
        {!uploading ? (
          <CardMedia className={classes.media} image={image.src} title="" />
        ) : (
          <div className={classes.spinnerWrap}>
            <div className={classes.spinner}>
              <CircularProgress />
            </div>
          </div>
        )}
        <CardContent className={classes.fileNameWrapper}>
          <UploadFileName
            fileName={image.name}
            error={errors.richmenuImages && !!errors.richmenuImages[RichMenuTypeKey[props.status]]}
            type={1}
          />
          {errors.richmenuImages && (
            <p className={classes.error}>
              {errors.richmenuImages[RichMenuTypeKey[props.status]]?.type === 'invalid' &&
                errors.richmenuImages[`${RichMenuTypeKey[status]}`]?.message}
            </p>
          )}
        </CardContent>
        <CardActions className={classes.uploadImgBtnWrapper} disableSpacing>
          <Button
            variant="contained"
            component="label"
            startIcon={<Add />}
            color="primary"
            className={`${classes.uploadImgBtn} disabled disabled-img-btn`}
          >
            {ADD_IMAGE}
            <input
              type="file"
              accept=".jpg, .jpeg, .png"
              style={{ display: 'none' }}
              onChange={handleChangeRichmenuImage}
              onClick={(e: any) => handleClickRichmenuImage(e)}
            />
          </Button>
        </CardActions>
      </Box>
      <Controller
        render={({ field: { onChange, value } }) => <DefaultBound onChange={onChange} value={value} />}
        defaultValue={null}
        name={`richmenuImages[${RichMenuTypeKey[props.status]}]`}
        control={control}
      />
      <List
        className={`${classes.areaList} ${
          status === RichMenuType.DEFAULT ? classes.listItemDefault : classes.listItemRegist
        }`}
      >
        {fields.map((item, index) => (
          <ListItem key={item.id} className={classes.areaItem}>
            <Controller
              render={({ field: { onChange } }) => (
                <RichmenuAreaInput
                  status={status}
                  onChange={onChange}
                  value={item.action}
                  areaIndex={index + 1}
                  errors={errors.richmenuData && errors.richmenuData[`${RichMenuTypeKey[status]}`]?.areas[index]}
                  setError={setError}
                  clearErrors={clearErrors}
                  subMenu={subMenu}
                  getValues={getValues}
                />
              )}
              defaultValue={{}}
              name={`richmenuData[${RichMenuTypeKey[props.status]}].areas[${index}].action`}
              control={control}
            />
            <Controller
              render={({ field: { onChange } }) => <DefaultBound onChange={onChange} value={item.bounds} />}
              defaultValue={{}}
              name={`richmenuData[${RichMenuTypeKey[props.status]}].areas[${index}].bounds`}
              control={control}
            />
          </ListItem>
        ))}
      </List>
    </>
  )
}
