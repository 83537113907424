import React, { FC } from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'

/**
 * Interface
 */

export interface GenericTemplateProps {
  headerBar: React.ReactNode
  children: React.ReactNode
}

/**
 * Style
 */

// [theme.breakpoints.up('md')]: {
//   backgroundColor: 'red',
// },

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'grid',
      fallbacks: {
        display: '-ms-grid',
      },
      width: '100%',
      height: '100%',
      '-ms-grid-rows': '64px 1fr 64px',
      '-ms-grid-columns': '1fr',
      gridTemplate: `
      'pageHeaderBar' 64px
      'pageContent' auto
      'pageFooter' 64px
    `,
    },
    headerBar: {
      gridArea: 'pageHeaderBar',
      '-ms-grid-row': 1,
      width: '100%',
      zIndex: theme.zIndex.drawer + 1,
      position: 'fixed',
      top: 0,
      left: 0,
    },
    content: {
      gridArea: 'pageContent',
      '-ms-grid-row': 2,
      width: '100%',
    },
    contentInner: {
      margin: '0 auto',
      padding: '0 1rem',
      [theme.breakpoints.up('sm')]: {
        width: '920px',
      },
    },
    footer: {
      gridArea: 'pageFooter',
      '-ms-grid-row': 2,
      width: '100%',
      position: 'fixed',
      left: 0,
      bottom: 0,
    },
  }),
)

/**
 * Presenter
 */

export const GenericTemplate: FC<GenericTemplateProps> = ({ children, headerBar }) => {
  const classes = useStyles({})
  return (
    <div className={classes.root}>
      <div className={classes.headerBar}>{headerBar && headerBar}</div>
      <div className={classes.content}>
        <div className={classes.contentInner}>{children}</div>
      </div>
    </div>
  )
}
