type State = number | null

export function createInitialState(): State {
  return null
}

export function setResponseStatus(responseStatus: number) {
  return {
    type: 'SET_RESPONSE_STATUS',
    payload: responseStatus,
  }
}

export function clearResponseStatus() {
  return {
    type: 'CLEAR_RESPONSE_STATUS',
  }
}

export function reducer(state = createInitialState(), action: {type: string, payload: number | null}) {
  switch (action.type) {
    case 'SET_RESPONSE_STATUS':
      return state = action.payload
    case 'CLEAR_RESPONSE_STATUS':
      return state = null
    default:
      return state
  }
}
