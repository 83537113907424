import React, { FC } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import * as Router from 'react-router-dom'
//
import { Domain } from '../../../features'
import { hooks } from '../../../libs'
import { SectionPanel, TenantRoleLabel } from '../../molecules'
import { Box, Table, TableHead, TableRow, TableCell, TableBody } from '../../atoms'

const ACCOUNT_NAME = 'アカウント名'
const ACCOUNT_ID = 'アカウントID'
const USER_PERMISSION = 'ユーザー権限'

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableHead: {
      backgroundColor: theme.palette.grey[200],
      '& th': {
        padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
        borderBottom: 0,
      },
    },
    // FIXME: アンカーテキストのスタイルをを共通化する
    link: {
      color: theme.palette.informative[700],
      '&:link': {
        color: theme.palette.informative[700],
        textDecoration: 'underline',
      },
      '&:visited': {
        color: theme.palette.informative[700],
        textDecoration: 'underline',
      },
      '&:hover': {
        color: theme.palette.informative[600],
        textDecoration: 'underline',
      },
      '&:active': {
        color: theme.palette.informative[800],
        textDecoration: 'underline',
        backgroundColor: theme.palette.informative[50],
      },
    },
  }),
)

/**
 * Interface
 */

export type stateProps = {
  tenants: Domain.Auth.TenantsAuth0Interface | undefined
}

export interface TenantListSectionPresenterProps {
  state: stateProps
}

export interface TenantListSectionContainerProps {
  children: (props: TenantListSectionPresenterProps) => React.ReactElement
}

/**
 * Container
 */

export const TenantListSectionContainer: FC<TenantListSectionContainerProps> = props => {
  const { children } = props
  const { tenants } = hooks.useAuth()
  return children({
    state: { tenants },
  })
}

/**
 * Presenter
 */

export const TenantListSectionPresenter: FC<TenantListSectionPresenterProps> = props => {
  const {
    state: { tenants },
  } = props
  const classes = useStyles()

  return (
    <SectionPanel>
      <Box paddingX={10} paddingY={5}>
        <Table>
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell>{ ACCOUNT_NAME }</TableCell>
              <TableCell>{ ACCOUNT_ID }</TableCell>
              <TableCell>{ USER_PERMISSION }</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tenants &&
              tenants.map((tenant: Domain.Auth.TenantAuth0Interface, index: number) => {
                const { tenantId } = tenant
                return (
                  <TableRow key={index}>
                    <TableCell scope="row">
                      <Router.Link className={classes.link} to={`/${tenantId}`}>
                        { tenant.tenantNameJP && tenant.tenantNameJP }
                      </Router.Link>
                    </TableCell>
                    <TableCell>{ tenant.tenantName }</TableCell>
                    <TableCell>
                      <TenantRoleLabel />
                    </TableCell>
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </Box>
    </SectionPanel>
  )
}

/**
 * Combine
 */

export const TenantListSection: FC = () => {
  return (
    <TenantListSectionContainer>
      {props => {
        return <TenantListSectionPresenter {...props} />
      }}
    </TenantListSectionContainer>
  )
}
