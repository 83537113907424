import { combineReducers } from "redux";
import * as types from '../../constants/types';

const initialState = false

export function loading(state = initialState, action: {type: string, payload: any}) {
  switch (action.type) {
    case types.SET_LOADING:
      return action.payload
    default:
      return state
  }
}

const authReducer = combineReducers({
  loading
})

export default authReducer
