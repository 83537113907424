import React, { FC } from 'react'
import { Redirect } from 'react-router-dom'
//
import { CenterAlignmentTemplate } from '../../templates'

/**
 * Interface
 */

interface Auth0Error {
  error: string,
  errorDescription: string,
  state: string
}

export interface CallbackPagePresenterProps {
  authenticated: boolean
  error: Error | Auth0Error | string | undefined
}

/**
 * Presenter
 */

export const CallbackPagePresenter: FC<CallbackPagePresenterProps> = ({ authenticated, error }) => {
  if (error) {
    if (['unauthorized', 'access_denied'].includes((error as Auth0Error).error)) {
      return <Redirect to={{pathname: '/unauthorized', state: error}} />
    } else {
      return <Redirect to="/login" push />
    }
  }
  if (authenticated) {
    return <Redirect to="/select-account" />
  } else {
    return <CenterAlignmentTemplate>Loading...</CenterAlignmentTemplate>
  }
}
