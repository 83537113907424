import * as Domain from '../domain'

type User = Domain.User.Entity | null

export function createInitialState(): User {
  return null
}

export const SET_USER = 'user/set' as const

export function setUser(user: User) {
  return {
    type: SET_USER,
    payload: {
      user,
    },
  }
}

type SetAction = ReturnType<typeof setUser>

export type Action = SetAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_USER:
      return action.payload.user
    default:
      return state
  }
}
