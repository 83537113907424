// TODO: 今後利用する

import React, { forwardRef } from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core/styles'
import { NavLink, NavLinkProps } from 'react-router-dom'

import { Domain } from '../../../features'
import { List, ListItem, ListItemIcon, ListItemText, Collapse, Divider, Icon } from '../../atoms'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuItem: {
      '&.active': {
        background: 'rgba(0, 0, 0, 0.08)',
      },
    },
    menuItemIcon: {
      minWidth: 32,
    },
    menuItemChildren: {
      paddingTop: 8,
      paddingBottom: 8,
      backgroundColor: theme.palette.background.default,
    },
  }),
)

type SidebarMenuItemProps = Domain.Navigation.SidebarMenuItems

type HandleSidebarMenuItemCondition = {
  handleSidebarMenuItemCondition: () => void
}

interface PresenterProps {
  state: {
    name: Domain.Navigation.SidebarName
    icon?: Domain.Navigation.SidebarIcon
    path?: Domain.Navigation.SidebarPath
    items?: Array<Domain.Navigation.SidebarMenuItem>
    isExpandable: boolean | undefined
    isSidebarMenuItemCondition: boolean
  }
  actions: HandleSidebarMenuItemCondition
}

interface ContainerProps {
  name: Domain.Navigation.SidebarName
  icon?: Domain.Navigation.SidebarIcon
  path?: Domain.Navigation.SidebarPath
  items?: Array<Domain.Navigation.SidebarMenuItem>
  children: (props: PresenterProps) => React.ReactElement
}

interface SidebarMenuItemPresenterProps {
  state: {
    path?: string
    className?: string
  }
  actions: HandleSidebarMenuItemCondition
  children: React.ReactElement
}
interface SidebarMenuItemRootPresenterProps {
  state: {
    name: string
    path?: string
    icon?: Domain.Navigation.SidebarIcon
    isExpandable: boolean | undefined
    isSidebarMenuItemCondition: boolean
  }
  actions: HandleSidebarMenuItemCondition
}
interface SidebarMenuItemChildrenPresenterProps {
  state: {
    isExpandable: boolean | undefined
    isSidebarMenuItemCondition: boolean
    items?: Array<Domain.Navigation.SidebarMenuItem>
  }
}

export const SidebarMenuItemContainer: React.FC<ContainerProps> = props => {
  const { name, path, icon, items, children } = props
  const isExpandable = items && items.length > 0
  const [isSidebarMenuItemCondition, setSidebarMenuItemCondition] = React.useState(false)

  function handleSidebarMenuItemCondition() {
    setSidebarMenuItemCondition(!isSidebarMenuItemCondition)
  }

  return children({
    state: {
      name,
      icon,
      path,
      items,
      isExpandable,
      isSidebarMenuItemCondition,
    },
    actions: {
      handleSidebarMenuItemCondition,
    },
  })
}

export const SidebarMenuItemPresenter: React.FC<SidebarMenuItemPresenterProps> = props => {
  const {
    state: { path, className },
    actions: { handleSidebarMenuItemCondition },
    children,
  } = props

  if (!path || typeof path !== 'string') {
    return <ListItem button className={className} children={children} onClick={handleSidebarMenuItemCondition} />
  }

  return (
    <ListItem
      button
      className={className}
      children={children}
      component={forwardRef((props: NavLinkProps, ref: any) => (
        <NavLink exact {...props} innerRef={ref} />
      ))}
      to={path}
    />
  )
}

export const SidebarMenuItemRootPresenter: React.FC<SidebarMenuItemRootPresenterProps> = props => {
  const {
    state: { name, path, icon, isExpandable, isSidebarMenuItemCondition },
    actions: { handleSidebarMenuItemCondition },
  } = props
  const classes = useStyles()
  return (
    <SidebarMenuItemPresenter
      state={{
        path,
        className: classes.menuItem,
      }}
      actions={{
        handleSidebarMenuItemCondition,
      }}
    >
      <>
        {icon && (
          <ListItemIcon className={classes.menuItemIcon}>
            <Icon size="1x" iconName={icon} />
          </ListItemIcon>
        )}
        <ListItemText primary={name} inset={!Icon} />
        {isExpandable && !isSidebarMenuItemCondition && <Icon size="sm" iconName="caret-down" />}
        {isExpandable && isSidebarMenuItemCondition && <Icon size="sm" iconName="caret-up" />}
      </>
    </SidebarMenuItemPresenter>
  )
}

export const SidebarMenuItemChildrenPresenter: React.FC<SidebarMenuItemChildrenPresenterProps> = props => {
  const {
    state: { isExpandable, isSidebarMenuItemCondition, items },
  } = props
  const classes = useStyles()

  return isExpandable && items ? (
    <Collapse in={isSidebarMenuItemCondition} timeout="auto" unmountOnExit>
      <Divider />
      <List className={classes.menuItemChildren} component="div" disablePadding>
        {items.map((item, index) => {
          return (
            <ListItem
              button
              key={index}
              component={forwardRef((props: NavLinkProps, ref: any) => (
                <NavLink exact {...props} innerRef={ref} />
              ))}
              to={item.path}
            >
              <ListItemText primary={item.name} />
            </ListItem>
          )
        })}
      </List>
      <Divider />
    </Collapse>
  ) : null
}

export const SidebarMenuItem: React.FC<SidebarMenuItemProps> = props => {
  return (
    <SidebarMenuItemContainer {...props}>
      {props => {
        const {
          state: { name, path, icon, items, isExpandable, isSidebarMenuItemCondition },
          actions: { handleSidebarMenuItemCondition },
        } = props
        return (
          <>
            <SidebarMenuItemRootPresenter
              state={{ name, path, icon, isExpandable, isSidebarMenuItemCondition }}
              actions={{ handleSidebarMenuItemCondition }}
            />
            <SidebarMenuItemChildrenPresenter state={{ isExpandable, isSidebarMenuItemCondition, items }} />
          </>
        )
      }}
    </SidebarMenuItemContainer>
  )
}
