import * as React from 'react'
import { Provider, useSelector, useDispatch } from 'react-redux'
import { ThemeProvider } from '@material-ui/styles/'
import CssBaseline from '@material-ui/core/CssBaseline'
import { AuthProvider } from './contexts/AuthContext'
import Routes from './Routes'
import { Theme } from './styles'
import { logger } from 'redux-logger'
import rootReducer from './stores/reducer'
import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'

const authCredential = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN as string,
  clientID: process.env.REACT_APP_AUTH0_CLIENT_ID as string,
  redirectUri: process.env.REACT_APP_AUTH0_CALLBACK_URL as string,
  namespace: process.env.REACT_APP_AUTH0_NAMESPACE as string,
}

const onLogger = ['test'].includes(process.env.REACT_APP_ENVIRONMENT as string)
const store = createStore(
  rootReducer,
  onLogger ? applyMiddleware(thunk, logger) :  applyMiddleware(thunk)
)

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={Theme.cxd}>
        <CssBaseline />
        <AuthProvider value={authCredential}>
          <Routes />
        </AuthProvider>
      </ThemeProvider>
    </Provider>
  )
}

export default App
