import React from 'react'
import {
  MODAL_CONFIRM_UPDATE_RESERVED_TITLE,
  MODAL_CONFIRM_UPDATE_RESERVED_CONTENT,
  MODAL_CONFIRM_UPDATE_RESERVED_OK,
} from '../../../../constants/event'
import { PrimaryConfirmModal } from '../../../molecules/ModalContainer/PrimaryDialogTemplate/PrimaryConfirmDialog'

interface ConfirmModalProps {
  onSubmit: any
}

export const ConfirmReservedModal: React.FC<ConfirmModalProps> = props => {
  return (
    <PrimaryConfirmModal
      header={MODAL_CONFIRM_UPDATE_RESERVED_TITLE}
      content={MODAL_CONFIRM_UPDATE_RESERVED_CONTENT}
      onSubmit={props.onSubmit}
      label={MODAL_CONFIRM_UPDATE_RESERVED_OK}
      skipButton={true}
    />
  )
}
