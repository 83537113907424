import { Grid } from '@material-ui/core'
import * as React from 'react'
import { Controller } from 'react-hook-form'
import { FetchStore } from '../../../../../stores/audience/audience.middleware'
import { Box, Checkbox, Divider } from '../../../../atoms'

interface AudienceStoreSelectProps {
  value: FetchStore[]
  onChange: any
  stores: FetchStore[]
}

export const AudienceStoreSelect: React.FC<AudienceStoreSelectProps> = props => {
  const {value, onChange, stores} = props
  const [selectedStores, setSelectedStores] = React.useState<FetchStore[]>([])

  const handleChange = (event: any) => {
    event.persist()
    let temp: any = []
    if (event.target.checked) {
      const pickingStore = stores.find(store => store.storeCd === event.target.value)
      selectedStores.push(pickingStore!)
      temp = [...selectedStores]
    } else {
      temp = selectedStores.filter(x => x.storeCd !== event.target.value)
    }
    setSelectedStores(temp)
    onChange(temp)
  }

  React.useEffect(() => {
    setSelectedStores(value)
  }, [value])

  return (
    <Box mx={-2.5} pb={10} clone>
      <Grid container style={{width:"auto"}}>
        {
          stores?.map((store, i) => (
            <Box key={i} px={2.5} clone>
              <Grid item xs={6}>
                <Box 
                  position="relative">
                  <Box
                    style={{transform: 'translateY(-50%)'}}
                    position="absolute"
                    top="50%"
                    left={9}>
                      <Checkbox
                        id={store.storeCd}
                        color="primary" 
                        value={store.storeCd} 
                        onChange={handleChange}
                        checked={value.findIndex(x => x.storeCd === store.storeCd) >= 0}
                      />
                  </Box>
                  <Box pl={8} py={2} m={0}>
                    <label className="selectbox-label" htmlFor={store.storeCd}>{store.storeCd}_{store.storeName}</label>
                  </Box>
                  <Divider />
                </Box>
              </Grid>
            </Box>
          ))
        }
      </Grid>
    </Box>
  )
}
