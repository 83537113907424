import React, { FC } from 'react'
//
import { GenericTemplate } from '../../templates'
import { HeaderBar, TenantListSection } from '../../organisms'
import { PageHeader } from '../../molecules'
import { Typography } from '../../atoms'

const ACCOUNT_TITLE = 'アカウント'

export const DashboardPagePresenter: FC = () => {
  return (
    <GenericTemplate headerBar={<HeaderBar />}>
      <PageHeader>
        <Typography variant="h3" component="h1">{ ACCOUNT_TITLE }</Typography>
      </PageHeader>
      <TenantListSection />
    </GenericTemplate>
  )
}
