import { Dispatch } from 'redux'
import { getThunk } from '../../libs/api'
import { getStores, getStoresError, getStoresSuccess } from './audience.action'

export type FetchStore = {
  storeCd: string
  storeName: string
}

export type FetchStoresResponse = {
  storeList: FetchStore[]
}

export function fetchStores(accessToken: string | undefined, tenantId: string): any {
  return function (dispatch: Dispatch) {
    dispatch(getStores())
    dispatch(getThunk('stores', accessToken, tenantId)).then((res: any) => {
      dispatch(getStoresSuccess(res))
    })
    .catch((err: any) => {
      dispatch(getStoresError(err))
    })
  }
}

export function downloadCSV(nameFile: string, path: string, accessToken: string | undefined, tenantId: string): any {
  return function (dispatch: Dispatch) {
    return dispatch(getThunk(`audiences/file?path=${path}`, accessToken, tenantId))
    .then((response: any) => response.blob())
    .then((blob: any) => {
        const filename: string = nameFile || 'filename'
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = `${filename}.csv`
        document.body.appendChild(a)
        a.click()    
        a.remove()
      })
      .catch((e: any) => {
        throw e
      })
  }
}
