import * as React from 'react'
import { Route, RouteComponentProps } from 'react-router-dom'
import { AudienceConfirmPage } from './AudienceConfirmPage'
import { AudienceCreatePage } from './AudienceCreatePage'
import { AudienceListPage } from './AudienceListPage'
import CacheRoute from 'react-router-cache-route'
import { AudienceDetailPage } from './AudienceDetailPage'

export const AudiencePage = () => {
  return (
    <>
      <CacheRoute
        path={`/:tenantId/audience/create`}
        cacheKey="AudienceCreatePage" 
        component={AudienceCreatePage}
      />
      <Route
        exact
        path={`/:tenantId/audience/confirm`}
        component={AudienceConfirmPage}
      />
      <Route
        exact
        path={`/:tenantId/audience`}
        component={AudienceListPage}
      />
      <Route
        exact
        path={`/:tenantId/audience/detail/:id`}
        component={AudienceDetailPage}
      />
    </>
  )
}