import { TYPOGRAPHY } from '../constants'

export default {
  htmlFontSize: 16,
  fontSize: 14,
  button: {
    textTransform: 'none',
  },
  fontFamily: [
    '"Helvetica Neue"',
    'Arial',
    '"Hiragino Kaku Gothic ProN"',
    '"Hiragino Sans"',
    '"BIZ UDPGothic"',
    'Meiryo',
    'sans-serif'
  ].join(','),
  h1: {
    fontSize: TYPOGRAPHY.CXD_DESKTOP_FONT_SIZE_900,
    fontWeight: 'bold',
  },
  h2: {
    fontSize: TYPOGRAPHY.CXD_DESKTOP_FONT_SIZE_700,
    fontWeight: 'bold',
  },
  h3: {
    fontSize: TYPOGRAPHY.CXD_DESKTOP_FONT_SIZE_500,
    fontWeight: 'bold',
  },
  h4: {
    fontSize: TYPOGRAPHY.CXD_DESKTOP_FONT_SIZE_300,
    fontWeight: 'bold',
  },
  h5: {
    fontSize: TYPOGRAPHY.CXD_DESKTOP_FONT_SIZE_200,
    fontWeight: 'bold',
  },
  h6: {
    fontSize: TYPOGRAPHY.CXD_DESKTOP_FONT_SIZE_200,
    fontWeight: 'normal',
  },
  subtitle1: {
    fontSize: TYPOGRAPHY.CXD_DESKTOP_FONT_SIZE_200,
    fontWeight: 'bold',
  },
  subtitle2: {
    fontSize: TYPOGRAPHY.CXD_DESKTOP_FONT_SIZE_100,
    fontWeight: 'bold',
  },
  body1: {
    fontSize: TYPOGRAPHY.CXD_DESKTOP_FONT_SIZE_200,
  },
  body2: {
    fontSize: TYPOGRAPHY.CXD_DESKTOP_FONT_SIZE_100,
  },
  caption: {
    fontSize: TYPOGRAPHY.CXD_DESKTOP_FONT_SIZE_75,
    fontWeight: 'bold',
  },
}
