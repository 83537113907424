export const BREADCRUMB_DATA: any = [
  {
    pattern: /^richmenu/,
    labels: ['title.richmenu']
  },
  {
    pattern: /^audience$/,
    labels: ['title.audience', 'title.audienceHistory']
  },
  {
    pattern: /^audience\/create$/,
    labels: ['title.audienceCreatePage']
  },
  {
    pattern: /^audience\/confirm$/,
    labels: ['title.audienceCreatePage', 'title.audienceConfirmPage']
  },
  {
    pattern: /^audience\/detail/,
    labels: ['title.audienceCreatePage', 'title.audienceHistory', 'title.audienceHistoryDetail']
  },
  {
    pattern: /^events$/,
    labels: ['title.event']
  },
  {
    pattern: /^events\/create$/,
    labels: ['title.eventCreatePage']
  },
  {
    pattern: /^events\/edit/,
    labels: ['title.event','title.eventEditPage']
  },
];
