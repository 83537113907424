import * as Domain from '../domain'

type Order = Domain.Order.Entity | null

export function createInitialState(): Order {
  return null
}

export const SET_ORDER = 'order/set' as const

export function setOrder(order: Order) {
  return {
    type: SET_ORDER,
    payload: {
      order,
    },
  }
}

type SetAction = ReturnType<typeof setOrder>

export type Action = SetAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_ORDER:
      return action.payload.order
    default:
      return state
  }
}
