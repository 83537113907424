import React, { FC } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { Redirect } from 'react-router-dom'
//
import { hooks } from '../../../libs'
import { LoginPagePresenter } from './LoginPagePresenter'

/**
 * Interface
 */

interface ChildrenProps {
  actions: {
    handleLogin: () => void
  }
}

interface LoginPageContainerProps {
  children: (props: ChildrenProps) => React.ReactElement
}

/**
 * Container
 */

export const LoginPageContainer: FC<LoginPageContainerProps> = props => {
  const { children } = props
  const { authenticated, login } = hooks.useAuth()

  if (authenticated) {
    return <Redirect to="/" push />
  }

  // TODO: useCallback
  const handleLogin = () => {
    login()
  }

  return children({
    actions: {
      handleLogin,
    },
  })
}

/**
 * Combine
 */

export default (_props: RouteComponentProps) => {
  return (
    <LoginPageContainer>
      {props => {
        return <LoginPagePresenter {...props} />
      }}
    </LoginPageContainer>
  )
}
