import { Pagination } from '@material-ui/lab'
import * as React from 'react'
import { Box, Grid, Icon, Typography } from '../../../atoms'
import { SectionPanel } from '../../../molecules'
import { Link, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button, CircularProgress, makeStyles, Paper } from '@material-ui/core'
import { Divider, FormControl, IconButton, InputAdornment, InputBase, InputLabel, List, ListItem } from '@material-ui/core'
import { getThunk } from '../../../../libs/api'
import { hooks } from '../../../../libs'
import { useDispatch, useSelector } from 'react-redux'
import { AUDIENCE_CONDITIONS, AUDIENCE_LABELS } from '../utility/AudienceData'
import { formatDateTime } from '../../../../libs/date'
import NoticeContext, { NoticeProvider } from '../../../../contexts/NoticeContext'
import Renderer from '../../../molecules/Renderer'
import { setLoading } from '../../../../stores/loading/loading.action'
import { AUDIENCE_STATUS_LABELS } from '../utility/AudienceData'
import { downloadCSV } from '../../../../stores/audience/audience.middleware'
import { NoticeDownloadCSVFailed } from '../modals'
import { ModalsContext } from '../../../../contexts'

const useStyles = makeStyles({
  customList: {
    paddingTop: '20px'
  },
  iconPos: {
    marginRight: '-12px',
    padding: '12px'
  },
  filterAudience: {
    '& input::placeholder': { 
      fontSize: '14px'
    }
  },
  nameAudience: {
    fontSize: '14px',
    lineHeight: '24px'
  },
  descriptionAudience: {
    fontSize: '14px',
    lineHeight: '24px',
    margin: 0
  },
  sectionHeight: {
    height: '100%'
  },
  resetMuiButtonStartIcon: {
    '& .MuiButton-startIcon': { 
      marginLeft: 0,
      marginRight: 0
    },
    '& .MuiButton-startIcon .MuiCircularProgress-root ': {
      marginRight: '5px'
    }
  }
})

const readmorePipe = (text: string, maxLength = 120) => {
  if (text) {
    if (text.length > maxLength) {
      let maxText = text.slice(0, maxLength)
      if(maxText[maxLength - 1] === '、') {
        maxText = text.slice(0, maxLength - 1)
      }
      return `${maxText}…`
    } else {
      return text
    }
  } else {
    return ''
  }
}

interface AudienceListContentProps {
  audiences: any[] | null
  limit: number
  errors: any
  currentPage: number
  onFilterChange: any
}


export const AudienceListContent: React.FC<AudienceListContentProps> = (props) => {
  const { tenantId } = hooks.useTenant()
  const { accessToken } = hooks.useAuth()
  const { audiences, limit, errors, currentPage, onFilterChange } = props
  const { t } = useTranslation(['audience', 'translation'])
  const [audiencesPerPage, setAudiencesPerPage] = React.useState<any[]>([])
  const [audiencesFilter, setAudiencesFilter] = React.useState<any[]>([])
  const classes = useStyles()
  const { changeModal } = React.useContext(ModalsContext)
  const [searchProps, setSearchProps] = React.useState({
    name: '',
    stores: '',
  });
  const [cacheSearchProps, setCacheSearchProps] = React.useState({
    name: '',
    stores: '',
  });
  const history = useHistory()
  const { setNoticeTitle, pushNoticeContent, clearNotice } = React.useContext(NoticeContext)
  const dispatch = useDispatch()

  const renderAudiencesPerPage = (page: number) => {
    setAudiencesPerPage(audiencesFilter!.slice(limit * (page - 1), limit * page))
  }

  React.useEffect(() => {
    if (audiences) {
      setAudiencesFilter(audiences)
    }
  }, [audiences])

  React.useEffect(() => {
    if (currentPage > 0) {
      renderAudiencesPerPage(currentPage)
    }
  }, [audiencesFilter, currentPage, limit])

  React.useEffect(() => {
    if (errors) {
      setNoticeTitle(t('translation:notice.fetchData.title'))
      pushNoticeContent(t('translation:notice.fetchData.content.errorFetch'))
    } else {
      clearNotice()
    }
  }, [errors])

  React.useEffect(() => {
    if (audiences) {
      if (cacheSearchProps.name || cacheSearchProps.stores) {
        let temp = []
        temp = audiences.filter(au => {
          if (cacheSearchProps.name && !cacheSearchProps.stores) {
            return au.audienceName.toLowerCase().includes(cacheSearchProps.name.toLowerCase())
          }
          if (cacheSearchProps.stores && !cacheSearchProps.name) {
            return au?.storeAt?.storeCdNames?.join(',')?.toLowerCase().includes(cacheSearchProps.stores.toLowerCase())
          }
          if (cacheSearchProps.stores && cacheSearchProps.name) {
            return au.audienceName.toLowerCase().includes(cacheSearchProps.name.toLowerCase()) && au?.storeAt?.storeCdNames?.join(',')?.toLowerCase().includes(cacheSearchProps.stores.toLowerCase())
          }
        })
        setAudiencesFilter(temp)
        onFilterChange(temp)
      } else {
        setAudiencesFilter(audiences)
        onFilterChange(audiences)
      }
    }
  }, [cacheSearchProps])

  const triggerSearch = () => {
    setCacheSearchProps(searchProps)
  }

  const triggerSearchEnter = (event: any) => {
    if (event.keyCode === 13) {
      event.preventDefault()
      triggerSearch()
    }
  }

  const handleSearch = (key: string, e: any) => {
    setSearchProps({
      ...searchProps,
      [key]: e.target.value
    })
  }

  const downloadCSVHandler = (name: string, path: string, ind: number) => {
    audiencesPerPage[ind].loading = true
    setAudiencesPerPage([...audiencesPerPage])
    dispatch(downloadCSV(name, path, accessToken, tenantId))
      .catch(_e => {
        changeModal(<NoticeDownloadCSVFailed/>)
      })
      .finally(() => {
        audiencesPerPage[ind].loading = false
        setAudiencesPerPage([...audiencesPerPage])
      })
  }

  const duplicateAudience = (id: string) => {
    history.push(`create?audienceId=${id}`)
  }

  const Title = () => {
    let content = '';
    if (audiences?.length > 0 && audiencesFilter.length > 0) {
      content = t('history.title')
    } else if (audiences?.length === 0) {
      content = t('history.empty')
    } else if (audiences?.length > 0 && audiencesFilter.length === 0) {
      content = t('history.filterEmpty')
    }
    return <>{content}</>
  }

  return (
    <>
      <Box pb={3}>
        {
          !errors && 
          <Typography variant="h6">
            <Title/>
          </Typography>
        }
      </Box>
      {
        audiences?.length > 0 && 
        <Box bgcolor="grey.300">
          <Grid container spacing={4}>
            <Box display="flex" alignItems="center" clone>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <Box pl={3} clone>
                    <InputBase
                      className={classes.filterAudience}
                      placeholder={t('history.placeholderName')}
                      onChange={(e) => handleSearch('name', e)}
                      onKeyUp={triggerSearchEnter}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton className={classes.iconPos} 
                            onClick={triggerSearch}
                          >
                            <Icon iconName="search"/>
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </Box>
                </FormControl>
              </Grid>
            </Box>
            <Box display="flex" alignItems="center" clone>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <Box pl={3} clone>
                    <InputBase
                      className={classes.filterAudience}
                      placeholder={t('history.placeholderStore')}
                      onChange={(e) => handleSearch('stores', e)}
                      onKeyUp={triggerSearchEnter}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton className={classes.iconPos} onClick={triggerSearch}>
                            <Icon iconName="search"/>
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </Box>
                </FormControl>
              </Grid>
            </Box>
            <Box display="flex" alignItems="center" clone>
              <Grid item xs={4}>
              </Grid>
            </Box>
          </Grid>
        </Box>
      }
      <List className={classes.customList} component="ul">
        { 
          audiencesPerPage && audiencesPerPage!.map((audience, index) => {
          return (
            <ListItem key={`l-${index}`} disableGutters>
              <Box width="100%">
                <Grid container spacing={4}>
                  <Grid item xs={4}>
                    <Box pl={2}>
                      <Typography color="primary" component={Link} to={`detail/${audience.audienceId}`} className={classes.nameAudience}>
                        {audience.audienceName}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <p className={classes.descriptionAudience}>
                      {
                        (AUDIENCE_CONDITIONS.PATTERN1 || AUDIENCE_CONDITIONS.PATTERN2) &&
                        readmorePipe(audience?.storeAt?.storeCdNames?.join('、'))
                      }
                    </p>
                  </Grid>
                  <Grid item xs={4}>
                    <Box height="100%" pr={1} display="flex" justifyContent="space-between">
                      <Box pr={1}>
                        <p className={classes.descriptionAudience}>{AUDIENCE_LABELS[audience.type]}</p>
                        <p className={classes.descriptionAudience}>{audience.audienceSize}名</p>
                        <p className={classes.descriptionAudience}>{formatDateTime(audience?.createdAt)}</p>
                        <Typography color={+audience.status ? 'primary': 'error'} className={classes.descriptionAudience}>
                          { t(`translation:${AUDIENCE_STATUS_LABELS[audience.status]}`) }
                        </Typography>
                      </Box>
                      <Box height="100%" display="flex" flexDirection="column" justifyContent="space-evenly">
                        <Button
                          variant="outlined"
                          onClick={() => duplicateAudience(audience.audienceId)} 
                          color="primary">
                          {t('translation:copy')}
                        </Button>
                        <Button
                          className={classes.resetMuiButtonStartIcon}
                          variant="outlined"
                          startIcon={audience.loading ? <CircularProgress size={18}/> : <></>}
                          disabled={audience.loading}
                          onClick={() => downloadCSVHandler(audience.audienceName, audience.pathFile, index)}
                          color="primary">
                          CSV
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <Box mt={2}>
                  <Divider />
                </Box>
              </Box>
            </ListItem>
          )
        })
      }
      </List>
    </>
  )
}

export const AudienceListPage = () => {
  const { tenantId } = hooks.useTenant()
  const { accessToken } = hooks.useAuth()
  const [audiences, setAudiences] = React.useState<any[] | null>(null)
  const [currentPage, setCurrentPage] = React.useState(1)
  const [totalPages, setTotalPages] = React.useState(0)
  const [totalFilterPages, setTotalFilterPages] = React.useState(0)
  const [limit, setLimit] = React.useState(0)
  const [errors, setErrors] = React.useState(null)
  const loading = useSelector((state: any) => state?.loadingReducer?.loading)
  const classes = useStyles()
  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(setLoading(true))
    dispatch(getThunk('audiences/list', accessToken, tenantId))
      .then((res: any) => {
        if (res) {
          setAudiences(res.data)
          setTotalPages(res.pages)
          setLimit(res.limit)
        }
      })
      .catch((e: any) => {
        setErrors(e)
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }, [])

  const onPaginationChange = (_e: any, page: any) => {
    setCurrentPage(page)
    setTimeout(() => {
      window.scrollTo({
        top: document.body.scrollHeight,
        left: 0,
        behavior: 'smooth'
      })
    })
  }

  const filterHandler = (items: any) => {
    setTotalFilterPages(Math.ceil(items.length / limit))
    setCurrentPage(1)
  }

  return (
    <Box pb={7} height="calc(100% - 57px)">
      <section className={classes.sectionHeight}>
        <Paper className={classes.sectionHeight}>
          <Box px={7} py={3} height="100%">
            <NoticeProvider>
              {
                !loading &&
                <AudienceListContent
                  audiences={audiences}
                  limit={limit}
                  errors={errors}
                  currentPage={currentPage}
                  onFilterChange={filterHandler}
                />
              }
            </NoticeProvider>
          </Box>
        </Paper>
      </section>
      {  
        totalFilterPages > 0 &&
        <Box mt={2} pb={3} display="flex" alignItems="center" justifyContent="center">
          <Pagination
            page={currentPage}
            onChange={onPaginationChange}
            count={totalFilterPages} 
            color="primary" />
        </Box>
      }
    </Box>
  )
}
