import * as Domain from '../domain'

type Notice = Domain.Notice.Entity | null

export function createInitialState(): Notice {
  return null
}

export const SET_NOTICE = 'notice/set' as const
export const CLEAR_NOTICE = 'notice/clear' as const

export function setNotice(notice: Notice) {
  return {
    type: SET_NOTICE,
    payload: {
      notice,
    },
  }
}

export function clearNotice() {
  return {
    type: CLEAR_NOTICE,
  }
}

type SetNoticeAction = ReturnType<typeof setNotice>
type ClearNoticeAction = ReturnType<typeof clearNotice>
export type Action = SetNoticeAction | ClearNoticeAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_NOTICE:
      return action.payload.notice
    case CLEAR_NOTICE:
      return null
    default:
      return state
  }
}
