import { format } from 'date-fns'
import React, { FC } from 'react'
import { MODAL_SUBMIT_CONFIRM_BTN, MODAL_SUBMIT_CONFIRM_CONTENT, MODAL_SUBMIT_CONFIRM_TITLE } from '../../../../constants/richmenu'
import { JP_DATE_FORMAT } from '../../../../constants/utility'
import { changText } from '../../../../libs/functions/common'
import { PrimaryConfirmModal } from '../../../molecules/ModalContainer/PrimaryDialogTemplate/PrimaryConfirmDialog'

interface ConfirmSubmitModalProps {
  onSubmit: any
  triggerTime: string
}

export const ConfirmSubmitModal: React.FC<ConfirmSubmitModalProps> = props => {
  return (
    <PrimaryConfirmModal
      header={MODAL_SUBMIT_CONFIRM_TITLE}
      content={changText(MODAL_SUBMIT_CONFIRM_CONTENT, format(new Date(props.triggerTime), JP_DATE_FORMAT))}
      onSubmit={props.onSubmit}
      label={MODAL_SUBMIT_CONFIRM_BTN}
      skipButton={true}
    />
  )
}
