import React, { useState } from 'react'
import { hooks } from '../../../../libs'
import { useSelector, useDispatch } from 'react-redux'
import { fetchRichmenu } from '../../../../stores/richmenu/richmenu.middleware'
import { RichmenuCreatePresenter } from './RichmenuCreatePresenter'
import { resetRichMenu } from '../../../../stores/richmenu/richmenu.action'
import { SubmenuType } from '../../../../features/domain/richmenu'

export interface  SubmenuInterface {
  subMenuId: string
  baseUrl: string
}

export const RichmenuCreatePage = () => {
  const { tenantId } = hooks.useTenant()
  const { accessToken } = hooks.useAuth()
  const [currentRichmenu, setCurrentRichmenu] = useState<any>()
  const [subMenu, setSubMenu] = useState<SubmenuType[]>()
  const [hasCallApi, setHasCallApi] = useState(false)
  const richmenuHandler = useSelector((state: any) => state?.richmenuReducer?.richmenu)
  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(fetchRichmenu(accessToken, tenantId, 10))
  }, [tenantId])

  React.useEffect(() => {
    return () => {
      dispatch(resetRichMenu())
    }
  }, [])

  React.useEffect(() => {
    if (richmenuHandler.hasError) {
      setCurrentRichmenu(undefined)
      setHasCallApi(true)
    }
    if (!richmenuHandler.hasError && richmenuHandler.data) {
      setCurrentRichmenu(richmenuHandler.data)
      setHasCallApi(true)
    }
    if (!richmenuHandler.hasError && richmenuHandler.subMenu) {
      setSubMenu(richmenuHandler.subMenu)
    }
  }, [richmenuHandler])

  return (
    <>
      {hasCallApi && tenantId && accessToken && (
        <RichmenuCreatePresenter tenantId={tenantId} accessToken={accessToken} richmenu={currentRichmenu} subMenu={subMenu} />
      )}
    </>
  )
}
