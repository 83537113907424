import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
//
import { hooks } from '../../../libs'
import { ProfileMenu } from '../../molecules'
import { Box, Typography } from '../../atoms'

const HEADER_LOGO = 'LINE管理画面'
const ACCOUNT_TITLE = 'アカウント'
/**
 * Interface
 */

interface HeaderBarPresenterProps {
  actions: {
    handleLogout: () => void
  }
}

interface HeaderBarContainerProps {
  children: (props: HeaderBarPresenterProps) => React.ReactElement
}

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.common.white,
    },
    inner: {
      height: '64px',
      display: 'flex',
      alignItems: 'center',
      padding: '0 1rem',
    },
    sidebarAction: {
      marginRight: 8,
    },
    brandLogoType: {
      padding: 0,
      margin: '0 1rem 0 0',
      fontSize: '20px',
      color: theme.palette.common.black,
      textDecoration: 'none'
    },
    selectAccountLink: {
      color: theme.palette.common.black,
      textDecoration: 'none'
    },
    profileAction: {
      marginLeft: 'auto',
    },
  }),
)

/**
 * Container
 */

export const HeaderBarContainer: FC<HeaderBarContainerProps> = props => {
  const { children } = props
  const { logout } = hooks.useAuth()

  const handleLogout = () => {
    logout()
  }

  return children({
    actions: {
      handleLogout,
    },
  })
}

/**
 * Presenter
 */

export const HeaderBarPresenter: FC<HeaderBarPresenterProps> = props => {
  const {
    actions: { handleLogout },
  } = props

  const pathname = window.location.pathname

  const classes = useStyles({})
  return (
    <header>
      <Box className={classes.root} boxShadow={2}>
        <div className={classes.inner}>
          <Typography variant="h1" className={classes.brandLogoType}>{ HEADER_LOGO }</Typography>
          {
            !(pathname === '/' || pathname === '/select-account') && (
              <Typography variant="body1" component={Link} to="/" className={classes.selectAccountLink}>{ ACCOUNT_TITLE }</Typography>
            )
          }
          <div className={classes.profileAction}>
            <ProfileMenu handleLogout={handleLogout} />
          </div>
        </div>
      </Box>
    </header>
  )
}

/**
 * Combine
 */

export const HeaderBar: FC = () => {
  return (
    <HeaderBarContainer>
      {props => {
        return <HeaderBarPresenter {...props} />
      }}
    </HeaderBarContainer>
  )
}
