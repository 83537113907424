import { combineReducers } from "redux";
import * as types from '../../constants/types';

const initialState: any = {
  forceLogout: undefined
};

export function auth(state = initialState, action: {type: string, payload: any}) {
  switch (action.type) {
    case types.SET_FORCE_LOGOUT:
      return {
        ...state,
        forceLogout: action.payload
      }
    default:
      return state
  }
}

const authReducer = combineReducers({
  auth
})

export default authReducer
