import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory, createMemoryHistory } from 'history'
import { composeWithDevTools } from 'redux-devtools-extension'

import createAppReducer, { createInitialState, blacklist } from './modules'

const persistConfig = {
  key: 'https://cxdrive.net',
  storage, // 保存先としてlocalStorageがここで設定される
  // whitelist: ['exampleA'], // Stateは`exampleA`のみStorageに保存する
  blacklist, // `exampleB`は保存しない
}

export const history =
  typeof document !== 'undefined'
    ? createBrowserHistory({
        basename: '/',
        forceRefresh: true,
      })
    : createMemoryHistory()

/**
 * Reducer
 */
const persistedReducer = persistReducer(persistConfig, createAppReducer(history))

const composeEnhancers =
  process.env.NODE_ENV === 'production'
    ? compose(applyMiddleware(thunk), applyMiddleware(routerMiddleware(history)))
    : composeWithDevTools(applyMiddleware(thunk), applyMiddleware(routerMiddleware(history)))

export const cxdStore = createStore(persistedReducer, createInitialState(), composeEnhancers)
export const persistor = persistStore(cxdStore)
