import { AudienceCreatation, ExtractAudienceResponse } from '../../components/pages/AudiencePage/AudienceCreatePage';
import * as types from '../../constants/types';

export type AudienceInfo = AudienceCreatation & ExtractAudienceResponse

export function getStores() {
  return {
    type: types.GET_STORES
  }
}

export function getStoresSuccess(res: any) {
  return {
    type: types.GET_STORES_SUCCESS,
    payload: res
  }
}

export function getStoresError(res: any) {
  return {
    type: types.GET_STORES_ERROR,
    payload: res
  }
}

export function setNewAudienceInfo(data: AudienceInfo) {
  return {
    type: types.SET_NEW_AUDIENCE_INFO,
    payload: data
  }
}
