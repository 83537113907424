import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core'
import * as React from 'react'
import { hooks } from '../../../../libs'
import { fetchStores, FetchStore } from '../../../../stores/audience/audience.middleware'
import { SectionPanel } from '../../../molecules'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { useHistory, useLocation } from 'react-router-dom'
import { Icon } from '../../../atoms'
import { setNewAudienceInfo } from '../../../../stores/audience/audience.action'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import NoticeContext, { NoticeProvider } from '../../../../contexts/NoticeContext'
import { AudienceBasicSection } from './sections/AudienceBasicSection'
import { AudienceStoreSection } from './sections/AudienceStoreSection'
import { useTranslation } from 'react-i18next'
import { NoticeNoUserModal, NoticeQueryUsersModal } from './modals'
import { ModalsContext } from '../../../../contexts'
import { getThunk, post } from '../../../../libs/api'
import { AUDIENCE_CONDITIONS } from '../utility/AudienceData'
import queryString from 'query-string'
import { setLoading } from '../../../../stores/loading/loading.action'

export interface AudienceCreatation {
  audienceType: string
  audienceName: string
  audienceStores: FetchStore[]
}

export interface ExtractAudienceResponse {
  count: number
  fileS3: string
}

class ExtractAudienceBody {
  type: string
  storeCds?: string[]

  constructor(data: AudienceCreatation) {
    this.type = data.audienceType
    if ([AUDIENCE_CONDITIONS.PATTERN1, AUDIENCE_CONDITIONS.PATTERN2].includes(+data.audienceType)) {
      this.storeCds = data.audienceStores.map(x => x.storeCd)
    }
  }
}

const validateSchema = yup.object().shape({
  audienceType: yup.string().required(),
  audienceName: yup.string().required().max(120),
  audienceStores: yup.array().when('audienceType', {
    is: (val: string) => [AUDIENCE_CONDITIONS.PATTERN1, AUDIENCE_CONDITIONS.PATTERN2].includes(+val),
    then: yup.array().min(1),
    otherwise: yup.array(),
  }),
})

export const AudienceCreateContent = () => {
  const { tenantId } = hooks.useTenant()
  const { accessToken } = hooks.useAuth()
  const dispatch = useDispatch()
  const { t } = useTranslation('audience')
  const history = useHistory()
  const { setNoticeTitle, clearNotice } = React.useContext(NoticeContext)
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    reset,
    formState,
    formState: { errors },
  } = useForm<AudienceCreatation>({
    defaultValues: {
      audienceType: AUDIENCE_CONDITIONS.PATTERN1.toString(),
      audienceName: '',
      audienceStores: [],
    },
    mode: 'onChange',
    resolver: yupResolver(validateSchema),
  })
  const watchAudienceType: any = watch('audienceType')
  const watchAudienceStores: any = watch('audienceStores')
  const { appendModal, closeAllModals, changeModal } = React.useContext(ModalsContext)
  const storesHandler = useSelector((state: any) => state?.audienceReducer?.stores)
  const location = useLocation()

  React.useEffect(() => {
    dispatch(fetchStores(accessToken, tenantId))
  }, [tenantId])

  React.useEffect(() => {
    const { audienceId } = queryString.parse(location.search)
    if (audienceId) {
      dispatch(setLoading(true))
      dispatch(getThunk(`audiences/detail/${audienceId}`, accessToken, tenantId))
        .then((response: any) => {
          const dupData: AudienceCreatation = {
            audienceType: response.type,
            audienceName: '',
            audienceStores:
              response.storeAt?.storeCds?.map((x: string, index: number) => ({
                storeCd: x,
                storeName: response.storeAt.storeNames[index],
              })) || [],
          }
          reset(dupData)
        })
        .finally(() => {
          dispatch(setLoading(false))
        })
    }
  }, [location])

  React.useEffect(() => {
    if (
      [AUDIENCE_CONDITIONS.PATTERN1, AUDIENCE_CONDITIONS.PATTERN2].includes(+watchAudienceType) &&
      storesHandler.hasError
    ) {
      dispatch(fetchStores(accessToken, tenantId))
    }
    clearNotice()
  }, [watchAudienceType])

  const extractAudienceApi = async (data: AudienceCreatation) => {
    const body = new ExtractAudienceBody(data)
    try {
      const res: ExtractAudienceResponse = await post('audiences/count_users', accessToken, tenantId, body)
      if (res.count > 0) {
        closeAllModals()
        dispatch(
          setNewAudienceInfo({
            ...data,
            ...res,
          }),
        )
        history.push('confirm')
      } else {
        changeModal(<NoticeNoUserModal />)
      }
    } catch (e) {
      setNoticeTitle(t('error.extract.title'))
      closeAllModals()
    }
  }

  const onSubmit = (data: AudienceCreatation) => {
    clearNotice()
    appendModal(<NoticeQueryUsersModal />)
    extractAudienceApi(data)
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <AudienceBasicSection control={control} />
          <Box my={4}>
            <Divider />
          </Box>
          {
            <Box
              display={
                [AUDIENCE_CONDITIONS.PATTERN1, AUDIENCE_CONDITIONS.PATTERN2].includes(+watchAudienceType)
                  ? 'block'
                  : 'none'
              }
            >
              <AudienceStoreSection
                isLoading={storesHandler.isLoading}
                control={control}
                errors={errors}
                setValue={setValue}
                storesHandler={storesHandler}
                watchAudienceStores={watchAudienceStores}
              />
            </Box>
          }
        </div>
        <Box position="fixed" bottom={28} right={92} display="flex" justifyContent="flex-end">
          <Typography component="div" align="right">
            {[AUDIENCE_CONDITIONS.PATTERN1, AUDIENCE_CONDITIONS.PATTERN2].includes(+watchAudienceType) && (
              <Box color="primary.main" clone>
                <Typography component="span">
                  {watchAudienceStores?.length} {t('button.stores')}
                </Typography>
              </Box>
            )}
            <Box pb={2}>
              <Button
                type="submit"
                startIcon={<Icon iconName="sync-alt" />}
                variant="contained"
                color="primary"
                disabled={!formState.isValid}
              >
                {t('button.submit')}
              </Button>
            </Box>
          </Typography>
        </Box>
      </form>
    </>
  )
}

export const AudienceCreatePage = () => {
  return (
    <Box mb={3}>
      <SectionPanel>
        <Box px={7} py={3}>
          <NoticeProvider>
            <AudienceCreateContent />
          </NoticeProvider>
        </Box>
      </SectionPanel>
    </Box>
  )
}
