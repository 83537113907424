
import { Box, CircularProgress } from '@material-ui/core';
import React from 'react';

const LoadingIndicator = () => {
  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <CircularProgress size={25} />
    </Box>
  )
}

const Renderer = (Wrapped: any) => {
  return function (props: any) {
    return (
      props.isLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          <Wrapped {...props}/>
        </>
      )
    )
  }
}

export default Renderer;