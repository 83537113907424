import React, { FC } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
//
import { TEXT } from '../../../constants'
import { Box } from '../../atoms'

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.common.white,
    },
    inner: {
      height: '64px',
      display: 'flex',
      padding: '0 1rem',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
)

/**
 * Presenter
 */

export const FooterPresenter: FC = () => {
  const classes = useStyles({})
  return (
    <footer>
      <Box className={classes.root} boxShadow={2}>
        <div className={classes.inner}>
          <small>&copy; {TEXT.COPYRIGHT}</small>
        </div>
      </Box>
    </footer>
  )
}

/**
 * Combine
 */

export const Footer: FC = () => {
  return <FooterPresenter />
}
