import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { PrimaryNoticeModal } from '../../../molecules/ModalContainer/PrimaryDialogTemplate/PrimaryNoticeDialog'

export const NoticeDownloadCSVFailed = () => {
  const { t } = useTranslation('audience')
  
  return (
    <PrimaryNoticeModal
      header={t('modals.noticeDownloadCSVFailed.title')}
      content={t('modals.noticeDownloadCSVFailed.content')}
    />
  )
}
