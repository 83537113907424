import * as React from 'react'
import Auth0, { Auth0DecodedHash } from 'auth0-js'
import get from 'lodash.get'

import { Domain } from '../features'

function currentTime(): number {
  return new Date().getTime()
}

/**
 * AuthContext API
 */
const AuthContext = React.createContext<Domain.Auth.Value>({
  domain: undefined,
  clientID: undefined,
  redirectUri: undefined,
  namespace: undefined,
  // ----
  auth0: undefined,
  accessToken: undefined,
  idToken: undefined,
  idTokenPayload: undefined,
  expiresAt: 0,
  tenantIds: undefined,
  tenants: undefined,
  sessionChecked: false,
  authenticated: false,
  // ----
  clearSession: () => {},
  setSession: (_authResult: Auth0DecodedHash) => {
    throw Promise.reject(new Error('Not initialized yet'))
  },
  handleAuthentication: (): Promise<Auth0DecodedHash> => {
    return Promise.reject(new Error('Not initialized yet'))
  },
  renewSession: (): Promise<Auth0DecodedHash> => {
    return Promise.reject(new Error('Not initialized yet'))
  },
  login: () => {},
  logout: () => {},
})

const useAuth = (givenValue: Domain.Auth.Credential): Domain.Auth.Value => {
  const { domain, clientID, redirectUri, namespace } = givenValue

  /**
   * Auth client state
   */
  const [auth0] = React.useState(
    new Auth0.WebAuth({
      domain,
      clientID,
      redirectUri,
      responseType: 'token id_token',
      scope: 'openid profile',
    }),
  )

  /**
   * Session state
   */
  const [sessionInfo, setSessionInfo] = React.useState<Domain.Auth.SessionInfo>({
    accessToken: undefined,
    idToken: undefined,
    idTokenPayload: undefined,
    expiresAt: 0,
    tenantIds: undefined,
    tenants: undefined,
    sessionChecked: false,
  })

  /**
   * This returns true if login session is there and it's valid
   */
  const authenticated: boolean = currentTime() < sessionInfo.expiresAt

  /**
   *
   */
  const clearSession = React.useCallback(() => {
    // Remove tokens and expiry time
    const sessionInfo = {
      accessToken: undefined,
      idToken: undefined,
      idTokenPayload: undefined,
      expiresAt: 0,
      tenantIds: undefined,
      tenants: undefined,
      sessionChecked: false,
    }
    setSessionInfo(sessionInfo)
  }, [setSessionInfo])

  /**
   *
   */
  const setSession = React.useCallback(
    (authResult: Auth0DecodedHash) => {
      // Set the time that the access token will expire at
      const { accessToken, idToken } = authResult
      const expiresIn = (authResult && authResult.expiresIn) || 0
      const expiresAt = expiresIn * 1000 + currentTime()
      const idTokenPayload = authResult.idTokenPayload
      const userMetadata = get(idTokenPayload, `${namespace}/user_metadata`, undefined)

      // .env に REACT_APP_DEBUG_PRINT_ID_TOKEN=true を設定すると idToken がコンソールに出力される
      // if (process.env.REACT_APP_DEBUG_PRINT_ID_TOKEN === 'true') {
      //   console.debug(`export ID_TOKEN=${idToken}`)
      // }
      // Filter tenant has any permission true
      const tenantHasPermission = userMetadata.tenants.filter((data: Domain.Auth.TenantAuth0Interface) => {
        return Object.values(data.permissions).includes(true)
      })

      // Get tenantIds from tenants
      const tenantIds: Array<string> = []
      tenantHasPermission.map((data: Domain.Auth.TenantAuth0Interface) => {
        return tenantIds.push(data.tenantId)
      })
      
      const sessionInfo = {
        accessToken,
        idToken,
        idTokenPayload,
        expiresAt,
        tenantIds: tenantIds,
        tenants: tenantHasPermission,
        sessionChecked: true,
      }
      setSessionInfo(sessionInfo)
    },
    [setSessionInfo, namespace],
  )

  /**
   *
   */
  const handleAuthentication = React.useCallback((): Promise<Auth0DecodedHash> => {
    return new Promise((resolve, reject) => {
      auth0 &&
        auth0.parseHash((error, authResult) => {
          if (authResult && authResult.accessToken && authResult.idToken) {
            setSession(authResult)
            resolve(authResult)
          } else if (error) {
            // history.replace('/home')
            console.error(`Error: ${error.error}. Check the console for further details.`)
            console.error('error:', error)
            reject(error)
          } else {
            reject(new Error('No session'))
          }
        })
    })
  }, [auth0, setSession])

  /**
   *
   */
  const renewSession = React.useCallback((): Promise<Auth0DecodedHash> => {
    return new Promise((resolve, reject) => {
      auth0 &&
        auth0.checkSession({}, (error, authResult) => {
          if (authResult && authResult.accessToken && authResult.idToken) {
            setSession(authResult)
            resolve(authResult)
          } else if (error) {
            // No session to recover, login required
            clearSession()
            // console.error(
            //   `Could not get a new token (${error.error}: ${
            //     error.error_description
            //   }).`
            // )
            // console.error('error:', error)
            reject(error)
          } else {
            clearSession()
            reject(new Error('No session'))
          }
        })
    })
  }, [auth0, setSession, clearSession])

  /**
   * Redirect a user to Auth0 to start the login procedure
   */
  const login = React.useCallback(() => {
    auth0 && auth0.authorize({prompt: 'login'})
  }, [auth0])

  /**
   * Log out from Auth0
   */
  const logout = React.useCallback(() => {

    auth0 &&
    auth0.logout({
      returnTo: window.location.origin,
    })
  }, [auth0, clearSession])

  return {
    domain,
    clientID,
    redirectUri,
    namespace,
    // ----
    auth0,
    ...sessionInfo,
    authenticated,
    // ----
    clearSession,
    setSession,
    handleAuthentication,
    renewSession,
    login,
    logout,
  }
}

/**
 * AuthProvider API
 */

export const AuthProvider: React.FC<Domain.Auth.ProviderProps> = ({ value: givenValue, children }) => {
  const value = useAuth(givenValue)

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const AuthConsumer = AuthContext.Consumer

export default AuthContext
