import React, { FC } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
//
import { Paper, Divider } from '../../atoms'

/**
 * Interface
 */

interface SectionPanelPresenterProps {
  header?: React.ReactElement | React.ReactNode
  footer?: React.ReactElement
}

interface SectionPanelProps {
  header?: React.ReactElement | React.ReactNode
  footer?: React.ReactElement
}

/**
 * Styles
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      padding: `${theme.spacing(2)}px`,
    },
    body: {
      padding: 0,
    },
    footer: {
      padding: `${theme.spacing(2)}px`,
    },
  }),
)

/**
 * Presenter
 */

export const SectionPanelPresenter: FC<SectionPanelPresenterProps> = props => {
  const { header, children, footer } = props
  const classes = useStyles({})
  return (
    <section>
      <Paper>
        {header && (
          <>
            <div className={classes.header}>{header}</div>
            <Divider />
          </>
        )}
        {children && <div className={classes.body}>{children}</div>}
        {footer && (
          <>
            <Divider />
            <div className={classes.footer}>{footer}</div>
          </>
        )}
      </Paper>
    </section>
  )
}

/**
 * Combine
 */

export const SectionPanel: FC<SectionPanelProps> = props => {
  return <SectionPanelPresenter {...props} />
}
