import React, { FC } from 'react'
//
import { CenterAlignmentTemplate } from '../../templates'
import { NotFoundErrorSection } from '../../organisms'

/**
 * Interface
 */

interface NotFoundErrorPagePresenterProps {
  actions: {
    handleDashboard: () => void
  }
}

/**
 * Presenter
 */

export const NotFoundErrorPagePresenter: FC<NotFoundErrorPagePresenterProps> = props => {
  const {
    actions: { handleDashboard },
  } = props
  return (
    <CenterAlignmentTemplate>
      <NotFoundErrorSection handleDashboard={handleDashboard} />
    </CenterAlignmentTemplate>
  )
}
