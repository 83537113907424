import * as React from 'react'

/**
 * NavigationContext API
 */

interface NavigationValue {
  sidebarDisplayCondition: boolean
  // ----
  handleSidebarDisplayCondition: (condition: boolean) => void
}

const NavigationContext = React.createContext<NavigationValue>({
  sidebarDisplayCondition: true, // FIXME: mobile の場合は falseにする
  // ----
  handleSidebarDisplayCondition: () => {},
})

const useNavigation = (): NavigationValue => {
  /**
   * Navigation state
   */
  const [sidebarDisplayCondition, setSidebarDisplayCondition] = React.useState(true)

  /**
   * Handle sidebar component display condition state
   */
  const handleSidebarDisplayCondition = React.useCallback(
    condition => {
      setSidebarDisplayCondition(condition)
    },
    [setSidebarDisplayCondition],
  )

  /**
   * Handle close Sidebar
   */

  return {
    // state
    sidebarDisplayCondition,
    // action
    handleSidebarDisplayCondition,
  }
}

/**
 * NavigationProvider API
 */

export const NavigationProvider: React.FC = props => {
  const { children } = props
  const value = useNavigation()

  return <NavigationContext.Provider value={value}>{children}</NavigationContext.Provider>
}

export const NavigationConsumer = NavigationContext.Consumer

export default NavigationContext
