// See https://material.io/design/color/the-color-system.html#tools-for-picking-colors for more about color system.

/**
 * Black / White
 */
const CXD_WHITE = '#ffffff'
const CXD_BLACK = '#000000'

/**
 * Brand
 */

const CXD_BRAND_50 = '#e2f0fc' // Alternate background
const CXD_BRAND_100 = '#b8daf9' // Base background
const CXD_BRAND_200 = '#8bc4f6'
const CXD_BRAND_300 = '#5cacf2'
const CXD_BRAND_400 = '#379bf0' // Light
const CXD_BRAND_500 = '#058bed'
const CXD_BRAND_600 = '#097ddf'
const CXD_BRAND_700 = '#096bcc'
const CXD_BRAND_800 = '#075bba' // Main
const CXD_BRAND_900 = '#033d9b' // Dark

/**
 * Gray
 */

const CXD_GRAY_1 = '#0000001f'
const CXD_GRAY_2 = '#00000042'
const CXD_GRAY_50 = '#fcfcfc' // Alternate background
const CXD_GRAY_100 = '#f7f7f7' // Base background
const CXD_GRAY_200 = '#f2f2f2'
const CXD_GRAY_300 = '#ececec'
const CXD_GRAY_400 = '#cacaca'
const CXD_GRAY_500 = '#acacac' // Disable text
const CXD_GRAY_600 = '#828282' // Placeholder text
const CXD_GRAY_700 = '#979797' // Text content
const CXD_GRAY_800 = '#4e4e4e' // Text content
const CXD_GRAY_900 = '#2c2c2c' // Text content

/**
 * Navy
 */

const CXD_NAVY_50 = '#eaecff' // Alternate background
const CXD_NAVY_100 = '#c9d4ee' // Base background
const CXD_NAVY_200 = '#adb7d5'
const CXD_NAVY_300 = '#8f9bbd'
const CXD_NAVY_400 = '#7985aa'
const CXD_NAVY_500 = '#627198' // Disable text
const CXD_NAVY_600 = '#546387' // Placeholder text
const CXD_NAVY_700 = '#435070' // Text content
const CXD_NAVY_800 = '#333e5a' // Text content
const CXD_NAVY_900 = '#202a42' // Text content

/**
 * Semantic colors
 *
 * - BLUE: Informative
 * - RED: Negative
 * - ORANGE: Notice
 * - GREEN: Positive
 */

/* BLUE */
const CXD_BLUE_50 = '#e2f0fc' // Alternate background
const CXD_BLUE_100 = '#b8daf9' // Base background
const CXD_BLUE_200 = '#8bc4f6'
const CXD_BLUE_300 = '#5cacf2'
const CXD_BLUE_400 = '#379bf0' // Light
const CXD_BLUE_500 = '#058bed'
const CXD_BLUE_600 = '#097ddf'
const CXD_BLUE_700 = '#096bcc'
const CXD_BLUE_800 = '#075bba' // Main
const CXD_BLUE_900 = '#033d9b' // Dark
const SUBMIT_BTN_BLUE = '#0D3C61'

/* RED */
const CXD_RED_50 = '#ffeaed' // Alternate background
const CXD_RED_100 = '#ffcacf' // Base background
const CXD_RED_200 = '#f09594'
const CXD_RED_300 = '#e66c6b'
const CXD_RED_400 = '#ef4946'
const CXD_RED_500 = '#f33529' // Light
const CXD_RED_600 = '#e42929'
const CXD_RED_700 = '#d21d23'
const CXD_RED_800 = '#c6131c' // Main
const CXD_RED_900 = '#b7000e' // Dark
const RED_1 = '#f44336'

/* ORANGE */
const CXD_ORANGE_50 = '#fef4e2' // Alternate background
const CXD_ORANGE_100 = '#fde2b6' // Base background
const CXD_ORANGE_200 = '#fcd087'
const CXD_ORANGE_300 = '#fbbc58'
const CXD_ORANGE_400 = '#faae38'
const CXD_ORANGE_500 = '#faa023' // Light
const CXD_ORANGE_600 = '#f59420'
const CXD_ORANGE_700 = '#ef851c'
const CXD_ORANGE_800 = '#e97619' // Main
const CXD_ORANGE_900 = '#df5f15' // Dark

/* GREEN */
const CXD_GREEN_50 = '#e1f3ef' // Alternate background
const CXD_GREEN_100 = '#b5e2d6' // Base background
const CXD_GREEN_200 = '#87d0bc'
const CXD_GREEN_300 = '#37ae8f'
const CXD_GREEN_400 = '#37ae8f'
const CXD_GREEN_500 = '#219f7d' // Light
const CXD_GREEN_600 = '#1d9171'
const CXD_GREEN_700 = '#188162'
const CXD_GREEN_800 = '#117154' // Main
const CXD_GREEN_900 = '#065539' // Dark

export {
  CXD_BRAND_50,
  CXD_BRAND_100,
  CXD_BRAND_200,
  CXD_BRAND_300,
  CXD_BRAND_400,
  CXD_BRAND_500,
  CXD_BRAND_600,
  CXD_BRAND_700,
  CXD_BRAND_800,
  CXD_BRAND_900,
  CXD_WHITE,
  CXD_BLACK,
  CXD_GRAY_1,
  CXD_GRAY_2,
  CXD_GRAY_50,
  CXD_GRAY_100,
  CXD_GRAY_200,
  CXD_GRAY_300,
  CXD_GRAY_400,
  CXD_GRAY_500,
  CXD_GRAY_600,
  CXD_GRAY_700,
  CXD_GRAY_800,
  CXD_GRAY_900,
  CXD_NAVY_50,
  CXD_NAVY_100,
  CXD_NAVY_200,
  CXD_NAVY_300,
  CXD_NAVY_400,
  CXD_NAVY_500,
  CXD_NAVY_600,
  CXD_NAVY_700,
  CXD_NAVY_800,
  CXD_NAVY_900,
  CXD_BLUE_50,
  CXD_BLUE_100,
  CXD_BLUE_200,
  CXD_BLUE_300,
  CXD_BLUE_400,
  CXD_BLUE_500,
  CXD_BLUE_600,
  CXD_BLUE_700,
  CXD_BLUE_800,
  CXD_BLUE_900,
  SUBMIT_BTN_BLUE,
  CXD_RED_50,
  CXD_RED_100,
  CXD_RED_200,
  CXD_RED_300,
  CXD_RED_400,
  CXD_RED_500,
  CXD_RED_600,
  CXD_RED_700,
  CXD_RED_800,
  CXD_RED_900,
  RED_1,
  CXD_ORANGE_50,
  CXD_ORANGE_100,
  CXD_ORANGE_200,
  CXD_ORANGE_300,
  CXD_ORANGE_400,
  CXD_ORANGE_500,
  CXD_ORANGE_600,
  CXD_ORANGE_700,
  CXD_ORANGE_800,
  CXD_ORANGE_900,
  CXD_GREEN_50,
  CXD_GREEN_100,
  CXD_GREEN_200,
  CXD_GREEN_300,
  CXD_GREEN_400,
  CXD_GREEN_500,
  CXD_GREEN_600,
  CXD_GREEN_700,
  CXD_GREEN_800,
  CXD_GREEN_900,
}
