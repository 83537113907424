import { Button } from '@material-ui/core'
import React from 'react'
import { PrimaryModalTemplate } from '../../../molecules/ModalContainer/PrimaryDialogTemplate'
import SingleModalContext from '../../../molecules/ModalContainer'
import { MODAL_CLOSE_BTN } from '../../../../constants/event'

interface PrimaryNoticeModalProps {
  header: any
  content: any
}

export const PreviewModal: React.FC<PrimaryNoticeModalProps> = props => {
  const { closeModal } = React.useContext(SingleModalContext)

  const Footer = () => {
    return (
      <Button variant="contained" color="primary" onClick={closeModal}>
        {MODAL_CLOSE_BTN}
      </Button>
    )
  }
  return <PrimaryModalTemplate header={props.header} content={props.content} footer={<Footer />} />
}
