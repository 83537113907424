import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
//
import { Domain } from '../../../features'

const ROLE_LABEL = '担当者'

/**
 * Interface
 */

export interface TenantRoleLabelPresenterProps {
  currentTenantId: string
  tenants: Domain.Auth.TenantsAuth0Interface | undefined
}

export interface TenantRoleLabelProps {
  currentTenantId: string
  tenants: Domain.Auth.TenantsAuth0Interface | undefined
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rollLabel: {
      fontSize: theme.typography.pxToRem(13),
      color: theme.palette.informative[700],
      textAlign: 'center',
      display: 'inline-block',
      padding: `${theme.spacing(0.5)}px ${theme.spacing(1.5)}px`,
      border: `solid ${theme.spacing(0.125)}px ${theme.palette.informative[700]}`,
      borderRadius: `${theme.spacing(0.5)}px`,
    },
  }),
)

/**
 * Presenter
 */

export const TenantRoleLabelPresenter = () => {
  const classes = useStyles()
  return <span className={classes.rollLabel}>{ROLE_LABEL}</span>
}

/**
 * Combine
 */

export const TenantRoleLabel = () => {
  return <TenantRoleLabelPresenter />
}
