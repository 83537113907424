import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import { hooks } from '../libs'
import { InitializePagePresenter } from '../components/pages/InitializePage'

/**
 * By using the isLoggedIn of children() of this component, we switch Routes based on the time a user has already logged in or not
 * We're going to confirm login if we can't verify session in auth: Auth like the first accessing or reloading
 *
 * - If we have not confirmed session in Auth0, we're going to confirm that
 * - If we have confirmed session in Auth0, just diplay a page
 * - If we have not confirmed session in Auth0, we're going to redirect a user to the login page
 */

type ChildrenFn = ({ isLoggedIn }: { isLoggedIn: boolean }) => React.ReactElement

interface RouteGroupSwitchProps extends RouteComponentProps {
  children: ChildrenFn
}

interface RouteGroupSwitchContainerProps {
  children: ChildrenFn
}

function RouteGroupSwitchContainer({ children }: RouteGroupSwitchContainerProps): React.ReactElement {
  const { authenticated, sessionChecked, renewSession } = hooks.useAuth()
  const [isSessionCheckRequired, setSessionCheckRequired] = React.useState<boolean>(!sessionChecked)

  // TODO: Warning: Can't perform a React state update on an unmounted component.
  React.useEffect(() => {
    if (isSessionCheckRequired) {
      renewSession()
        .then(_authResult => {
          // Login session recovered
          setSessionCheckRequired(false)
        })
        .catch(_error => {
          // No login session, login required
          setSessionCheckRequired(false)
        })
    }
  }, [renewSession, isSessionCheckRequired])

  if (isSessionCheckRequired) {
    // FIXME: display loding image
    return <InitializePagePresenter />
  }
  return children({ isLoggedIn: authenticated })
}

function RouteGroupSwitch({ children }: RouteGroupSwitchProps): React.ReactElement {
  return <RouteGroupSwitchContainer children={children} />
}

export default withRouter(RouteGroupSwitch)
