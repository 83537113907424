import * as Domain from '../domain'

type Stocks = Array<Domain.Stock.Entity>

export function createInitialState(): Stocks {
  return []
}

export const SET_STOCKS = 'stocks/set' as const

export function setStocks(stocks: Stocks) {
  return {
    type: SET_STOCKS,
    payload: {
      stocks,
    },
  }
}

type SetAction = ReturnType<typeof setStocks>

export type Action = SetAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_STOCKS:
      return action.payload.stocks
    default:
      return state
  }
}
