import { Dispatch } from 'redux'
import { forceLogout } from '../stores/auth/auth.action'

export interface Options {
  path: string
}

export function fetchApi(apiUrl: string, options: RequestInit | undefined) {
  return fetch(apiUrl, options).then((res) => {
    if (!res.ok) {
      throw res
    }
    const contentType = res.headers.get('content-type')
    if (contentType && 'application/json'.includes(contentType)) {
      return res.json()
    } else {
      return res
    }
  }).catch(async respErr => {
    if (respErr.status) {
      if (respErr.status === 401) {
        throw 'Unauthorized'
      } else {
        const error = await respErr.json()
        throw error
      }
    } else {
      if (respErr.message === 'Failed to fetch') {
        throw 'Unauthorized'
      }
      throw respErr
    }
  })
}

export async function get(
  apiName: string,
  accessToken: string | undefined,
  tenantId: string,
) {
  const apiUrl = `${process.env.REACT_APP_MANAGEMENT_API_ENDPOINT}/${apiName}`

  const headers = {
    'authorization': accessToken as string,
    'tenantid': tenantId,
  }
  return fetchApi(apiUrl, {
    method: 'GET',
    headers,
  }).then((res) => {
    return res
  })
}

export async function post(
  apiName: string,
  accessToken: string | undefined,
  tenantId: string,
  body: any,
) {
  const apiUrl = `${process.env.REACT_APP_MANAGEMENT_API_ENDPOINT}/${apiName}`

  const headers = {
    'authorization': accessToken as string,
    'tenantid': tenantId,
    'Content-Type': 'application/json'
  }
  return fetchApi(apiUrl, {
    method: 'POST',
    headers,
    body: JSON.stringify(body),
  })
  .then((res) => {
    return res
  })
}

export async function put(
  apiName: string,
  accessToken: string | undefined,
  tenantId: string,
  body: any,
) {
  const apiUrl = `${process.env.REACT_APP_MANAGEMENT_API_ENDPOINT}/${apiName}`

  const headers = {
    'authorization': accessToken as string,
    'tenantid': tenantId,
    'Content-Type': 'application/json'
  }
  return fetchApi(apiUrl, {
    method: 'PUT',
    headers,
    body: JSON.stringify(body),
  })
  .then((res) => {
    return res
  })
}

export function remove(
  apiName: string,
  accessToken: string | undefined,
  tenantId: string,
) {
  const apiUrl = `${process.env.REACT_APP_MANAGEMENT_API_ENDPOINT}/${apiName}`

  const headers = {
    'authorization': accessToken as string,
    'tenantid': tenantId,
  }
  return fetchApi(apiUrl, {
    method: 'DELETE',
    headers,
  })
  .then((res) => {
    return res
  })
}

export function postFile(
  apiName: string,
  accessToken: string | undefined,
  tenantId: string,
  body: File,
  contentType: string,
) {
  const apiUrl = `${process.env.REACT_APP_MANAGEMENT_API_ENDPOINT}/${apiName}`

  const headers = {
    'authorization': accessToken as string,
    'tenantid': tenantId,
    'Content-Type': contentType
  }
  return fetchApi(apiUrl, {
    method: 'POST',
    headers,
    body
  })
  .then((res) => {
    return res
  })
}

export function getThunk(
  apiName: string,
  accessToken: string | undefined,
  tenantId: string,
): any {
  return function (dispatch: Dispatch) {
    return get(apiName, accessToken, tenantId).then((res: any) => {
      return res
    }).catch(err => {
      if (err === 'Unauthorized') {
        dispatch(forceLogout(true))
        return
      }
      throw err
    })
  }
}

export function postThunk(
  apiName: string,
  accessToken: string | undefined,
  tenantId: string,
  body: any,
): any {
  return function (dispatch: Dispatch) {
    return post(apiName, accessToken, tenantId, body).then((res: any) => {
      return res
    }).catch(err => {
      if (err === 'Unauthorized') {
        dispatch(forceLogout(true))
        return
      }
      throw err
    })
  }
}

export function putThunk(
  apiName: string,
  accessToken: string | undefined,
  tenantId: string,
  body: any,
): any {
  return function (dispatch: Dispatch) {
    return put(apiName, accessToken, tenantId, body).then((res: any) => {
      return res
    }).catch(err => {
      if (err === 'Unauthorized') {
        dispatch(forceLogout(true))
        return
      }
      throw err
    })
  }
}

export function removeThunk(
  apiName: string,
  accessToken: string | undefined,
  tenantId: string,
): any {
  return function (dispatch: Dispatch) {
    return remove(apiName, accessToken, tenantId).then((res: any) => {
      return res
    }).catch(err => {
      if (err === 'Unauthorized') {
        dispatch(forceLogout(true))
        return
      }
      throw err
    })
  }
}

export function postFileThunk(
  apiName: string,
  accessToken: string | undefined,
  tenantId: string,
  body: File,
  contentType: string,
): any {
  return function (dispatch: Dispatch) {
    return postFile(apiName, accessToken, tenantId, body, contentType).then((res: any) => {
      return res
    }).catch(err => {
      if (err === 'Unauthorized') {
        dispatch(forceLogout(true))
        return
      }
      throw err
    })
  }
}
