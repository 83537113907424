import React from 'react'
import { useParams, useLocation } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { Box } from '@material-ui/core'
import { useAuth, useTenant } from '../../../../libs/hooks'
import { NoticeProvider } from '../../../../contexts/NoticeContext'
import { duplicateEvent, fetchEvent } from '../../../../stores/event/event.middleware'
import { resetEvent } from '../../../../stores/event/event.action'
import { clearNotice } from '../../../../stores/notice/notice.action'
import { EventCreatePagePresenter } from './EventCreatePagePresenter'

const EventCreatePageContainer = () => {
  const [isFirstLoadComplete, setIsFirstLoadComplete] = React.useState(false)
  const [originalId, setOriginalId] = React.useState('')
  const { id } = useParams<{ id: string }>()
  const storeEvent = useSelector((state: any) => state?.eventReducer?.event)
  const { tenantId } = useTenant()
  const { accessToken } = useAuth()
  const dispatch = useDispatch()
  const location = useLocation()

  React.useEffect(() => {
    return () => {
      dispatch(clearNotice())
    }
  }, [])

  React.useEffect(() => {
    dispatch(clearNotice())
    if (id) {
      dispatch(fetchEvent(id, accessToken, tenantId))
    } else if (location.state) {
      setOriginalId(location.state['event']['eventId'])
      dispatch(duplicateEvent(location.state['event']['eventId'], accessToken, tenantId))
    } else {
      dispatch(resetEvent())
    }
  }, [id, location.state])

  React.useEffect(() => {
    if (id && !storeEvent.data) {
      setIsFirstLoadComplete(false)
    } else if (!!location.state && !storeEvent.data) {
      setIsFirstLoadComplete(false)
    } else {
      setIsFirstLoadComplete(true)
    }
  }, [storeEvent])

  return (
    <Box mb={3}>
      <NoticeProvider>
        {isFirstLoadComplete && (
          <EventCreatePagePresenter id={id} accessToken={accessToken} tenantId={tenantId} originalId={originalId} />
        )}
      </NoticeProvider>
    </Box>
  )
}

export default EventCreatePageContainer
