import * as types from '../../constants/types'

export function setNotice(err: any) {
  return {
    type: types.SET_NOTICE,
    payload: err
  }
}
export function removeNotice(err: any) {
  return {
    type: types.REMOVE_NOTICE,
    payload: err
  }
}

export function clearNotice() {
  return {
    type: types.CLEAR_NOTICE
  }
}
