import * as Domain from '../domain'

type Product = Domain.Product.Entity | null

export function createInitialState(): Product {
  return null
}

export const SET_PRODUCT = 'product/set' as const

export function setProduct(product: Product) {
  return {
    type: SET_PRODUCT,
    payload: {
      product,
    },
  }
}

type SetAction = ReturnType<typeof setProduct>

export type Action = SetAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_PRODUCT:
      return action.payload.product
    default:
      return state
  }
}
