import React, { useState, useEffect } from 'react'
//
import { hooks } from '../../../libs'
import { CallbackPagePresenter } from './CallbackPagePresenter'

/**
 * Interface
 */

interface ChildrenProps {
  authenticated: boolean
  error: Error | string | undefined
}

interface CallbackPageContainerProps {
  children: (props: ChildrenProps) => React.ReactElement
}

/**
 * Container
 */

export const CallbackPageContainer: React.FC<CallbackPageContainerProps> = props => {
  const { children } = props
  const { authenticated, handleAuthentication } = hooks.useAuth()
  const [error, setError] = useState<Error | string | undefined>(undefined)

  useEffect(() => {
    // FIXME: ここに来る前に <RouteGroupSwitch> の renewSession() で認証が完了してしまっている
    // FIXME: handleAuthentication() を呼ぶと No session error になる
    // FIXME: handleAuthentication() を呼ぶと処理中に renewSession() による unmount が走って warning が出る
    if (authenticated) {
      return
    }
    handleAuthentication()
      .then(_authResult => {
        // Nothing to do
      })
      .catch(error => {
        setError(error)
      })
  }, [authenticated, handleAuthentication])

  return children({ authenticated, error })
}

export default () => {
  return (
    <CallbackPageContainer>
      {props => {
        return <CallbackPagePresenter {...props} />
      }}
    </CallbackPageContainer>
  )
}
