import { Dispatch } from 'redux'
import { getThunk, postThunk, putThunk, removeThunk } from '../../libs/api'
import { setLoading } from '../loading/loading.action'
import { setNotice } from '../notice/notice.action'
import {
  getEvent,
  getEventError,
  getEventSuccess,
  deleteEventSuccess,
  deleteEventError,
  postEventError,
  postEventSuccess,
  putEventSuccess,
  putEventError,
  postEvent,
  putEvent,
} from './event.action'
import * as EVENT from '../../constants/event'
import { EventDetail, PutEventBody } from './event.reducer'

export function fetchEvent(
  id: string | undefined,
  accessToken: string | undefined,
  tenantId: string,
  isUpdateComplete = false,
): any {
  return function (dispatch: Dispatch) {
    dispatch(setLoading(true))
    dispatch(getEvent())
    dispatch(getThunk(`events/${id}`, accessToken, tenantId))
      .then((res: any) => {
        const { eventImageFileName, eventImageDetailFileName, ...rest } = res.data
        dispatch(getEventSuccess(rest))
        if (isUpdateComplete) dispatch(putEventSuccess())
      })
      .catch((err: any) => {
        dispatch(getEventError(err))
        dispatch(setNotice(err.errorMessage || EVENT.ERR_GET_EVENT))
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

export function duplicateEvent(
  id: string,
  accessToken: string | undefined,
  tenantId: string,
): any {
  return function (dispatch: Dispatch) {
    dispatch(setLoading(true))
    dispatch(getEvent())
    dispatch(getThunk(`events/${id}`, accessToken, tenantId))
      .then((res: any) => {
        const { eventImageFileName, eventImageDetailFileName, ...rest } = res.data
        rest.eventId = ''
        rest.isReservedOpen = false
        rest.status = ''
        dispatch(getEventSuccess(rest))
        
      })
      .catch((err: any) => {
        dispatch(getEventError(err))
        dispatch(setNotice(err.errorMessage || EVENT.ERR_GET_EVENT))
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

export function createEvent(
  body: EventDetail,
  accessToken: string | undefined,
  tenantId: string,
  callback: { success: () => void; error: () => void },
): any {
  return function (dispatch: Dispatch) {
    dispatch(setLoading(true))
    dispatch(postEvent(body))
    dispatch(postThunk(`events`, accessToken, tenantId, body))
      .then(() => {
        // 成功した場合は編集画面に遷移するため、loadingはオフにしない
        dispatch(postEventSuccess())
        callback.success()
      })
      .catch((err: any) => {
        dispatch(postEventError(err))
        dispatch(setNotice(err.errorMessage || EVENT.ERR_POST_EVENT))
        callback.error()
        dispatch(setLoading(false))
      })
  }
}

export function updateEvent(
  id: string | undefined,
  accessToken: string | undefined,
  tenantId: string,
  body: PutEventBody,
  callback: { success: () => void; error: () => void },
): any {
  return function (dispatch: Dispatch) {
    dispatch(setLoading(true))
    dispatch(putEvent({ ...body, eventId: id }))
    dispatch(putThunk(`events/${id}`, accessToken, tenantId, body))
      .then(() => {
        dispatch(fetchEvent(id, accessToken, tenantId, true))
        callback.success()
      })
      .catch((err: any) => {
        dispatch(putEventError(err))
        dispatch(setNotice(err.errorMessage || EVENT.ERR_PUT_EVENT))
        callback.error()
        dispatch(setLoading(false))
      })
  }
}

export function removeEvent(
  id: string | undefined,
  accessToken: string | undefined,
  tenantId: string,
  callback: () => void,
): any {
  return function (dispatch: Dispatch) {
    dispatch(setLoading(true))
    dispatch(removeThunk(`events/${id}`, accessToken, tenantId))
      .then(() => {
        dispatch(deleteEventSuccess())
        callback()
      })
      .catch((err: any) => {
        dispatch(deleteEventError(err))
        dispatch(setNotice(err.errorMessage || EVENT.ERR_DELETE_EVENT))
        dispatch(setLoading(false))
      })
  }
}
