import React, { FC } from 'react'
//
import { DashboardPagePresenter } from './DashboardPagePresenter'

interface DashboardPageContainerProps {
  children: (props: any) => React.ReactElement
}

export const DashboardPageContainer: FC<DashboardPageContainerProps> = props => {
  const { children } = props
  return children({})
}

export default () => {
  return (
    <DashboardPageContainer>
      {props => {
        return <DashboardPagePresenter {...props} />
      }}
    </DashboardPageContainer>
  )
}
