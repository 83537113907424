import { combineReducers } from 'redux'
import { connectRouter, RouterState } from 'connected-react-router'
import { History, createLocation } from 'history'

import * as App from './app'
import * as Network from './network'
import * as Error from './error'
import * as Product from './product'
import * as Products from './products'
import * as Pagination from './pagination'
import * as Orders from './orders'
import * as Order from './order'
import * as OrderResults from './orderResults'
import * as Stocks from './stocks'
import * as Notice from './notice'
import * as Tenant from './tenant'
import * as User from './user'
import * as Users from './users'
import * as Auth from './auth'

export { App, Network, Error, Product, Products, Pagination, Orders, Order, OrderResults, Stocks, Notice, Auth }

/**
 * AppState interfaces
 */

export type AppState = {
  readonly app: ReturnType<typeof App.createInitialState>
  readonly network: ReturnType<typeof Network.createInitialState>
  readonly error: ReturnType<typeof Error.createInitialState>
  readonly product: ReturnType<typeof Product.createInitialState>
  readonly products: ReturnType<typeof Products.createInitialState>
  readonly pagination: ReturnType<typeof Pagination.createInitialState>
  readonly orders: ReturnType<typeof Orders.createInitialState>
  readonly order: ReturnType<typeof Order.createInitialState>
  readonly orderResults: ReturnType<typeof OrderResults.createInitialState>
  readonly stocks: ReturnType<typeof Stocks.createInitialState>
  readonly notice: ReturnType<typeof Notice.createInitialState>
  readonly tenant: ReturnType<typeof Tenant.createInitialState>
  readonly user: ReturnType<typeof User.createInitialState>
  readonly users: ReturnType<typeof Users.createInitialState>
  readonly auth: ReturnType<typeof Auth.createInitialState>
  readonly router: RouterState
}

/**
 * Create state
 */

export function createInitialState(): AppState {
  return {
    app: App.createInitialState(),
    network: Network.createInitialState(),
    error: Error.createInitialState(),
    product: Product.createInitialState(),
    products: Products.createInitialState(),
    pagination: Pagination.createInitialState(),
    orders: Orders.createInitialState(),
    order: Order.createInitialState(),
    orderResults: OrderResults.createInitialState(),
    stocks: Stocks.createInitialState(),
    notice: Notice.createInitialState(),
    tenant: Tenant.createInitialState(),
    user: User.createInitialState(),
    users: Users.createInitialState(),
    auth: Auth.createInitialState(),
    router: {
      location: { ...createLocation(''), query: {} },
      action: 'PUSH',
    },
  }
}

export const blacklist = [
  'app',
  'network',
  'error',
  'product',
  'products',
  'pagination',
  'orders',
  'order',
  'stocks',
  'notice',
  'tenant',
  'user',
  'users',
  'orderResults',
]

/**
 * Reducer
 */

function createAppReducer(history: History) {
  return combineReducers<AppState>({
    app: App.reducer,
    network: Network.reducer,
    error: Error.reducer,
    product: Product.reducer,
    products: Products.reducer,
    pagination: Pagination.reducer,
    orders: Orders.reducer,
    order: Order.reducer,
    orderResults: OrderResults.reducer,
    stocks: Stocks.reducer,
    notice: Notice.reducer,
    tenant: Tenant.reducer,
    user: User.reducer,
    users: Users.reducer,
    auth: Auth.reducer,
    router: connectRouter(history),
  })
}

export default createAppReducer
