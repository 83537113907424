interface AccountLiffDomainInterface {
  [key: string]: {
    [key: string]: {
      [key: string]: string
    }
  }
}

const ACCOUNT_LIFF_DOMAINS: AccountLiffDomainInterface = {
  //TokyuStore
  '0101': {
    'itg': {
      'preview': 'https://liff.line.me/1655267713-7pLEJ5XA',
      'submit': 'https://liff.line.me/1656552177-X5YaOGVV'
    },
    'test': {
      'preview': 'https://liff.line.me/1655267713-7pLEJ5XA',
      'submit': 'https://liff.line.me/1656559705-zdnk4lBR'
    },
    'test2': {
      'preview': 'https://liff.line.me/1656739300-JrmqOW8y',
      'submit': 'https://liff.line.me/1656738845-qJbRky0Z'
    },
    'stg': {
      'preview': 'https://liff.line.me/1656559407-qEVyeM5r',
      'submit': 'https://liff.line.me/1656411435-K84mMog1'
    },
    'stg2': {
      'preview': 'https://liff.line.me/1656739300-JrmqOW8y',
      'submit': 'https://liff.line.me/1656739310-aPWOG9X4'
    },
    'prd': {
      'preview': 'https://liff.line.me/1655255501-1Rx08b52',
      'submit': 'https://liff.line.me/1653991875-QO2AwX9E'
    }
  },
  //TokyuDept
  '0201': {
    'test': {
      'preview': 'https://liff.line.me/1655267747-7KpBjMM6',
      'submit': 'https://liff.line.me/1656553284-JObaZ0Mp'
    },
    'test2': {
      'preview': 'https://liff.line.me/1656739225-G4kaXBQ8',
      'submit': 'https://liff.line.me/1656738878-G9J6EjkL'
    },
    'stg': {
      'preview': 'https://liff.line.me/1656559569-1n0J4k7v',
      'submit': 'https://liff.line.me/1656559951-jyP8vg4x'
    },
    'stg2': {
      'preview': 'https://liff.line.me/1656739225-G4kaXBQ8',
      'submit': 'https://liff.line.me/1656739260-8WjnB0QB'
    },
    'prd': {
      'preview': 'https://liff.line.me/1655261046-MaO5g4bO',
      'submit': 'https://liff.line.me/1654791363-YWp45PZQ'
    }
  },
  //Foodshow
  '0202': {
    'test': {
      'preview': 'https://liff.line.me/1655267778-VwBQn1JB',
      'submit': 'https://liff.line.me/1656553380-78NMX2B6'
    },
    'test2': {
      'preview': 'https://liff.line.me/1656738927-nPdyzV0w',
      'submit': 'https://liff.line.me/1656738896-OZEdl1BG'
    },
    'stg': {
      'preview': 'https://liff.line.me/1656559541-DZOeyZXa',
      'submit': 'https://liff.line.me/1656559982-MK0N6BXg'
    },
    'stg2': {
      'preview': 'https://liff.line.me/1656738927-nPdyzV0w',
      'submit': 'https://liff.line.me/1656738948-gpGVxR5m'
    },
    'prd': {
      'preview': 'https://liff.line.me/1655261024-G3DBZydv',
      'submit': 'https://liff.line.me/1654791884-Xb8Q5LOp'
    }
  },
  //TokyuBeauty
  '0203': {
    'test': {
      'preview': 'https://liff.line.me/1655267767-Ml6v3oor',
      'submit': 'https://liff.line.me/1655261153-nXJrm7VD'
    },
    'test2': {
      'preview': 'https://liff.line.me/1656738895-vWGRgpbe',
      'submit': 'https://liff.line.me/1656738614-GW8N9Vap'
    },
    'stg': {
      'preview': 'https://liff.line.me/1656559577-MomKEeqr',
      'submit': 'https://liff.line.me/1656559968-N4e18JWQ'
    },
    'stg2': {
      'preview': 'https://liff.line.me/1656738895-vWGRgpbe',
      'submit': 'https://liff.line.me/1656738895-vWGRgpbe'
    },
    'prd': {
      'preview': 'https://liff.line.me/1655261035-Q69nYyrN',
      'submit': 'https://liff.line.me/1654791648-xy8nNkw9'
    }
  },
  //ShinQs
  '0204': {
    'test': {
      'preview': 'https://liff.line.me/1655863633-lAk24PN1',
      'submit': 'https://liff.line.me/1655839085-EPX6kQkw'
    },
    'test2': {
      'preview': 'https://liff.line.me/1656738541-lR6zqwJE',
      'submit': 'https://liff.line.me/1656738816-dA3A1yjB'
    },
    'stg': {
      'preview': 'https://liff.line.me/1655863633-lAk24PN1',
      'submit': 'https://liff.line.me/1655960776-JAyAww6m'
    },
    'stg2': {
      'preview': 'https://liff.line.me/1656738541-lR6zqwJE',
      'submit': 'https://liff.line.me/1656738784-AkzqVjeB'
    },
    'prd': {
      'preview': 'https://liff.line.me/1655962862-9lzYr2e7',
      'submit': 'https://liff.line.me/1655962551-MldkwlVG'
    }
  },
  //Etomo
  '0301': {
    'test': {
      'preview': 'https://liff.line.me/1655937147-rjJ6V7zk',
      'submit': 'https://liff.line.me/1655937073-8j359R6n'
    },
    'test2': {
      'preview': 'https://liff.line.me/1656738565-32o9ajkv',
      'submit': 'https://liff.line.me/1656738959-DOJ0Zx29'
    },
    'stg': {
      'preview': 'https://liff.line.me/1655937147-rjJ6V7zk',
      'submit': 'https://liff.line.me/1655985812-AWL1K3NP'
    },
    'stg2': {
      'preview': 'https://liff.line.me/1656738565-32o9ajkv',
      'submit': 'https://liff.line.me/1656738510-Xqme1EvZ'
    },
    'prd': {
      'preview': 'https://liff.line.me/1655978316-awNm6gk6',
      'submit': 'https://liff.line.me/1655975976-m0lyMKXr'
    }
  },
  //TPS
  '0401': {
    'itg': {
      'preview': 'https://liff.line.me/1656251842-lxa04xBV',
      'submit': 'https://liff.line.me/1656099586-zJoyvM5Q'
    },
    'test': {
      'preview': 'https://liff.line.me/1656251842-lxa04xBV',
      'submit': 'https://liff.line.me/1656099586-zJoyvM5Q'
    },
    'test2': {
      'preview': '',
      'submit': ''
    },
    'stg': {
      'preview': 'https://liff.line.me/1656251842-lxa04xBV',
      'submit': 'https://liff.line.me/1656100308-4DAvq7g1'
    },
    'stg2': {
      'preview': '',
      'submit': ''
    },
    'prd': {
      'preview': 'https://liff.line.me/1656346706-O7Doaq2q',
      'submit': 'https://liff.line.me/1656214370-wJgqBv7p'
    }
  },
  //Futako
  '0501': {
    'itg': {
      'preview': 'https://liff.line.me/1656267222-80pokzrO',
      'submit': 'https://liff.line.me/1656549422-0VoVdy1w'
    },
    'test': {
      'preview': 'https://liff.line.me/1656267222-80pokzrO',
      'submit': 'https://liff.line.me/1656559905-3wREyDKk'
    },
    'test2': {
      'preview': 'https://liff.line.me/1656738818-D7Z7eJbG',
      'submit': 'https://liff.line.me/1656739018-RXPG2j8L'
    },
    'stg': {
      'preview': 'https://liff.line.me/1656556184-QGW0P1oo',
      'submit': 'https://liff.line.me/1656560042-MBz5vxbP'
    },
    'stg2': {
      'preview': 'https://liff.line.me/1656738818-D7Z7eJbG',
      'submit': 'https://liff.line.me/1656738577-YOrOVgEB'
    },
    'prd': {
      'preview': 'https://liff.line.me/1656267247-O2dg24qj',
      'submit': 'https://liff.line.me/1656327990-M4K4pQo7'
    }
  },
  //TamaPlus
  '0302': {
    'test': {
      'preview': 'https://liff.line.me/1656860414-wk0MM1en',
      'submit': 'https://liff.line.me/1656962077-l4rgA6Eb'
    },
    'test2': {
      'preview': 'https://liff.line.me/1656852718-p3YrrwNA',
      'submit': 'https://liff.line.me/1656845757-Rk5QQE8P'
    },
    'stg': {
      'preview': 'https://liff.line.me/1656860414-wk0MM1en',
      'submit': 'https://liff.line.me/1656845716-OJ5ddE3V'
    },
    'stg2': {
      'preview': 'https://liff.line.me/1656852718-p3YrrwNA',
      'submit': 'https://liff.line.me/1656845777-9xR22A6L'
    },
    'prd': {
      'preview': 'https://liff.line.me/1656860615-gJOAA9Kp',
      'submit': 'https://liff.line.me/1656824842-Pzxaol3a'
    }
  }
}

export const generalAccountLiffDomain = (tenantId: string, action: string) => {
  return ACCOUNT_LIFF_DOMAINS[tenantId][process.env.REACT_APP_ENVIRONMENT as string][action]
}
