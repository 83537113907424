import { combineReducers } from 'redux'
import * as types from '../../constants/types'

export type EventDetail = {
  eventId: string
  tenantId?: string
  eventImage?: string
  eventImageDetail?: string
  headLineText?: string
  eventDescription?: string
  commentInfos?: []
  isDisplayUploadImage?: boolean
  imageText?: string
  isDisableIndex?: boolean
  isDisableDuration?: boolean
  checkRegisterCard?: boolean
  startDate?: string
  endDate?: string
  displayEndDate?: string
  eventVersion?: number
  createdAt?: string
  updatedAt?: string
  createdBy?: string
  updatedBy?: string
  isReservedOpen?: boolean
}

export type PostEventBody = {
  eventId: string
  eventImage?: string
  eventImageFileName?: string
  eventImageDetail?: string
  eventImageDetailFileName?: string
  headLineText?: string
  eventDescription?: string
  commentInfos?: []
  isDisplayUploadImage?: boolean
  imageText?: string
  isDisableIndex?: boolean
  isDisableDuration?: boolean
  checkRegisterCard?: boolean
  startDate?: string
  endDate?: string
  displayEndDate?: string
  eventVersion?: number
  isReservedOpen?: boolean
}

export type PutEventBody = {
  eventImage?: string
  eventImageDetail?: string
  headLineText?: string
  eventDescription?: string
  commentInfos?: []
  isDisplayUploadImage?: boolean
  imageText?: string
  isDisableIndex?: boolean
  isDisableDuration?: boolean
  checkRegisterCard?: boolean
  startDate?: string
  endDate?: string
  displayEndDate?: string
  eventVersion?: number
  isReservedOpen?: boolean
}

const initialState: any = {
  updateComplete: 0,
  error: undefined,
  data: undefined,
}

export function event(state = initialState, action: { type: string; payload: any }) {
  switch (action.type) {
    case types.GET_EVENT:
      return {
        ...state,
      }
    case types.GET_EVENT_SUCCESS:
      return {
        ...state,
        error: null,
        data: action.payload,
      }
    case types.GET_EVENT_ERROR:
      return {
        ...state,
        data: null,
        error: action.payload,
      }
    case types.POST_EVENT:
      return {
        ...state,
      }
    case types.POST_EVENT_SUCCESS:
      return {
        ...state,
        error: null,
      }
    case types.POST_EVENT_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case types.PUT_EVENT:
      return {
        ...state,
      }
    case types.PUT_EVENT_SUCCESS:
      return {
        ...state,
        error: null,
        updateComplete: state.updateComplete + 1
      }
    case types.PUT_EVENT_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case types.RESET_EVENT:
      return {
        ...initialState,
      }
    case types.DELETE_EVENT:
      return {
        ...state,
      }
    case types.DELETE_EVENT_SUCCESS:
      return {
        ...state,
        error: null,
        data: null,
      }
    case types.DELETE_EVENT_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

const eventReducer = combineReducers({
  event,
})

export default eventReducer
