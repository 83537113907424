import React, { FC } from 'react'
import { makeStyles } from '@material-ui/core/styles'

/**
 * Interface
 */

interface CenterAlignmentTemplateProps {
  children: React.ReactNode
}

/**
 * Style
 */

const useStyles = makeStyles({
  root: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
})

/**
 * Presenter
 */

export const CenterAlignmentTemplate: FC<CenterAlignmentTemplateProps> = ({ children }) => {
  const classes = useStyles({})
  return <div className={classes.root}>{children}</div>
}
