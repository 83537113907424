import React, { FC } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
//
import { Domain } from '../../../features'
import { date } from '../../../libs'
import { SectionPanel } from '../../molecules'
import { Typography, Table, TableBody, TableRow, TableCell } from '../../atoms'

/**
 * Interface
 */

export type StateProps = {
  tenant: Domain.Tenant.Entity
}

export interface AppDetailSectionPresenterProps {
  state: StateProps
}

export interface AppDetailSectionProps {
  state: StateProps
}

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(2),
    },
    content: {
      padding: theme.spacing(0),
      '&:last-child': {
        paddingBottom: theme.spacing(0),
      },
    },
    tableCellHead: {
      width: 160,
    },
    tableCellHeadTop: {
      width: 160,
      verticalAlign: 'top',
    },
    tableBody: {
      '& tr:last-child > td': {
        borderBottomStyle: 'none',
      },
    },
    tableInnerBody: {
      border: `1px solid ${theme.palette.grey[400]}`,
    },
    tableCellInnerHead: {
      width: 140,
    },
    tableCell: {
      wordBreak: 'break-all',
    },
  }),
)

/**
 * Presenter
 */

export const AppDetailSectionPresenter: FC<AppDetailSectionPresenterProps> = props => {
  const {
    state: {
      tenant,
    },
  } = props

  const classes = useStyles({})

  return (
    <>
      {tenant.apps.map(app => 
        <SectionPanel
          key={app.id}
          header={<Typography variant="h4">{app.versions[0].version} - {app.name}</Typography>}
        >
          <Table cellSpacing={0}>
            <TableBody className={classes.tableBody}>
              <TableRow>
                <TableCell className={classes.tableCellHead} align="left">
                  <Typography variant="caption">ID</Typography>
                </TableCell>
                <TableCell className={classes.tableCell} align="left">{app.id}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tableCellHead} align="left">
                  <Typography variant="caption">アプリコード</Typography>
                </TableCell>
                <TableCell className={classes.tableCell} align="left">{app.code}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tableCellHead} align="left">
                  <Typography variant="caption">アプリ名称</Typography>
                </TableCell>
                <TableCell className={classes.tableCell} align="left">{app.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tableCellHead} align="left">
                  <Typography variant="caption">LIFF ID</Typography>
                </TableCell>
                <TableCell className={classes.tableCell} align="left">{app.liffId}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tableCellHead} align="left">
                  <Typography variant="caption">LINE Channel Id</Typography>
                </TableCell>
                <TableCell className={classes.tableCell} align="left">{app.lineChannelId}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tableCellHead} align="left">
                  <Typography variant="caption">LINE Channel Secret</Typography>
                </TableCell>
                <TableCell className={classes.tableCell} align="left">{app.lineChannelSecret}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tableCellHeadTop} align="left">
                  <Typography variant="caption">Line Channel Access Token Cache</Typography>
                </TableCell>
                <TableCell align="left">
                  <Table cellSpacing={0}>
                    <TableBody className={classes.tableInnerBody}>
                      <TableRow>
                        <TableCell className={classes.tableCellInnerHead} align="left">
                          <Typography variant="caption">Access Token</Typography>
                        </TableCell>
                        <TableCell className={classes.tableCell} align="left">{app.lineChannelAccessTokenCache ? app.lineChannelAccessTokenCache.accessToken : '-'}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.tableCellInnerHead} align="left">
                          <Typography variant="caption">Expires At</Typography>
                        </TableCell>
                        <TableCell className={classes.tableCell} align="left">{app.lineChannelAccessTokenCache ? app.lineChannelAccessTokenCache.expiresAt : '-'}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tableCellHead} align="left">
                  <Typography variant="caption">Messaging API Channel Access Token</Typography>
                </TableCell>
                <TableCell className={classes.tableCell} align="left">{app.messagingApiChannelAccessToken}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tableCellHeadTop} align="left">
                  <Typography variant="caption">バージョン</Typography>
                </TableCell>
                <TableCell align="left">
                  <Table cellSpacing={0}>
                    <TableBody className={classes.tableInnerBody}>
                      <TableRow>
                        <TableCell className={classes.tableCellInnerHead} align="left">
                          <Typography variant="caption">ID</Typography>
                        </TableCell>
                        <TableCell className={classes.tableCell} align="left">{app.versions[0].id}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.tableCellInnerHead} align="left">
                          <Typography variant="caption">App ID</Typography>
                        </TableCell>
                        <TableCell className={classes.tableCell} align="left">{app.versions[0].appId}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.tableCellInnerHead} align="left">
                          <Typography variant="caption">モジュール名</Typography>
                        </TableCell>
                        <TableCell className={classes.tableCell} align="left">{app.versions[0].moduleName}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.tableCellInnerHead} align="left">
                          <Typography variant="caption">バージョン</Typography>
                        </TableCell>
                        <TableCell className={classes.tableCell} align="left">{app.versions[0].version}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.tableCellInnerHead} align="left">
                          <Typography variant="caption">作成日時</Typography>
                        </TableCell>
                        <TableCell className={classes.tableCell} align="left">{date.formatDate(app.versions[0].createdAt)}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.tableCellInnerHead} align="left">
                          <Typography variant="caption">作成日時</Typography>
                        </TableCell>
                        <TableCell  className={classes.tableCell}align="left">{date.formatDate(app.versions[0].updatedAt)}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tableCellHead} align="left">
                  <Typography variant="caption">作成日時</Typography>
                </TableCell>
                <TableCell className={classes.tableCell} align="left">{date.formatDate(app.createdAt)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tableCellHead} align="left">
                  <Typography variant="caption">更新日時</Typography>
                </TableCell>
                <TableCell className={classes.tableCell} align="left">{date.formatDate(app.updatedAt)}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </SectionPanel>
      )}
    </>
  )
}

/**
 * Combine
 */

export const AppDetailSection: FC<AppDetailSectionProps> = props => {
  return <AppDetailSectionPresenter {...props} />
}
