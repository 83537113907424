import * as types from '../../constants/types'

export function getEvent() {
  return {
    type: types.GET_EVENT,
  }
}

export function getEventSuccess(res: any) {
  return {
    type: types.GET_EVENT_SUCCESS,
    payload: res,
  }
}

export function getEventError(res: any) {
  return {
    type: types.GET_EVENT_ERROR,
    payload: res,
  }
}

export function resetEvent() {
  return {
    type: types.RESET_EVENT,
  }
}

export function deleteEvent() {
  return {
    type: types.DELETE_EVENT,
  }
}

export function deleteEventSuccess() {
  return {
    type: types.DELETE_EVENT_SUCCESS,
  }
}

export function deleteEventError(res: any) {
  return {
    type: types.DELETE_EVENT_ERROR,
    payload: res,
  }
}


export function postEvent(newEvent: any) {
  return {
    type: types.POST_EVENT,
    payload: newEvent,
  }
}

export function postEventSuccess() {
  return {
    type: types.POST_EVENT_SUCCESS,
  }
}

export function postEventError(res: any) {
  return {
    type: types.POST_EVENT_ERROR,
    payload: res,
  }
}

export function putEvent(newEvent: any) {
  return {
    type: types.PUT_EVENT,
    payload: newEvent,
  }
}

export function putEventSuccess() {
  return {
    type: types.PUT_EVENT_SUCCESS,
  }
}

export function putEventError(res: any) {
  return {
    type: types.PUT_EVENT_ERROR,
    payload: res,
  }
}
