import React, { FC } from 'react'
//
import { Alert, AlertTitle } from '../../atoms'
import { Warning } from '@material-ui/icons'

/**
 * Interface
 */

type AlertMessageType = 'error' | 'info' | 'success' | 'warning'

export interface AlertMessagePresenterProps {
  type: AlertMessageType
  title: string
}

export interface AlertMessageProps {
  type: AlertMessageType
  title: string
}

/**
 * Styles
 */

/**
 * Presenter
 */

export const AlertMessagePresenter: FC<AlertMessagePresenterProps> = props => {
  const { type, title, children } = props
  const iconType = {
    // success: <Icon iconName="check-circle" fontSize={'small'} />,
    // warning: <Icon iconName="exclamation-triangle" fontSize={'small'} />,
    error: <Warning />,
    // info: <Icon iconName="info-circle" fontSize={'small'} />,
  }
  return (
    <Alert color={type} severity={type} iconMapping={iconType}>
      <AlertTitle>
        {title}
      </AlertTitle>
      {children}
    </Alert>
  )
}

/**
 * Combine component
 */

export const AlertMessage: FC<AlertMessageProps> = props => {
  return <AlertMessagePresenter {...props} />
}
