const RICH_MENU_TITLE = 'リッチメニュー'
const PREVIEW_MSG = '{value0} に 以下の内容を 保存 しました。'
const SCHEDULING_MSG = '{value0} に 以下の内容を 予約 しました。{value1} に 公開予定 です。'
const CANCELLED_MSG = '{value0} に 以下の内容が 予約取消 されました。'
const ERROR_MSG = '{value0} に リッチメニュー更新処理でエラーが発生しました。'
const PUBLISHED_MSG = '{value0} に 以下の内容で 公開 しました。予約の登録が可能です。'

const ERR_TITLE = ' 入力した内容を確認してください'
const SUBMIT_VALIDATE_MSG = '未入力の項目があるため公開予約できません。入力内容を確認してください。'
const PREVIEW_VALIDATE_MSG = '未入力の項目があるためプレビューできません。入力内容を確認してください。'
const VALIDATE_ERR_NOTI = '入力内容に不備があります。入力内容を確認してください。'
const UPLOAD_VALIDATE_MSG = 'アップロードされた画像が条件に合っていません。画像のサイズや形式を確認してください。'
const INVALID_IMAGE_MSG = 'アップロードされた画像が条件に合っていません。画像のサイズや形式を確認してください。'
const INVALID_URL_MSG = 'URLの書式が正しくありません。'
const REQUIRED_URL_MSG = '遷移先URLを入力してください。'
const INVALID_QA_MSG = 'GAトラッキングIDの値が正しくありません。'
const REQUIRED_GA_MSG = 'GAトラッキングIDを入力してください。'
const INVALID_DATE_MSG = '予約日時には現在より5分後以降の日時を指定してください。'
const UPLOAD_MATERIAL_FAILED_MSG = 'ファイルアップロードに失敗しました。'
const UPLOAD_IMG_FAILED_MSG = 'リッチメニュー画像アップロードに失敗しました。'
const SUBMIT_FAILED_MSG = '公開予約処理でエラーが発生しました。'
const PREVIEW_FAILED_MSG = 'プレビュー用リッチメニュー更新処理でエラーが発生しました。'
const CANCEL_FAILED_MSG = '予約取消処理でエラーが発生しました。'
const INVALID_MAERIAL_MSG = 'ファイルの形式が正しくありません。'
const REQUIRED_SUBMENU_MSG = 'サブメニューが選択されていません。'
const NONE_SUBMENU_MSG = '設定可能なサブメニューがありません。'
const REQUIRED_TEXT_MSG = 'テキストが入力されていません。'
const MAX_TEXT_MSG = 'テキストは300文字以内で入力してください。'

const MODAL_SUBMIT_CONFIRM_TITLE = '予約日時の確認'
const MODAL_SUBMIT_NOTI_TITLE = '予約完了'
const MODAL_SUBMIT_CONFIRM_BTN = '予約する'
const MODAL_SUBMIT_NOTI_CONTENT = '{value0} 分に予約しました'
const MODAL_SUBMIT_CONFIRM_CONTENT = '{value0} 分に予約しますか？'
const MODAL_PREVIEW_TITLE = '保存とプレビュー'
const MODAL_PREVIEW_CONTENT = '現在の状態が保存され、PRV用アカウントで確認ができるようになりました'
const MODAL_CANCEL_TITLE = '予約取消の確認'
const MODAL_CANCEL_CONTENT = '現在の予約を取り消しますか？'
const MODAL_COFIRM_DISAGREE_BTN = 'キャンセル'
const MODAL_NOTI_AGREE_BTN = '確認した'
const MODAL_CANCEL_FAILED_TITLE = '予約取消エラー'
const MODAL_CANCEL_FAILED_CONTENT = '既に予約日時を過ぎているため予約取消できません。'
const CANCEL_BTN_TITLE = '予約取消'

const NOT_SELECT_IMAGE_TITLE = 'イメージが選択されていません'
const NOT_SELECT_MATERIAL_TITLE = 'ファイルが添付されていません'

interface SelectListInterFace {
  [key: string]: Array<string>
}

const APP_SCREEN_LIST: SelectListInterFace = {
  default: [
    '/select-card',
    '/registered-store-list',
    '/events'
  ],
  regist: [
    '/registered-card',
    '/registered-store-list',
    '/events'
  ]
}

const APP_GA_LIST: SelectListInterFace = {
  default: [
    'カード登録_D',
    'キャンペーン一覧_D',
    'チラシ一覧_D',
    'ネットショッピング_D',
    '公式HP_D',
    '店舗一覧_D'
  ],
  regist: [
    'インスタグラム_R',
    'キャンペーン一覧_R',
    'チラシ一覧_R',
    'ネットショッピング_R',
    'ブランドリスト_R',
    '公式HP_R',
    '店舗一覧_R',
    '電話注文_R',
    '登録情報確認_R',
  ]
}
interface RichmenuNameInterFace {
  [key: string]: string
}
const RICH_MENU_NAME: RichmenuNameInterFace = {
  default: 'カード未連携',
  regist: 'カード連携済'
}

export {
  RICH_MENU_TITLE,
  APP_SCREEN_LIST,
  APP_GA_LIST,
  PREVIEW_MSG,
  SCHEDULING_MSG,
  CANCELLED_MSG,
  ERROR_MSG,
  PUBLISHED_MSG,
  ERR_TITLE,
  SUBMIT_VALIDATE_MSG,
  PREVIEW_VALIDATE_MSG,
  UPLOAD_VALIDATE_MSG,
  INVALID_IMAGE_MSG,
  INVALID_DATE_MSG,
  INVALID_URL_MSG,
  REQUIRED_URL_MSG,
  INVALID_QA_MSG,
  REQUIRED_GA_MSG,
  UPLOAD_MATERIAL_FAILED_MSG,
  UPLOAD_IMG_FAILED_MSG,
  SUBMIT_FAILED_MSG,
  PREVIEW_FAILED_MSG,
  CANCEL_FAILED_MSG,
  VALIDATE_ERR_NOTI,
  RICH_MENU_NAME,
  INVALID_MAERIAL_MSG,
  REQUIRED_SUBMENU_MSG,
  NONE_SUBMENU_MSG,
  REQUIRED_TEXT_MSG,
  MAX_TEXT_MSG,
  NOT_SELECT_IMAGE_TITLE,
  NOT_SELECT_MATERIAL_TITLE,
  MODAL_SUBMIT_CONFIRM_TITLE,
  MODAL_SUBMIT_NOTI_TITLE,
  MODAL_SUBMIT_CONFIRM_BTN,
  MODAL_SUBMIT_NOTI_CONTENT,
  MODAL_SUBMIT_CONFIRM_CONTENT,
  MODAL_PREVIEW_TITLE,
  MODAL_PREVIEW_CONTENT,
  MODAL_CANCEL_TITLE,
  MODAL_CANCEL_CONTENT,
  MODAL_COFIRM_DISAGREE_BTN,
  MODAL_NOTI_AGREE_BTN,
  MODAL_CANCEL_FAILED_TITLE,
  MODAL_CANCEL_FAILED_CONTENT,
  CANCEL_BTN_TITLE
}
