import React, { FC, createRef, useState, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
//
import { Typography, Button, Menu, MenuItem } from '../../atoms'
const ACCOUNT_TITLE = 'アカウント'
/**
 * Interface
 */

export interface TenantMenuProps {
  tenantIds: string[] | undefined
}

export interface TenantMenuContainerProps {
  state: {
    tenantIds: string[] | undefined
  }
  children: (props: TenantMenuPresenterProps) => React.ReactElement
}

export interface TenantMenuPresenterProps {
  state: {
    tenantIds: string[] | undefined
    menuCondition: boolean
  }
  actions: {
    handleMenuOpen: (event: React.MouseEvent<HTMLButtonElement>) => void
    handleMenuClose: (event: React.MouseEvent<HTMLButtonElement>) => void
  }
}

/**
 * Styles
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menu: {
      '& .MuiMenu-paper': {
        border: '1px solid #eee',
        boxShadow: theme.shadows[3],
        '& .MuiMenu-list': {
          minWidth: '160px',
        },
      },
    },
  }),
)

/**
 * Container
 */

export const TenantMenuContainer: FC<TenantMenuContainerProps> = props => {
  const {
    state: { tenantIds },
    children,
  } = props

  const [menuCondition, setMenuCondition] = useState<boolean>(false)
  const handleMenuOpen = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation()
      setMenuCondition(true)
    },
    [setMenuCondition],
  )

  const handleMenuClose = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation()
      setMenuCondition(false)
    },
    [setMenuCondition],
  )
  return children({
    state: {
      tenantIds,
      menuCondition,
    },
    actions: { handleMenuOpen, handleMenuClose },
  })
}

/**
 * Presenter
 */

export const TenantMenuPresenter: FC<TenantMenuPresenterProps> = props => {
  const {
    state: { tenantIds, menuCondition },
    actions: { handleMenuOpen, handleMenuClose },
  } = props
  const classes = useStyles({})
  const [ref] = useState(createRef<HTMLButtonElement>())

  return tenantIds && tenantIds.length > 0 ? (
    <>
      <Button aria-controls="tenantMenu" aria-haspopup="true" ref={ref} onClick={handleMenuOpen}>
        { ACCOUNT_TITLE }
      </Button>
      {ref.current && (
        <Menu
          className={classes.menu}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          elevation={0}
          getContentAnchorEl={null}
          anchorEl={ref.current}
          open={menuCondition}
          onClose={handleMenuClose}
        >
          {tenantIds.map((tenantId: string, index: number) => (
            <MenuItem key={index} component={Link} to={`/${tenantId}/richmenu`}>
              <Typography variant="subtitle2">{tenantId}</Typography>
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  ) : null
}

/**
 * Combine
 */

export const TenantMenu: FC<TenantMenuProps> = props => {
  const { tenantIds } = props
  return (
    <TenantMenuContainer state={{ tenantIds }} {...props}>
      {props => {
        return <TenantMenuPresenter {...props} />
      }}
    </TenantMenuContainer>
  )
}
