import * as React from 'react'
import { Route } from 'react-router-dom'
import { EventListPage } from './EventListPage'
import EventCreatePage from './EventCreatePage'
import CacheRoute from 'react-router-cache-route'

export const EventPage = () => {
  return (
    <>
      <CacheRoute
        exact
        path={`/:tenantId/events/edit/:id`}
        cacheKey="EventCreatePage" 
        component={EventCreatePage}
      />
      <Route
        exact
        path={`/:tenantId/events/create`}
        component={EventCreatePage}
      />
      <Route
        exact
        path={`/:tenantId/events`}
        component={EventListPage}
      />
    </>
  )
}
