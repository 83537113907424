import * as React from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core';

export interface ObjectData {
  [name: string]: any
}

export interface DataObjectTableProps {
  data: ObjectData,
  labels: ObjectData
}

const useStyles = makeStyles({
  th: {
    backgroundColor: '#ecf4fd',
    width: '244px',
    whiteSpace: 'pre-wrap'
  },
  tr: {
    whiteSpace: 'pre-wrap'
  }
});

export const DataObjectTable: React.FC<DataObjectTableProps> = (props) => {
  const { data, labels } = props
  const classes = useStyles() 

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          {
            data && labels && Object.keys(labels).map((field: any, index: number) => (
              <TableRow key={`t-${index}`}>
                <TableCell align="right" className={classes.th} component="th" scope="row">
                  {labels[field]}
                </TableCell>
                <TableCell className={classes.tr}>{data[field]}</TableCell>
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
    </TableContainer>
  )
}
