import React, { FC } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
//
import { Domain } from '../../../features'
import { date } from '../../../libs'
import { SectionPanel } from '../../molecules'
import { Typography, Table, TableBody, TableRow, TableCell } from '../../atoms'

/**
 * Interface
 */

export type StateProps = {
  tenant: Domain.Tenant.Entity
}

export interface TenantDetailSectionPresenterProps {
  state: StateProps
}

export interface TenantDetailSectionProps {
  state: StateProps
}

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(2),
    },
    content: {
      padding: theme.spacing(0),
      '&:last-child': {
        paddingBottom: theme.spacing(0),
      },
    },
    tableCellHead: {
      width: 160,
    },
    tableCellHeadTop: {
      width: 160,
      verticalAlign: 'top',
    },
    tableBody: {
      '& tr:last-child > td': {
        borderBottomStyle: 'none',
      },
    },
    tableInnerBody: {
      border: `1px solid ${theme.palette.grey[400]}`,
    },
    tableCellInnerHead: {
      width: 140,
    },
  }),
)

/**
 * Presenter
 */

export const TenantDetailSectionPresenter: FC<TenantDetailSectionPresenterProps> = props => {
  const {
    state: {
      tenant,
    },
  } = props

  const classes = useStyles({})

  return (
    <SectionPanel>
      <Table cellSpacing={0}>
        <TableBody className={classes.tableBody}>
          <TableRow>
            <TableCell className={classes.tableCellHead} align="left">
              <Typography variant="caption">ID</Typography>
            </TableCell>
            <TableCell align="left">{tenant.id}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.tableCellHead} align="left">
              <Typography variant="caption">名前</Typography>
            </TableCell>
            <TableCell align="left">{tenant.name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.tableCellHeadTop} align="left">
              <Typography variant="caption">決済設定情報</Typography>
            </TableCell>
            <TableCell align="left">
              <Table cellSpacing={0}>
                <TableBody className={classes.tableInnerBody}>
                  <TableRow>
                    <TableCell className={classes.tableCellInnerHead} align="left">
                      <Typography variant="caption">channelId</Typography>
                    </TableCell>
                    <TableCell align="left">{tenant.paymentServiceConfig.channelId}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableCellInnerHead} align="left">
                      <Typography variant="caption">channelSecretKey</Typography>
                    </TableCell>
                    <TableCell align="left">{tenant.paymentServiceConfig.channelSecretKey}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableCellInnerHead} align="left">
                      <Typography variant="caption">endpoint</Typography>
                    </TableCell>
                    <TableCell align="left">{tenant.paymentServiceConfig.endpoint}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableCellInnerHead} align="left">
                      <Typography variant="caption">confirmUrl</Typography>
                    </TableCell>
                    <TableCell align="left">{tenant.paymentServiceConfig.confirmUrl}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.tableCellInnerHead} align="left">
                      <Typography variant="caption">cancelUrl</Typography>
                    </TableCell>
                    <TableCell align="left">{tenant.paymentServiceConfig.cancelUrl}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.tableCellHead} align="left">
              <Typography variant="caption">作成日時</Typography>
            </TableCell>
            <TableCell align="left">{date.formatDate(tenant.createdAt)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.tableCellHead} align="left">
              <Typography variant="caption">更新日時</Typography>
            </TableCell>
            <TableCell align="left">{date.formatDate(tenant.updatedAt)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </SectionPanel>
  )
}

/**
 * Combine
 */

export const TenantDetailSection: FC<TenantDetailSectionProps> = props => {
  return <TenantDetailSectionPresenter {...props} />
}
