import { Button } from '@material-ui/core'
import React, { FC } from 'react'
import SingleModalContext from '../..'
import { PrimaryModalTemplate } from '..'
import { MODAL_COFIRM_DISAGREE_BTN } from '../../../../../constants/richmenu'

interface PrimaryConfirmModalProps {
  onSubmit: any
  label: string
  header: any
  content: any
  skipButton: boolean
}

export const PrimaryConfirmModal: React.FC<PrimaryConfirmModalProps> = props => {
  const { closeModal } = React.useContext(SingleModalContext)

  const onSubmit = () => {
    props.onSubmit()
  }
  
  const Footer = () => {
    return (
      <>
      {
        props.skipButton && <Button 
          variant="outlined"
          onClick={closeModal}
        >
          { MODAL_COFIRM_DISAGREE_BTN }
        </Button>
      }
      <Button
        variant="contained" 
        color="primary"
        onClick={onSubmit}
      >
        { props.label }
      </Button>
      </>
    )
  }

  return (
    <PrimaryModalTemplate
      header={props.header}
      content={props.content}
      footer={<Footer/>}
    />
  )
}
