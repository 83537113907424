type State = Array<string>

export function createInitialState(): State {
  return []
}

export const SET_ERROR = 'error/set' as const
export const REMOVE_ERROR = 'error/remove' as const
export const CLEAR_ERROR = 'error/clear' as const

export function setError(error: string) {
  return {
    type: SET_ERROR,
    payload: {
      error,
    },
  }
}

export function removeError(error: string) {
  return {
    type: REMOVE_ERROR,
    payload: {
      error,
    },
  }
}
export function clearError() {
  return {
    type: CLEAR_ERROR,
  }
}

type SetErrorAction = ReturnType<typeof setError>
type RemoveErrorAction = ReturnType<typeof removeError>
type ClearErrorAction = ReturnType<typeof clearError>
export type Action = SetErrorAction | RemoveErrorAction | ClearErrorAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_ERROR:
      const found = state.find(er => er === action.payload.error)
      if (!found) {
        state.push(action.payload.error)
      }
      return [...state]
    case REMOVE_ERROR:
      const temp = state.filter((err) => err !== action.payload.error)
      return temp
    case CLEAR_ERROR:
      return []
    default:
      return state
  }
}
