import { createTheme } from '@material-ui/core/styles'

import paletteOption from './palette'
import typographyOption from './typography'
import breakpointsOption from './breakpoints'
import shadowsOption from './shadows'
import resetOption from './reset'

export const cxd = createTheme({
  breakpoints: Object.assign(breakpointsOption),
  typography: Object.assign(typographyOption),
  palette: Object.assign(paletteOption),
  shadows: Object.assign(shadowsOption),
  overrides: {
    MuiCssBaseline: Object.assign(resetOption),
  }
})
