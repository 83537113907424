import React, { FC } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

/**
 * Interface
 */

export type PageHeaderProps = {
  children: React.ReactElement | React.ReactNode
}

export type PageHeaderPresenterProps = PageHeaderProps

/**
 * Style
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  }),
)

/**
 * Presenter
 */

export const PageHeaderPresenter: FC<PageHeaderPresenterProps> = props => {
  const { children } = props
  const classes = useStyles({})
  return <div className={classes.root}>{children}</div>
}

/**
 * Combine
 */

export const PageHeader: FC<PageHeaderPresenterProps> = props => {
  return <PageHeaderPresenter {...props} />
}
