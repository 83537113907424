const apiGotSuccess = (type: string) => {
  return type + '_SUCCESS'
}
const apiGotError = (type: string) => {
  return type + '_ERROR'
}
export const GET_RICHMENU = 'GET_RICHMENU'
export const GET_RICHMENU_SUCCESS = apiGotSuccess(GET_RICHMENU)
export const GET_RICHMENU_ERROR = apiGotError(GET_RICHMENU)
export const GET_SUBMENU = 'GET_SUBMENU'
export const GET_SUBMENU_SUCCESS = apiGotSuccess(GET_SUBMENU)
export const GET_SUBMENU_ERROR = apiGotError(GET_SUBMENU)

export const SET_RICHMENU = 'SET_RICHMENU'
export const RESET_RICHMENU = 'RESET_RICHMENU'

export const SET_RICHMENU_ERROR = apiGotError(SET_RICHMENU)
export const REMOVE_RICHMENU_ERROR = 'REMOVE_RICHMENU_ERROR'

export const GET_STORES = 'GET_STORES'
export const GET_STORES_SUCCESS = apiGotSuccess(GET_STORES)
export const GET_STORES_ERROR = apiGotError(GET_STORES)

export const SET_NEW_AUDIENCE_INFO = 'SET_NEW_AUDIENCE_INFO'

// events
export const GET_EVENT = 'GET_EVENT'
export const GET_EVENT_SUCCESS = apiGotSuccess(GET_EVENT)
export const GET_EVENT_ERROR = apiGotError(GET_EVENT)
export const POST_EVENT = 'POST_EVENT'
export const POST_EVENT_SUCCESS = apiGotSuccess(POST_EVENT)
export const POST_EVENT_ERROR = apiGotError(POST_EVENT)
export const PUT_EVENT = 'PUT_EVENT'
export const PUT_EVENT_SUCCESS = apiGotSuccess(PUT_EVENT)
export const PUT_EVENT_ERROR = apiGotError(PUT_EVENT)
export const SET_EVENT = 'SET_EVENT'
export const RESET_EVENT = 'RESET_EVENT'
export const DELETE_EVENT = 'DELETE_EVENT'
export const DELETE_EVENT_SUCCESS = apiGotSuccess(DELETE_EVENT)
export const DELETE_EVENT_ERROR = apiGotError(DELETE_EVENT)

// constant for internal state
export const SET_FORCE_LOGOUT = 'SET_FORCE_LOGOUT'

// constant for loading state
export const SET_LOADING = 'SET_LOADING'

// constant for notice state
export const SET_NOTICE = 'SET_NOTICE'
export const REMOVE_NOTICE = 'REMOVE_NOTICE'
export const CLEAR_NOTICE = 'CLEAR_NOTICE'
