export enum RichMenuStatus {
  PREVIEW = 1,
  SCHEDULING = 2,
  CANCELLED = 3,
  ERROR = 4,
  PUBLISHED = 5,
}

export enum RichMenuType {
  DEFAULT = 0,
  REGIST,
}

export enum RichMenuTypeKey {
  'default' = 0,
  'regist',
}

export enum RedirectUriType {
  SELECT = 0,
  INPUT = 1,
  FILE = 2,
  TEXT = 3,
  SUBMENU = 4,
}

export enum gaType {
  SELECT = 0,
  INPUT = 1,
}
