import * as types from '../../constants/types';

export function getRichMenu() {
  return {
    type: types.GET_RICHMENU
  }
}

export function setRichMenu(data: any) {
  return {
    type: types.SET_RICHMENU,
    payload: data
  }
}

export function resetRichMenu() {
  return {
    type: types.RESET_RICHMENU,
  }
}

export function getRichMenuSuccess(res: any) {
  return {
    type: types.GET_RICHMENU_SUCCESS,
    payload: res
  }
}

export function getRichMenuError(res: any, subMenuRes: any) {
  return {
    type: types.GET_RICHMENU_ERROR,
    payload: {
      error: res,
      subMenu: subMenuRes.subMenuList
    }
  }
}

export function getSubMenu() {
  return {
    type: types.GET_SUBMENU
  }
}

export function getSubMenuSuccess(richmenuRes: any, subMenuRes: any) {
  return {
    type: types.GET_SUBMENU_SUCCESS,
    payload: {
      data: richmenuRes,
      subMenu: subMenuRes.subMenuList
    }
  }
}

export function getSubMenuError(res: any) {
  return {
    type: types.GET_SUBMENU_ERROR,
    payload: res
  }
}

