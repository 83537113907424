import React, { FC } from 'react'
//
import { LoginSection } from '../../organisms'
import { CenterAlignmentTemplate } from '../../templates'

/**
 * Interface
 */

interface LoginPagePresenterProps {
  actions: {
    handleLogin: () => void
  }
}

/**
 * Presenter
 */

export const LoginPagePresenter: FC<LoginPagePresenterProps> = props => {
  const {
    actions: { handleLogin },
  } = props
  return (
    <CenterAlignmentTemplate>
      <LoginSection handleLogin={handleLogin} />
    </CenterAlignmentTemplate>
  )
}
