import * as Domain from '../domain'

type Tenant = Domain.Tenant.Entity | null

export function createInitialState(): Tenant {
  return null
}

export const SET_TENANT = 'tenant/set' as const

export function setTenant(tenant: Tenant) {
  return {
    type: SET_TENANT,
    payload: {
      tenant,
    },
  }
}

type SetAction = ReturnType<typeof setTenant>

export type Action = SetAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_TENANT:
      return action.payload.tenant
    default:
      return state
  }
}
