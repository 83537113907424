import * as Domain from '../domain'

type Users = Array<Domain.User.Entity>

export function createInitialState(): Users {
  return []
}

export const SET_USERS = 'users/set' as const

export function setUsers(users: Users) {
  return {
    type: SET_USERS,
    payload: {
      users,
    },
  }
}

type SetAction = ReturnType<typeof setUsers>

export type Action = SetAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_USERS:
      return action.payload.users
    default:
      return state
  }
}
