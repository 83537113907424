import querystring from 'query-string'
import { APP_GA_LIST, APP_SCREEN_LIST } from '../../constants/richmenu'
import { functions } from '../../libs'
import { RichMenuTypeKey, RichMenuStatus, RedirectUriType, RichMenuType } from '../enum'
import { formatDateTime } from '../../libs/date'
import { getRedirectAppEndPoint } from '../../libs/functions/redirectAppEndPoint'

export type AreaActionType = {
  uri: string
  redirectUri: string
  redirectUriType: number | null
  type: string
  isDisabled?: boolean
  data?: string
  text?: string
}

export type AreaBoundType = {
  x: number
  y: number
  height: number
  width: number
}

export type AreaType = {
  action: AreaActionType
  bounds: AreaBoundType
}

export type RichmenuDataKeyType = keyof typeof RichMenuTypeKey

export type FetchedRichmenuType = {
  default: RichmenuType
  regist: RichmenuType
  triggerTime: string | null
  status: RichMenuStatus | null
  submittedDate?: string
}

export type RichmenuDataType = {
  chatBarText: string
  name: string
  selected: boolean
  size: {
    width: number
    height: number
  }
  areas: AreaType[]
}

export type RichmenuType = {
  image?: string | null
  richmenuData: RichmenuDataType
}

export type SubmenuType = {
  subMenuId: string
  baseUrl: string
}

const postRichmenuDefaultArea = (x: number, y: number, status: string, isDisabled = true): AreaType => ({
  action: {
    redirectUri: APP_SCREEN_LIST[status][0],
    redirectUriType: 0,
    type: 'uri',
    uri: APP_GA_LIST[status][0],
    isDisabled,
    data: '',
    text: '',
  },
  bounds: {
    x,
    y,
    width: 833,
    height: 843,
  },
})

export const postRichmenuDefault = {
  default: {
    image: null,
    richmenuData: {
      chatBarText: 'メニュー',
      name: 'LINE Developers Info',
      selected: true,
      size: { width: 2500, height: 1686 },
      areas: [
        postRichmenuDefaultArea(0, 0, RichMenuTypeKey[RichMenuType.DEFAULT]),
        postRichmenuDefaultArea(834, 0, RichMenuTypeKey[RichMenuType.DEFAULT]),
        postRichmenuDefaultArea(1687, 0, RichMenuTypeKey[RichMenuType.DEFAULT]),
        postRichmenuDefaultArea(0, 843, RichMenuTypeKey[RichMenuType.DEFAULT]),
        postRichmenuDefaultArea(834, 843, RichMenuTypeKey[RichMenuType.DEFAULT]),
        postRichmenuDefaultArea(1687, 843, RichMenuTypeKey[RichMenuType.DEFAULT]),
      ],
    },
  },
  regist: {
    image: null,
    richmenuData: {
      chatBarText: 'メニュー',
      name: 'LINE Developers Info',
      selected: true,
      size: { width: 2500, height: 1686 },
      areas: [
        postRichmenuDefaultArea(0, 0, RichMenuTypeKey[RichMenuType.REGIST]),
        postRichmenuDefaultArea(834, 0, RichMenuTypeKey[RichMenuType.REGIST]),
        postRichmenuDefaultArea(1687, 0, RichMenuTypeKey[RichMenuType.REGIST]),
        postRichmenuDefaultArea(0, 843, RichMenuTypeKey[RichMenuType.REGIST]),
        postRichmenuDefaultArea(834, 843, RichMenuTypeKey[RichMenuType.REGIST]),
        postRichmenuDefaultArea(1687, 843, RichMenuTypeKey[RichMenuType.REGIST]),
      ],
    },
  },
  triggerTime: null,
  status: null,
  subMenu: null,
}

export class PostRichmenu {
  richmenuImages: {
    default: any
    regist: any
  }
  richmenuData: {
    default: RichmenuDataType
    regist: RichmenuDataType
  }
  triggerTime: string | null

  constructor(data: PostRichmenu | null, richmenuInput: FetchedRichmenuType = postRichmenuDefault) {
    if (data) {
      this.richmenuImages = data.richmenuImages
      this.richmenuData = data.richmenuData
      this.triggerTime = data.triggerTime
    } else {
      this.richmenuData = {
        default: this.handleAreaType(richmenuInput.default, RichMenuTypeKey[RichMenuType.DEFAULT]).richmenuData,
        regist: this.handleAreaType(richmenuInput.regist, RichMenuTypeKey[RichMenuType.REGIST]).richmenuData,
      }
      this.removeLinkAll()
      this.richmenuImages = {
        default: richmenuInput.default.image,
        regist: richmenuInput.regist.image,
      }
      this.triggerTime = richmenuInput.triggerTime ? richmenuInput.triggerTime : new Date().toISOString()
    }
  }

  removeLink(areas: AreaType[], status: string) {
    return areas.map(x => {
      const uri = APP_GA_LIST[status][0]
      const redirectUri =
        x.action.redirectUriType === RedirectUriType.FILE
          ? x.action.redirectUri.split('/').pop() || APP_SCREEN_LIST[status][0]
          : x.action.redirectUri
      return {
        bounds: x.bounds,
        action: {
          uri,
          redirectUri,
          redirectUriType: x.action.redirectUriType,
          type: x.action.type,
          isDisabled: x.action.isDisabled,
          data: x.action.data,
          text: x.action.text,
        },
      }
    })
  }

  removeLinkAll() {
    const defaultAreas = this.removeLink(this.richmenuData.default.areas, RichMenuTypeKey[RichMenuType.DEFAULT])
    const registAreas = this.removeLink(this.richmenuData.regist.areas, RichMenuTypeKey[RichMenuType.REGIST])
    this.richmenuData = {
      default: { ...this.richmenuData.default, areas: defaultAreas },
      regist: { ...this.richmenuData.regist, areas: registAreas },
    }
  }

  addLinkAndRemoveDisabledAreas(areas: AreaType[], tenantId: string, tenantName: string, action: string): AreaType[] {
    return areas
      .map(x => {
        const uri = this._createUri(x, tenantId, tenantName, action)
        const redirectUri =
          x.action.redirectUriType === RedirectUriType.FILE
            ? `${process.env.REACT_APP_CLOUDFRONT_URL}/${x.action.redirectUri}`
            : x.action.redirectUri
        const data =
          x.action.redirectUriType === RedirectUriType.SUBMENU
            ? this._addIsPreviewToData(x.action.data, action)
            : x.action.data
        return {
          bounds: x.bounds,
          action: {
            uri,
            redirectUri,
            redirectUriType: x.action.redirectUriType,
            type: x.action.type,
            isDisabled: x.action.isDisabled,
            data,
            text: x.action.text,
          },
        }
      })
      .filter(x => !x.action.isDisabled)
  }

  handlePostData(tenantId: string, tenantName: string, action: string) {
    const defaultAreas = this.addLinkAndRemoveDisabledAreas(
      this.richmenuData.default.areas,
      tenantId,
      tenantName,
      action,
    )
    const registAreas = this.addLinkAndRemoveDisabledAreas(this.richmenuData.regist.areas, tenantId, tenantName, action)
    this.richmenuData = {
      default: { ...this.richmenuData.default, areas: defaultAreas },
      regist: { ...this.richmenuData.regist, areas: registAreas },
    }
    this.triggerTime = this.triggerTime ? new Date(this.triggerTime).toISOString() : new Date().toISOString()
  }

  handleAreaType(richmenu: RichmenuType, status: string): RichmenuType {
    const pos = [
      { x: 0, y: 0 },
      { x: 834, y: 0 },
      { x: 1687, y: 0 },
      { x: 0, y: 843 },
      { x: 834, y: 843 },
      { x: 1687, y: 843 },
    ]
    const areas = pos.map(item => {
      const area = richmenu.richmenuData.areas.find(itemA => item.x === itemA.bounds.x && item.y === itemA.bounds.y)
      return area ? area : postRichmenuDefaultArea(item.x, item.y, status, true)
    })
    richmenu.richmenuData.areas = areas
    return richmenu
  }

  private _addIsPreviewToData(data: string, action: string): string {
    const dataParse = querystring.parse(data)
    const dataAddedIsPreview = {
      ...dataParse,
      isPreview: action === 'preview' ? true : false,
    }
    return querystring.stringify(dataAddedIsPreview)
  }

  private _createUri = (areaType: AreaType, tenantId: string, _tenantName: string, action: string) => {
    if (
      areaType.action.redirectUriType === RedirectUriType.TEXT ||
      areaType.action.redirectUriType === RedirectUriType.SUBMENU
    )
      return ''

    return this._createDestination(areaType.action, tenantId, action)
  }

  private _createDestination = (areaAction: AreaActionType, tenantId: string, action: string) => {
    switch (areaAction.redirectUriType) {
      case RedirectUriType.SELECT:
        return `${functions.account.generalAccountLiffDomain(tenantId, action)}${areaAction.redirectUri}`
      case RedirectUriType.FILE:
        return `${process.env.REACT_APP_CLOUDFRONT_URL}/${areaAction.redirectUri}`
      default:
        return areaAction.redirectUri
    }
  }

  private _decodeUri = (area: AreaType): AreaType => {
    const decodeUri = new URLSearchParams(area.action.uri).get('utm_content')
    return {
      ...area,
      action: {
        ...area.action,
        uri: decodeUri != null ? decodeURI(decodeUri) : area.action.uri,
      },
    }
  }
}
