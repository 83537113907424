import React, { FC, useContext } from 'react'
import clsx from 'clsx'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
//
import { NavigationContext } from '../../../contexts'
import { Box, Container, Drawer, Typography } from '../../atoms'
import { LoadingPanel, PageHeader, SectionPanel } from '../../molecules'
import { Modules } from '../../../features'
import { HeaderBar, Sidebar } from '../../organisms'
import { useLocation } from 'react-router-dom'
import { BREADCRUMB_DATA } from '../../../constants/breadcrumb'
import { useTranslation } from 'react-i18next'

/**
 * Interface
 */

export interface PageTemplateProps {
  children: any
}

/**
 * Style
 */

// FIXME: constants で管理する
const sidebarWidth = 256
const headerHight = 64

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'grid',
      fallbacks: {
        display: '-ms-grid',
      },
      width: '100%',
      height: '100%',
      '-ms-grid-rows': '64px 1fr 64px',
      '-ms-grid-columns': '1fr',
      gridTemplate: `
      'pageHeaderBar' 64px
      'pageContent' auto
    `,
    },
    breadcrumbItem: {
      '&:last-child $breadcrumbSplitter': { 
        display: 'none'
      }
    },
    breadcrumbSplitter: {
    },
    headerBar: {
      gridArea: 'pageHeaderBar',
      '-ms-grid-row': 1,
      width: '100%',
      zIndex: theme.zIndex.drawer + 1,
      position: 'fixed',
    },
    content: {
      gridArea: 'pageContent',
      '-ms-grid-row': 2,
      width: '100%',
      display: 'flex',
      // paddingTop: theme.spacing(2),
      // paddingBottom: theme.spacing(4),
    },
    container: {
      height: '100%',
      position: 'relative'
    },
    maxWidthLgPaddingCustom: {
      padding: '0 60px',
      minWidth: 1000
    },
    sidebar: {
      width: sidebarWidth,
      flexShrink: 0,
    },
    sidebarPaper: {
      width: sidebarWidth,
      paddingTop: headerHight,
    },
    main: {
      height: '100%',
      flexGrow: 1,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -sidebarWidth,
    },
    mainShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
  }),
)

/**
 * Presenter
 */

export const PageTemplate: FC<PageTemplateProps> = React.memo((props: PageTemplateProps) => {
  const { children } = props
  const classes = useStyles()
  const { sidebarDisplayCondition } = useContext(NavigationContext)
  const { t } = useTranslation()
  const location = useLocation()
  const [breadcrumb, setBreadcrumb] = React.useState<string[]>([])

  React.useEffect(() => {
    console.log(location)
    const pathArr = location.pathname.split('/').filter(x => x)
    pathArr.shift()
    const urlBuilder = pathArr.join('/')
    let labels
    BREADCRUMB_DATA.some((item: any) => {
      if (item.pattern.test(urlBuilder)) {
        labels = item.labels
        return true
      }
      return false
    })
    setBreadcrumb(labels ? labels : [])
  }, [location.pathname])

  return (
    <div className={`${classes.root} ieHeightAuto`}>
      <div className={classes.headerBar}>
        <HeaderBar />
      </div>
      <div className={classes.content}>
        <Drawer
          className={classes.sidebar}
          variant="persistent"
          anchor="left"
          open={sidebarDisplayCondition}
          classes={{
            paper: classes.sidebarPaper,
          }}
        >
          <Sidebar />
        </Drawer>
        <main
          className={clsx(classes.main, {
            [classes.mainShift]: sidebarDisplayCondition,
          })}
        >
          <Container
            className={classes.container}
            classes={{maxWidthLg: classes.maxWidthLgPaddingCustom}}>
            <LoadingPanel/>
            <PageHeader>
              <Box display="flex">
                { 
                  breadcrumb.map((lab: string, index: number) => (
                    <div className={classes.breadcrumbItem} key={index}>
                      <Box color="text.secondary">
                        <Typography className="txt-decoration-line" variant="h6" component="span">{ t(lab) }</Typography>
                        <Box px={1} clone>
                          <span className={classes.breadcrumbSplitter}>/</span>
                        </Box>
                      </Box>
                    </div>
                  ))
                }
              </Box>
            </PageHeader>
            { children }
          </Container>
        </main>
      </div>
    </div>
  )
})
