import React from 'react'
import { SingleModalConsumer } from '../../../molecules/ModalContainer'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { Dialog, DialogTitle, DialogContent, DialogActions, Divider } from '../../../atoms'

export interface PrimaryModalProps {
  header: React.ReactNode | React.ReactElement
  content: React.ReactNode | React.ReactElement
  footer?: React.ReactNode | React.ReactElement
}

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      width: '100%',
    },
    actionRoot: {
      padding: 20,
    },
    titleRoot: {
      padding: 20,
    },
    contentRoot: {
      padding: 20,
    },
    disagreeBtn: {
      marginRight: 'auto',
    },
    agreeBtn: {
      marginLeft: 'auto',
    },
  }),
)

export const ConfirmDownloadCsvModalTemplate: React.FC<PrimaryModalProps> = props => {
  const classes = useStyles()
  return (
    <SingleModalConsumer>
      {({ open }) => (
        <>
          <Dialog open={open} classes={{ paper: classes.paper }}>
            <DialogTitle classes={{ root: classes.titleRoot }}>{props.header}</DialogTitle>
            <Divider />
            <DialogContent classes={{ root: classes.contentRoot }}>{props.content}</DialogContent>
            {props.footer && (
              <>
                <Divider />
                <DialogActions classes={{ root: classes.actionRoot }}>{props.footer}</DialogActions>
              </>
            )}
          </Dialog>
        </>
      )}
    </SingleModalConsumer>
  )
}
