import { makeStyles } from '@material-ui/core'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Link, useHistory, useParams } from 'react-router-dom'
import { ModalsContext } from '../../../../contexts'
import NoticeContext, { NoticeProvider } from '../../../../contexts/NoticeContext'
import { hooks } from '../../../../libs'
import { getThunk } from '../../../../libs/api'
import { formatDateTime } from '../../../../libs/date'
import { downloadCSV } from '../../../../stores/audience/audience.middleware'
import { setLoading } from '../../../../stores/loading/loading.action'
import { Box, Button, CircularProgress, DataObjectTable, Divider, Typography } from '../../../atoms'
import { ObjectData } from '../../../atoms/DataObjectTable'
import { SectionPanel } from '../../../molecules'
import { NoticeDownloadCSVFailed } from '../modals'
import { AUDIENCE_CONDITIONS, AUDIENCE_LABELS, AUDIENCE_STATUS_LABELS } from '../utility/AudienceData'

const detailAudienceCardTypeLabels = {
  audienceName: 'オーディエンス名',
  status: '結果',
  createdAt: 'オーディエンス作成日時',
  type: '条件',
  audienceSize: '抽出数',
  author: '担当者'
}

const detailAudienceStoresTypeLabels = {
  audienceName: 'オーディエンス名',
  status: '結果',
  createdAt: 'オーディエンス作成日時',
  type: '条件',
  audienceSize: '抽出数',
  numberOfStores: '店舗数',
  storesKey: '店舗コード\n店舗名',
  author: '担当者'
}

interface AudienceDetail {
  audienceId: string
  audienceName: string
  audienceSize: number
  author: any
  createdAt: string
  lineAudienceId: number
  messsageResponse: any
  pathFile: string
  status: number | string
  storeAt: any
  tenantId: string
  type: string
  updatedAt: string
}

class AudienceDetailTable {
  audienceName: string
  status: string
  createdAt: string
  type: string
  audienceSize: string
  numberOfStores: string
  storesKey: string
  author: string 
  
  constructor(data: any) {
    this.audienceName = data.audienceName
    this.status = data.status
    this.createdAt = formatDateTime(data.createdAt)
    this.type = AUDIENCE_LABELS[data.type]
    this.audienceSize = data.audienceSize
    this.numberOfStores = data.storeAt?.storeCds?.length || 0
    this.storesKey = data.storeAt?.storeCdNames?.join('\n')
    this.author =  data.author?.name
  }
}

const useStyles = makeStyles({
  resetMuiButtonStartIcon: {
    '& .MuiButton-startIcon': { 
      marginLeft: 0,
      marginRight: 0
    },
    '& .MuiButton-startIcon .MuiCircularProgress-root ': {
      marginRight: '5px'
    }
  }
})

export const AudienceDetailContent = () => {
  const { setNoticeTitle, pushNoticeContent, clearNotice } = React.useContext(NoticeContext)
  const { tenantId } = hooks.useTenant()
  const { accessToken } = hooks.useAuth()
  const { t } = useTranslation(['audience', 'translation'])
  const [data, setData] = React.useState<ObjectData| null>(null)
  const [audience, setAudience] = React.useState<AudienceDetail>()
  const [labels, setLabels] = React.useState({})
  const dispatch = useDispatch()
  const { id }: any = useParams()
  const history = useHistory()
  const [downloading, setDownloading] = React.useState(false)
  const classes = useStyles()
  const { changeModal } = React.useContext(ModalsContext)

  const downloadCSVHandler = () => {
    setDownloading(true)
    dispatch(downloadCSV(audience.audienceName, audience?.pathFile, accessToken, tenantId))
      .catch(_e => {
        changeModal(<NoticeDownloadCSVFailed/>)
      })
      .finally(() => {
        setDownloading(false)
      })
  }

  const duplicateAudience = () => {
    history.push(`../create?audienceId=${audience?.audienceId}`)
  }

  React.useEffect(() => {
    if (id) {
      dispatch(setLoading(true))
      dispatch(getThunk(`audiences/detail/${id}`, accessToken, tenantId))
        .then((response: AudienceDetail) => {
          if (response) {
            if ([AUDIENCE_CONDITIONS.PATTERN1, AUDIENCE_CONDITIONS.PATTERN2].includes(+response.type)) {
              setLabels(detailAudienceStoresTypeLabels)
            } else if ([AUDIENCE_CONDITIONS.PATTERN3, AUDIENCE_CONDITIONS.PATTERN4].includes(+response.type)) {
              setLabels(detailAudienceCardTypeLabels)
            }
            response.status = t(`translation:${AUDIENCE_STATUS_LABELS[+response.status]}`).toString() || ''
            setAudience(response)
            setData(new AudienceDetailTable(response))
            clearNotice()
          }
        })
        .catch((e: any) => {
          if (e.errorCode === '40414') {
            history.push(`/not-found`)
          } else {
            setNoticeTitle(t('translation:notice.fetchData.title'))
            pushNoticeContent(t('translation:notice.fetchData.content.errorFetch'))
          }
        })
        .finally(() => {
          dispatch(setLoading(false))
        })
    }
  }, [id])

  return (
    data &&
    <>
      <Box pb={2}>
        <Box height="100%" display="flex" justifyContent="flex-end">
          <Box mr={1}>
            <Button
              variant="outlined" 
              onClick={duplicateAudience}
              color="primary">
              {t('translation:copy')}
            </Button>
          </Box>
          <Box ml={1}>
            <Button
              className={classes.resetMuiButtonStartIcon}
              startIcon={downloading ? <CircularProgress size={18}/> : <></>}
              disabled={downloading}
              onClick={downloadCSVHandler}
              variant="outlined" 
              color="primary">
              CSV
            </Button>
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box pt={3}>
        <DataObjectTable data={data} labels={labels}/>
      </Box>
      <Box pt={2}>
        <Box height="100%" display="flex" justifyContent="flex-end">
          <Box mr={1}>
            <Button
              variant="outlined" 
              onClick={duplicateAudience}
              color="primary">
              {t('translation:copy')}
            </Button>
          </Box>
          <Box ml={1}>
            <Button
              className={classes.resetMuiButtonStartIcon}
              disabled={downloading}
              startIcon={downloading ? <CircularProgress size={18}/> : <></>}
              variant="outlined" 
              onClick={downloadCSVHandler}
              color="primary">
              CSV
            </Button>
          </Box>
        </Box>
        <Box pt={2} display="flex" justifyContent="flex-end">
          <Box color="primary.main" clone>
            <Typography component={Link} to={'../'} className="nav-link">
              ＜ リストへ戻る
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  )
}



export const AudienceDetailPage = () => {
  return (
    <Box mb={3}>
      <SectionPanel>
        <Box px={7} py={3}>
          <NoticeProvider>
            <AudienceDetailContent/>
          </NoticeProvider>
        </Box>
      </SectionPanel>
    </Box>
  )
}