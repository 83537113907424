import React, { FC } from 'react'
//
import { hooks } from '../../../libs'
import { NotFoundErrorPagePresenter } from './NotFoundErrorPagePresenter'

/**
 * Interface
 */

interface ChildrenProps {
  actions: {
    handleDashboard: () => void
  }
}

interface NotFoundErrorPageContainerProps {
  children: (props: ChildrenProps) => React.ReactElement
}

/**
 * Container
 */

export const NotFoundErrorPageContainer: FC<NotFoundErrorPageContainerProps> = props => {
  const { children } = props

  const { history } = hooks.useReactRouter()

  const handleDashboard = () => {
    history.push('/select-account')
  }

  return children({
    actions: {
      handleDashboard,
    },
  })
}

/**
 * Combine
 */

export default () => {
  return (
    <NotFoundErrorPageContainer>
      {props => {
        return <NotFoundErrorPagePresenter {...props} />
      }}
    </NotFoundErrorPageContainer>
  )
}
