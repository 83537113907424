interface OrderResults {
  succeeded: string[]
  failded: string[]
}

export function createInitialState(): OrderResults {
  return {
    succeeded: [],
    failded: [],
  }
}

export const SET_ORDER_RESULTS = 'orderResults/set' as const
export const RESET_ORDER_RESULTS = 'orderResults/reset' as const

export function setOrderResults(orderResults: OrderResults) {
  return {
    type: SET_ORDER_RESULTS,
    payload: {
      orderResults,
    },
  }
}

export function resetOrderResults() {
  return {
    type: RESET_ORDER_RESULTS,
  }
}

type SetAction = ReturnType<typeof setOrderResults>
type ResetAction = ReturnType<typeof resetOrderResults>

export type Action = SetAction | ResetAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_ORDER_RESULTS:
      return action.payload.orderResults
    case RESET_ORDER_RESULTS:
      return {
        succeeded: [],
        failded: [],
      }
    default:
      return state
  }
}
