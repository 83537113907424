import { Button } from '@material-ui/core'
import React, { FC } from 'react'
import { PrimaryModalTemplate } from '..'
import SingleModalContext from '../..'
import { MODAL_NOTI_AGREE_BTN } from '../../../../../constants/richmenu'

interface PrimaryNoticeModalProps {
  header: any
  content: any
}

export const PrimaryNoticeModal: React.FC<PrimaryNoticeModalProps> = props => {
  const { closeModal } = React.useContext(SingleModalContext)

  const Footer = () => {
    return (
      <Button 
        variant="contained" 
        color="primary" 
        onClick={closeModal}>
        { MODAL_NOTI_AGREE_BTN }
      </Button>
    )
  }
  return (
    <PrimaryModalTemplate
      header={props.header}
      content={props.content}
      footer={<Footer/>}
    />
  )
}
