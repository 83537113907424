import * as Domain from '../domain'

type Orders = Array<Domain.Orders.Entity>

export function createInitialState(): Orders {
  return []
}

export const SET_ORDERS = 'orders/set' as const

export function setOrders(orders: Orders) {
  return {
    type: SET_ORDERS,
    payload: {
      orders,
    },
  }
}

type SetAction = ReturnType<typeof setOrders>

export type Action = SetAction

export function reducer(state = createInitialState(), action: Action) {
  switch (action.type) {
    case SET_ORDERS:
      return action.payload.orders
    default:
      return state
  }
}
