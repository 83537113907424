import React from 'react'
import * as FileSaver from 'file-saver'
import { Box, Button, Divider, makeStyles, Typography } from '@material-ui/core'
import { ConfirmDownloadCsvModalTemplate } from './ConfirmDownloadCsvModalTemplate'
import { formatDateTime } from '../../../../libs/date'
import { useDispatch } from 'react-redux'
import { getThunk } from '../../../../libs/api'
import * as EVENT from '../../../../constants/event'
import { clearNotice, setNotice } from '../../../../stores/notice/notice.action'
import { ModalsContext } from '../../../../contexts'

const useStyles = makeStyles({
  confirmText: {
    margin: '20px 12px',
  },
  container: {
    display: 'flex',
  },
  leftContent: {
    width: '300px !important',
    height: '300px !important',
  },
  rightContent: {
    width: 500,
  },
  rightContentWrapper: {
    padding: '12px',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentWrapper: {
    padding: '12px',
    height: '100%',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  divider: {
    margin: '5px 0',
  },
  headLineText: {
    width: '100%',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 2,
    overflow: 'hidden',
    overflowWrap: 'break-word',
  },
  title: {
    color: '#999999',
  },
  footer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
})

interface ConfirmDownloadCsvModalProps {
  event: any
  accessToken: string
  tenantId: string
}

interface ItemProps {
  title: string
  item: string
}

export const ConfirmDownloadCsvModal: React.FC<ConfirmDownloadCsvModalProps> = props => {
  const { event, accessToken, tenantId } = props
  const { closeAllModals } = React.useContext(ModalsContext)
  const classes = useStyles()
  const dispatch = useDispatch()

  const onClickDownload = async () => {
    await dispatch(getThunk(`events/${event.eventId}/csv_dl`, accessToken, tenantId))
      .then((res: any) => {
        const u8a = new Uint8Array(res.csvData)
        const blob = new Blob([u8a], { type: 'text/csv' })
        FileSaver.saveAs(blob, res.fileName)
      })
      .catch((err: any) => {
        dispatch(clearNotice())
        dispatch(setNotice(err.errorMessage || EVENT.ERR_GET_EVENT_CSV))
        closeAllModals()
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        })
      })
  }

  const Content = () => {
    const Item: React.FC<ItemProps> = props => {
      const classes = useStyles()
      const { title, item } = props
      return (
        <>
          <Typography className={classes.title} variant="body2" style={{ padding: '3px' }}>
            {title}
          </Typography>
          <Typography className={classes.headLineText} variant="body1" style={{ padding: '3px' }}>
            {item}
          </Typography>
        </>
      )
    }

    return (
      <>
        <Typography variant="body1" className={classes.confirmText}>
          {EVENT.MODAL_CONFIRM_DOWNLOAD_TITLE}
        </Typography>
        <div className={classes.container}>
          <div className={classes.leftContent}>
            <div className={classes.rightContentWrapper}>
              <img src={event.eventImageDetail} className={classes.image} />
            </div>
          </div>
          <div className={classes.rightContent}>
            <div className={classes.contentWrapper}>
              <div style={{ height: '25%' }}>
                <Item title={EVENT.MODAL_PREVIEW_DOWNLOAD_LABEL_ID} item={event.eventId} />
              </div>
              <Divider className={classes.divider} />
              <div style={{ height: '35%' }}>
                <Item title={EVENT.MODAL_PREVIEW_DOWNLOAD_LABEL_HEADLINE_TEXT} item={event.headLineText} />
              </div>
              <Divider className={classes.divider} />
              <div style={{ height: '25%' }}>
                <Item
                  title={EVENT.MODAL_PREVIEW_DOWNLOAD_LABEL_DATE}
                  item={`${formatDateTime(event.startDate, 'yyyy/MM/dd')} 〜 ${formatDateTime(
                    event.endDate,
                    'yyyy/MM/dd',
                  )}`}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  const Footer = () => {
    return (
      <>
        <Box className={classes.footer}>
          <Button variant="contained" onClick={closeAllModals}>
            {EVENT.MODAL_CONFIRM_DELETE_CANCEL}
          </Button>
          <Button variant="contained" color="primary" style={{ marginLeft: '10px' }} onClick={onClickDownload}>
            {EVENT.MODAL_CONFIRM_DOWNLOAD}
          </Button>
        </Box>
      </>
    )
  }

  return (
    <ConfirmDownloadCsvModalTemplate
      header={EVENT.MODAL_CONFIRM_DOWNLOAD_HEADER}
      content={<Content />}
      footer={<Footer />}
    />
  )
}
