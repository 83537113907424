import React from 'react'
import { ModalsContext } from '../../../contexts'

interface ModalValue {
  open: boolean
  closeModal: () => void
}

const SingleModalContext = React.createContext<ModalValue>({
  open: false,
  closeModal: () => {}
})

interface SingleModalProviderProps {
  children: React.ReactElement | React.ReactNode,
  i: number
}

export const SingleModalProvider: React.FC<SingleModalProviderProps> = props => {
  const { children } = props
  const [open, setOpen] = React.useState<boolean>(true)

  const { closeCurrentModal } = React.useContext(ModalsContext);

  const closeModal = () => {
    setOpen(false)
    closeCurrentModal(props.i)
  }

  return (
    <SingleModalContext.Provider value={{open, closeModal}}>
      { children }
    </SingleModalContext.Provider>
  )
}

export const SingleModalConsumer = SingleModalContext.Consumer

export default SingleModalContext
