import React from 'react'
import { Typography, CircularProgress } from '@material-ui/core'
import { Unarchive } from '@material-ui/icons'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { COLORS } from '../../../constants'

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    width: 'auto',
    padding: 5,
    backgroundColor: COLORS.CXD_GRAY_1,
    color: theme.palette.grey[600],
  },
  uploadIcon: {
    fontSize: 20,
    marginRight: 5,

  },
  name: {
    fontSize: 13,
    fontWeight: 500,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingRight: 5
  },
  errorType1: {
    backgroundColor: COLORS.CXD_RED_50
  },
  errorType2: {
    border: `solid 1px ${COLORS.RED_1}`
  },
  errMsg: {
    margin: `3px 14px 0`,
    fontSize: 12,
    fontWeight: 'bold',
    color: COLORS.RED_1
  },
  indeterminate: {
    animation: 'none',
  },
  loadingWrap: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto'
  },
}))

interface Props {
  fileName: string,
  loading?: boolean,
  error?: boolean,
  errMsg?: string,
  type: number,
}

export const UploadFileName = (props: Props) => {
  const { fileName, loading, error, errMsg, type } = props
  const classes = useStyles({})
  return (
    <>
      <div className={`${classes.container} ${(error) && (type === 1 ? classes.errorType1 : classes.errorType2)}`}>
        <Unarchive className={classes.uploadIcon}/>
          <Typography className={classes.name}>{ fileName }</Typography>
          <div className={classes.loadingWrap}>
            { !!loading && <CircularProgress classes={{indeterminate: classes.indeterminate}} size={15} /> }
          </div>
      </div>
      {
        (error && errMsg) && <span className={classes.errMsg}>{ errMsg }</span>
      }
    </>
  )
}
