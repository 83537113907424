import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { PrimaryNoticeModal } from '../../../../molecules/ModalContainer/PrimaryDialogTemplate/PrimaryNoticeDialog'

export const NoticeNoUserModal = () => {
  const { t } = useTranslation('audience')
  
  return (
    <PrimaryNoticeModal
      header={t('modals.noticeNoUser.title')}
      content={t('modals.noticeNoUser.content')}
    />
  )
}
