import * as React from 'react'
import { SingleModalProvider } from '../components/molecules/ModalContainer'

interface ModalValue {
  appendModal: (modal: any) => void
  changeModal: (modal: any) => void
  closeAllModals: () => void
  closeCurrentModal: (index: number) => void
}

const ModalsContext = React.createContext<ModalValue>({
  appendModal: (modal: any) => (modal),
  changeModal: (modal: any) => (modal),
  closeAllModals: () => {},
  closeCurrentModal: (index: any) => (index)
})

export const ModalsProvider: React.FC = props => {
  const { children } = props
  const [modals, setModals] = React.useState<any[]>([])

  const appendModal = (modal: any) => {
    setModals([
      ...modals,
      modal
    ])
  }

  const changeModal = (modal: any) => {
    setModals([modal])
  }

  const closeAllModals = () => {
    setModals([])
  }

  const closeCurrentModal = (index: number) => {
    modals.splice(index, 1)
    setModals([...modals])
  }

  return (
    <ModalsContext.Provider value={{ appendModal, closeAllModals, closeCurrentModal, changeModal }}>
      {
        modals.map((modal: any, index: number) => (
          <SingleModalProvider key={index} i={index}>
            { modal }
          </SingleModalProvider>
        ))
      }
      {children}
    </ModalsContext.Provider>
  )
}

export const ModalConsumer = ModalsContext.Consumer

export default ModalsContext
