import * as React from 'react'
import { Redirect, Route, RouteComponentProps, Switch, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { Modules } from '../features'
import { TenantProvider } from '../contexts/TenantContext'
import { hooks } from '../libs'
import { Auth } from '../features/domain'
import { PageTemplate } from '../components/templates'
import { ModalsProvider } from '../contexts/ModalsContext'
import { AudiencePage } from '../components/pages/AudiencePage'
import { RichmenuPage } from '../components/pages/RichmenuPage'
import { EventPage } from '../components/pages/EventPage'

type MatchParams = {
  tenantId: string
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface TenantContainerProps extends RouteComponentProps<MatchParams> {}

const fullRoutes: any = {
  richmenu: {
    path: 'richmenu',
    redirect: 'richmenu',
    comp: RichmenuPage,
  },
  audience: {
    path: 'audience',
    redirect: 'audience/create',
    comp: AudiencePage,
  },
  event: {
    path: 'events',
    redirect: 'events',
    comp: EventPage,
  },
}

const BuildRoutes = (props: any) => {
  const { tenant } = props
  const [allowedFeatures, setAllowedFeatures] = React.useState<string[]>([])

  React.useEffect(() => {
    if (tenant) {
      const features = Object.keys(tenant?.permissions).filter(
        permission => permission !== 'eventEntryCsvDl' && tenant?.permissions[permission],
      )
      setAllowedFeatures(features)
    }
  }, [tenant])

  return allowedFeatures.length > 0 ? (
    <Switch>
      {allowedFeatures.map((feature, index) => (
        <Route
          key={`b-${index}`}
          path={`/:tenantId/${fullRoutes[feature].path}`}
          component={fullRoutes[feature].comp}
        />
      ))}
      <Route
        exact
        path={`/:tenantId`}
        render={() => {
          return <Redirect to={`/${tenant?.tenantId}/${fullRoutes[allowedFeatures[0]].redirect}`} />
        }}
      />
      <Route
        render={() => {
          return <Redirect to={`/not-found`} />
        }}
      />
    </Switch>
  ) : (
    <></>
  )
}

export default () => {
  const { tenantId } = useParams<{ tenantId: string }>()
  const { tenants } = hooks.useAuth()
  const [currentTenant, setCurrentTenant] = React.useState<Auth.TenantAuth0Interface>()
  const dispatch = useDispatch()

  React.useEffect(() => {
    if (tenants) {
      const temp = tenants.find((tenant: Auth.TenantAuth0Interface) => {
        return tenant.tenantId === tenantId
      })
      setCurrentTenant(temp)
      dispatch(Modules.App.setTenantId(tenantId))
    }
  }, [tenants])

  return (
    <TenantProvider value={{ tenantId }}>
      <ModalsProvider>
        <PageTemplate>
          <BuildRoutes tenant={currentTenant} />
        </PageTemplate>
      </ModalsProvider>
    </TenantProvider>
  )
}
