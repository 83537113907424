import React from 'react'
import { SectionPanel, AlertMessage } from '../../../../molecules'
import { Typography, Grid, makeStyles, Button, Box, InputAdornment, IconButton } from '@material-ui/core'
import { api, hooks } from '../../../../../libs'
import { Richmenu } from '../../components/Richmenu'
import { COLORS, RICHMENU } from '../../../../../constants'
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { RichmenuStatus } from '../../components/RichmenuStatus'
import { TabletMacOutlined, CalendarToday } from '@material-ui/icons'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { Domain, Enum } from '../../../../../features'
import { yupResolver } from '@hookform/resolvers/yup'
import { addMinutes } from 'date-fns'
import * as yup from 'yup'
import { useSelector, useDispatch } from 'react-redux'
import { setNotice, removeNotice, clearNotice } from '../../../../../stores/notice/notice.action'
import { setRichMenu } from '../../../../../stores/richmenu/richmenu.action'
import { PostRichmenu, FetchedRichmenuType, SubmenuType } from '../../../../../features/domain/richmenu'
import { setLoading } from '../../../../../stores/loading/loading.action'
import { fetchRichmenu } from '../../../../../stores/richmenu/richmenu.middleware'
import { ModalsContext } from '../../../../../contexts'
import {
  ConfirmCancelModal,
  ConfirmSubmitModal,
  NoticePreviewModal,
  NoticeSubmitModal,
  ErrorCancelModal,
} from '../../modals'
import { RedirectUriType } from '../../../../../features/enum'

const SUBMIT_BTN_TITLE = '公開予約'
const CANCEL_BTN_TITLE = '予約取消'
const RESERVATION_TITLE = '予約日時'
const PREVEW_DESCRIPTION = '入力内容を保存してPRV用アカウントに反映します'
const PREVIEW_BTN_TITLE = 'プレビュー'

const currentDate = new Date()

const useStyles = makeStyles(() => ({
  container: {
    paddingTop: 10,
  },
  submitSection: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
    marginTop: 20,
    marginBottom: 50,
  },
  pageTitle: {
    textDecorationLine: 'underline',
    color: '#979797',
    fontWeight: 'normal',
  },
  submitButton: {
    backgroundColor: COLORS.SUBMIT_BTN_BLUE,
  },
  reservationTitle: {
    marginBottom: 10,
  },
  previewBtnGroup: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
  previewTitle: {
    marginRight: 15,
  },
  errorButton: {
    marginTop: 20,
  },
  errMsg: {
    fontWeight: 'bold',
    color: COLORS.RED_1,
    marginTop: 10,
  },
  alertWrap: {
    paddingBottom: 20,
  },
  areaWrap: {
    padding: 20,
  },
  disabled: {
    '& .disabled': {
      pointerEvents: 'none',
    },
    '& .disabled-img-btn': {
      backgroundColor: COLORS.CXD_GRAY_400,
    },
    '& .disabled-triggerTime': {
      '& .MuiInputBase-root, & .MuiButtonBase-root': {
        color: COLORS.CXD_GRAY_400,
      },
    },
    '& .disabled-input': {
      color: COLORS.CXD_GRAY_400,
      '& .MuiInputBase-root': {
        color: COLORS.CXD_GRAY_400,
      },
    },
    '& .disabled-radio': {
      '& .MuiIconButton-label': {
        color: COLORS.CXD_GRAY_400,
      },
    },
    '& .disabled-checkbox': {
      '& .MuiIconButton-label': {
        color: COLORS.CXD_GRAY_400,
      },
    },
    '& .disabled-material-btn': {
      backgroundColor: COLORS.CXD_GRAY_400,
      color: COLORS.CXD_GRAY_2,
    },
    '& .disabled-submenu': {
      cursor: 'auto',
      '&:active': {
        pointerEvents: 'none',
      },
    },
  },
}))

const uriRegExpInvalid = new RegExp(
  /^[\u3041-\u309f\u30a0-\u30fa\u30fc-\u30fe\uff10-\uff19\uff21-\uff3a\uff41-\uff5a\uff66-\uff9d\u4e00-\u9faf\u3400-\u4dbfa-zA-Z0-9_\-]+$/,
)
const areasValidateSchema = yup.object().shape({
  areas: yup.array().of(
    yup.object().shape({
      action: yup.object().shape({
        isDisabled: yup.bool(),
        redirectUriType: yup.number(),
        redirectUri: yup.string().when('isDisabled', {
          is: true,
          then: yup.string().nullable(),
          otherwise: yup.string().when('redirectUriType', {
            is: RedirectUriType.SUBMENU,
            then: yup.string().nullable(),
            otherwise: yup.string().when('redirectUriType', {
              is: 1,
              then: yup.string().required(RICHMENU.REQUIRED_URL_MSG).url(RICHMENU.INVALID_URL_MSG),
              otherwise: yup.string().when('redirectUriType', {
                is: RedirectUriType.TEXT,
                then: yup.string().nullable(),
                otherwise: yup.string().required(),
              }),
            }),
          }),
        }),
        uri: yup.string().when('isDisabled', {
          is: true,
          then: yup.string(),
          otherwise: yup.string().when('redirectUriType', {
            is: RedirectUriType.SUBMENU,
            then: yup.string().nullable(),
            otherwise: yup
              .string()
              .matches(uriRegExpInvalid, { excludeEmptyString: true, message: RICHMENU.INVALID_QA_MSG })
              .required(RICHMENU.REQUIRED_GA_MSG),
          }),
        }),
        data: yup.string().when('redirectUriType', {
          is: RedirectUriType.SUBMENU,
          then: yup.string().required(RICHMENU.REQUIRED_SUBMENU_MSG),
          otherwise: yup.string().nullable(),
        }),
        text: yup.string().when('redirectUriType', {
          is: RedirectUriType.TEXT,
          then: yup.string().required(RICHMENU.REQUIRED_TEXT_MSG).max(300, RICHMENU.MAX_TEXT_MSG),
          otherwise: yup.string().nullable(),
        }),
      }),
    }),
  ),
})
const imageValidateSchema = yup
  .string()
  .nullable()
  .required()
  .test('invalid', RICHMENU.INVALID_IMAGE_MSG, val => val !== 'invalid')

const validateSchema = yup.object().shape({
  richmenuData: yup.object().shape({
    default: areasValidateSchema,
    regist: areasValidateSchema,
  }),
  richmenuImages: yup.object().shape({
    default: imageValidateSchema,
    regist: imageValidateSchema,
  }),
  isTriggerTimeRequired: yup.bool(),
  triggerTime: yup.date().when('isTriggerTimeRequired', {
    is: true,
    then: () => {
      const validDate = addMinutes(new Date().setSeconds(0), 5)
      return yup.date().min(validDate, RICHMENU.INVALID_DATE_MSG).required()
    },
  }),
})

interface RichmenuCreatePresenterProps {
  richmenu: any
  subMenu: SubmenuType[]
  tenantId: string
  accessToken: string
}

export const RichmenuCreatePresenter: React.FC<RichmenuCreatePresenterProps> = props => {
  const { richmenu, tenantId, accessToken, subMenu } = props
  const { tenants } = hooks.useAuth()
  const [currentTenant, setCurrentTenant] = React.useState<Domain.Auth.TenantAuth0Interface>()
  const action = React.useRef('submit')
  const {
    handleSubmit,
    control,
    setValue,
    setError,
    getValues,
    register,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validateSchema),
  })
  const mainFormRef: any = React.useRef(null)
  const classes = useStyles()
  const { fields: fieldsDefault } = useFieldArray({
    control,
    name: `richmenuData.${Enum.RichMenuTypeKey[Enum.RichMenuType.DEFAULT]}.areas`,
  })
  const { fields: fieldsRegist } = useFieldArray({
    control,
    name: `richmenuData.${Enum.RichMenuTypeKey[Enum.RichMenuType.REGIST]}.areas`,
  })
  const notice = useSelector((state: any) => state?.noticeReducer?.notice)
  const dispatch = useDispatch()
  const { appendModal, closeAllModals } = React.useContext(ModalsContext)
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  React.useEffect(() => {
    return () => {
      dispatch(clearNotice())
    }
  }, [])

  React.useEffect(() => {
    const postRichmenu = new Domain.Richmenu.PostRichmenu(null, richmenu)
    reset(postRichmenu)
  }, [richmenu])

  React.useEffect(() => {
    const current = tenants.find((tenant: Domain.Auth.TenantsAuth0Interface) => tenant.tenantId === tenantId)
    setCurrentTenant(current)
  }, [tenants, tenantId])

  const handleSetRichmenuAfterUpdate = (postRichmenu: PostRichmenu | null, res: any) => {
    if (postRichmenu) {
      const temp: FetchedRichmenuType = {
        default: {
          image: postRichmenu.richmenuImages.default,
          richmenuData: postRichmenu.richmenuData.default,
        },
        regist: {
          image: postRichmenu.richmenuImages.regist,
          richmenuData: postRichmenu.richmenuData.regist,
        },
        triggerTime: postRichmenu.triggerTime,
        submittedDate: res.submittedDate,
        status: res.status,
      }
      dispatch(setRichMenu(temp))
    } else {
      dispatch(
        setRichMenu({
          ...richmenu,
          submittedDate: res.submittedDate,
          status: res.status,
        }),
      )
    }
  }

  const callApi = (data: any) => {
    closeAllModals()
    let apiUrl = ''
    let notiErr = ''
    if (action.current === 'submit') {
      apiUrl = 'rich-menu/update'
      notiErr = RICHMENU.SUBMIT_FAILED_MSG
    } else if (action.current === 'preview') {
      apiUrl = 'rich-menu/preview/update'
      notiErr = RICHMENU.PREVIEW_FAILED_MSG
    }
    dispatch(setLoading(true))
    dispatch(api.postThunk(apiUrl, accessToken, tenantId, data))
      .then((res: any) => {
        handleSetRichmenuAfterUpdate(data, res)
        if (action.current === 'submit') {
          appendModal(<NoticeSubmitModal triggerTime={getValues('triggerTime')} />)
        } else if (action.current === 'preview') {
          appendModal(<NoticePreviewModal />)
        }
        dispatch(removeNotice(notiErr))
      })
      .catch(() => {
        dispatch(setNotice(notiErr))
        scrollToTop()
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }

  const onSubmitOfConfirmSubmitModal = React.useCallback(async (data, dataTemp) => {
    const validDate = addMinutes(new Date().setSeconds(0), 5)
    if (new Date(data.triggerTime as string) > validDate) {
      callApi(dataTemp)
    } else {
      await setError('triggerTime', {
        type: 'min',
        message: RICHMENU.INVALID_DATE_MSG,
      })
      await dispatch(setNotice(RICHMENU.VALIDATE_ERR_NOTI))
      closeAllModals()
      await scrollToTop()
    }
  }, [])

  const onSubmitForm = (data: any) => {
    const dataTemp = new Domain.Richmenu.PostRichmenu(data)
    dataTemp.handlePostData(tenantId, currentTenant.tenantName, action.current)
    dispatch(clearNotice())
    if (action.current === 'submit') {
      appendModal(
        <ConfirmSubmitModal
          triggerTime={getValues('triggerTime')}
          onSubmit={() => {
            onSubmitOfConfirmSubmitModal(data, dataTemp)
          }}
        />,
      )
    } else if (action.current === 'preview') {
      callApi(dataTemp)
    }
  }

  const onSubmit = () => {
    setValue('isTriggerTimeRequired', true)
    action.current = 'submit'
    mainFormRef.current.dispatchEvent(new Event('submit', { cancelable: true }))
  }

  const onPreview = () => {
    setValue('isTriggerTimeRequired', false)
    clearErrors('triggerTime')
    action.current = 'preview'
    mainFormRef.current.dispatchEvent(new Event('submit', { cancelable: true }))
  }

  const onReloadRichmenuOfErrorCancelModal = () => {
    closeAllModals()
    dispatch(fetchRichmenu(accessToken, tenantId, 10))
    dispatch(clearNotice())
  }

  const onAgreeOfConfirmCancelModal = () => {
    closeAllModals()
    dispatch(setLoading(true))
    dispatch(api.removeThunk('rich-menu/cancel', accessToken, tenantId))
      .then((res: any) => {
        handleSetRichmenuAfterUpdate(null, res)
        dispatch(removeNotice(RICHMENU.CANCEL_FAILED_MSG))
      })
      .catch((err: any) => {
        if (err.errorCode === '40075') {
          appendModal(<ErrorCancelModal onSubmit={() => onReloadRichmenuOfErrorCancelModal()} />)
        } else {
          dispatch(setNotice(RICHMENU.CANCEL_FAILED_MSG))
          scrollToTop()
        }
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }

  const onCancelRichmenu = () => {
    appendModal(<ConfirmCancelModal onSubmit={() => onAgreeOfConfirmCancelModal()} />)
  }

  const onError = () => {
    dispatch(setNotice(RICHMENU.VALIDATE_ERR_NOTI))
    scrollToTop()
  }

  return (
    <>
      <div className={`${richmenu?.status === Enum.RichMenuStatus.SCHEDULING && classes.disabled}`}>
        <form ref={mainFormRef} onSubmit={handleSubmit(onSubmitForm, onError)}>
          <SectionPanel>
            <Box className={classes.areaWrap}>
              {richmenu && <RichmenuStatus data={richmenu} />}
              {!!notice.length && (
                <Box className={classes.alertWrap}>
                  <AlertMessage type="error" title={RICHMENU.ERR_TITLE}>
                    <ul>
                      {notice.map((err: string, index: number) => {
                        return (
                          <li key={index}>
                            <Typography>{err}</Typography>
                          </li>
                        )
                      })}
                    </ul>
                  </AlertMessage>
                </Box>
              )}
              <>
                <Grid container className={classes.container}>
                  <Grid item xs={6}>
                    <Richmenu
                      data={richmenu?.default}
                      status={Enum.RichMenuType.DEFAULT}
                      register={register}
                      control={control}
                      fields={fieldsDefault}
                      setValue={setValue}
                      errors={errors}
                      setError={setError}
                      clearErrors={clearErrors}
                      subMenu={subMenu}
                      getValues={getValues}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Richmenu
                      data={richmenu?.regist}
                      status={Enum.RichMenuType.REGIST}
                      register={register}
                      control={control}
                      setValue={setValue}
                      fields={fieldsRegist}
                      errors={errors}
                      setError={setError}
                      clearErrors={clearErrors}
                      subMenu={subMenu}
                      getValues={getValues}
                    />
                  </Grid>
                </Grid>
              </>
            </Box>
          </SectionPanel>
          <Box className={classes.submitSection}>
            <Box pb={3}>
              <Box className={classes.previewBtnGroup}>
                <Typography className={classes.previewTitle} variant="body2">
                  {PREVEW_DESCRIPTION}
                </Typography>
                <Button
                  type="button"
                  startIcon={<TabletMacOutlined />}
                  variant="contained"
                  color="primary"
                  onClick={onPreview}
                  disabled={richmenu?.status === Enum.RichMenuStatus.SCHEDULING}
                >
                  {PREVIEW_BTN_TITLE}
                </Button>
              </Box>
            </Box>
            <Box pb={2}>
              <input {...register('isTriggerTimeRequired')} type="" hidden={true} />
              <Typography className={classes.reservationTitle} variant="body2">
                {RESERVATION_TITLE}
              </Typography>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Controller
                  render={({ field: { onChange, value, name } }) => (
                    <DateTimePicker
                      disablePast
                      format="yyyy/MM/dd HH:mm"
                      className="disabled disabled-triggerTime"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton>
                              <CalendarToday />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      name={name}
                      onChange={onChange}
                      value={value}
                      minDateMessage=""
                      error={errors.hasOwnProperty('triggerTime')}
                      helperText={errors.triggerTime?.message}
                    />
                  )}
                  control={control}
                  name="triggerTime"
                  defaultValue={currentDate}
                />
              </MuiPickersUtilsProvider>
            </Box>
            {richmenu?.status === Enum.RichMenuStatus.SCHEDULING ? (
              <Button
                type="button"
                startIcon={<CalendarToday />}
                variant="contained"
                color="secondary"
                className={classes.errorButton}
                onClick={onCancelRichmenu}
              >
                {CANCEL_BTN_TITLE}
              </Button>
            ) : (
              <>
                <Button
                  type="button"
                  startIcon={<CalendarToday />}
                  variant="contained"
                  color="primary"
                  className={classes.submitButton}
                  onClick={onSubmit}
                >
                  {SUBMIT_BTN_TITLE}
                </Button>
              </>
            )}
          </Box>
        </form>
      </div>
    </>
  )
}
