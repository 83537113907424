import React, { FC } from 'react'
import clsx from 'clsx'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

/**
 * Interface
 */

type SemanticStatus = 'default' | 'notice' | 'informative' | 'positive' | 'negative'

interface StatusLabelPresenterProps {
  status: SemanticStatus
}

interface StatusLabelProps {
  status: SemanticStatus
}

/**
 * Styles
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      fontSize: theme.typography.pxToRem(12),
      fontWeight: 'bold',
      textAlign: 'center',
      display: 'inline-block',
      color: theme.palette.common.white,
      minWidth: '60px',
      padding: `${theme.spacing(0.25)}px ${theme.spacing(1)}px`,
      borderRadius: `${theme.spacing(0.5)}px`,
    },
    default: {
      border: `1px solid ${theme.palette.grey[400]}`,
      color: theme.palette.grey[900],
    },
    notice: {
      border: `1px solid ${theme.palette.notice[400]}`,
      color: theme.palette.notice[900],
      backgroundColor: theme.palette.notice[50],
    },
    informative: {
      border: `1px solid ${theme.palette.informative[400]}`,
      color: theme.palette.informative[900],
      backgroundColor: theme.palette.informative[50],
    },
    positive: {
      border: `1px solid ${theme.palette.positive[400]}`,
      color: theme.palette.positive[900],
      backgroundColor: theme.palette.positive[50],
    },
    negative: {
      border: `1px solid ${theme.palette.negative[400]}`,
      color: theme.palette.negative[900],
      backgroundColor: theme.palette.negative[50],
    },
  }),
)

/**
 * Presenter component
 */

export const StatusLabelPresenter: FC<StatusLabelPresenterProps> = props => {
  const { status, children } = props
  const classes = useStyles({})
  return <span className={clsx([classes.label, classes[status]])}>{children}</span>
}

/**
 * Combine component
 */

export const StatusLabel: FC<StatusLabelProps> = props => {
  return <StatusLabelPresenter {...props} />
}
