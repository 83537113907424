import * as React from 'react'
import { Route } from 'react-router-dom'
import { RichmenuCreatePage } from './RichmenuCreatePage'

export const RichmenuPage = () => {
  return (
    <>
      <Route
        exact
        path={`/:tenantId/richmenu`}
        component={RichmenuCreatePage}
      />
    </>
  )
}