import { Box, Typography } from '@material-ui/core'
import { Warning } from '@material-ui/icons'
import { Alert, AlertTitle } from '@material-ui/lab'
import * as React from 'react'
import { AlertMessage } from '../components/molecules'

interface NoticeValue {
  pushNoticeContent: (content: string) => void
  setNoticeTitle: (title: string) => void
  clearNotice: () => void
}

const NoticeContext = React.createContext<NoticeValue>({
  pushNoticeContent: (content: string) => (content),
  setNoticeTitle: (title: string) => (title),
  clearNotice: () => {}
})

export const NoticeProvider: React.FC = props => {
  const { children } = props
  const [content, setNoticeContent] = React.useState<string[]>([])
  const [title, setNoticeTitle] = React.useState<string>('')
  const iconType = {
    // success: <Icon iconName="check-circle" fontSize={'small'} />,
    // warning: <Icon iconName="exclamation-triangle" fontSize={'small'} />,
    error: <Warning />,
    // info: <Icon iconName="info-circle" fontSize={'small'} />,
  }

  const pushNoticeContent = (notice: string) => {
    if (!content.includes(notice)) {
      setNoticeContent((prev) => ([
        ...prev,
        notice
      ]))
    }
  }

  const clearNotice = () => {
    setNoticeTitle('')
    setNoticeContent([])
  }

  return (
    <NoticeContext.Provider value={{ pushNoticeContent, setNoticeTitle, clearNotice }}>
      {
        title && 
        <Box pb={3}>
          {
            content.length === 0 &&
            <Alert color={"error"} severity={"error"} iconMapping={iconType}>
              {title}
            </Alert>
          }
          {
            content.length > 0 &&
            <Alert color={"error"} severity={"error"} iconMapping={iconType}>
              <AlertTitle>
                {title}
              </AlertTitle>
              <ul>
                {
                  content.map((err: string, index: number) => {
                    return (
                      <li key={index}>
                        <Typography>{err}</Typography>
                      </li>
                    )
                  })
                }
              </ul>
            </Alert>
          }
        </Box>
      }
      {children}
    </NoticeContext.Provider>
  )
}

export const NoticeConsumer = NoticeContext.Consumer

export default NoticeContext
