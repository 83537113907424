import { Box, CircularProgress } from '@material-ui/core'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { PrimaryModalTemplate } from '../../../../molecules/ModalContainer/PrimaryDialogTemplate'

export const NoticeQueryUsersModal = () => {
  const { t } = useTranslation('audience')

  const Body = () => {
    return (
      <>
        <Box pt={4} pb={4} display="flex" alignItems="center" justifyContent="center">
          <CircularProgress size={72} />
        </Box>
        <p>{t('modals.queryUser.content1')}</p>
        <p>{t('modals.queryUser.content2')}</p>
      </>
    )
  }
  
  return (
    <PrimaryModalTemplate
      header={t('modals.queryUser.title')}
      content={<Body/>}
    />
  )
}
