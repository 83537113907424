import React from 'react'
import { functions } from '../../../../libs'
import { Domain, Enum } from '../../../../features'
import { ErrorOutline, Warning, TabletMacOutlined, CalendarToday } from '@material-ui/icons'
import { Typography, makeStyles } from '@material-ui/core'
import { COLORS, RICHMENU } from '../../../../constants'
import { format } from 'date-fns'
const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 20
  },
  icon: {
    fontSize: 20,
    marginRight: 5,
    display: 'flex'
  },
}))

const colorList = {
  [`${Enum.RichMenuStatus.SCHEDULING}`]: {
    icon: <CalendarToday />,
    color: COLORS.CXD_BLUE_600,
    text: RICHMENU.SCHEDULING_MSG
  },
  [`${Enum.RichMenuStatus.PREVIEW}`]: {
    icon: <TabletMacOutlined />,
    color: COLORS.CXD_GRAY_500,
    text: RICHMENU.PREVIEW_MSG
  },
  [`${Enum.RichMenuStatus.CANCELLED}`]: {
    icon: <ErrorOutline />,
    color: COLORS.CXD_GRAY_500,
    text: RICHMENU.CANCELLED_MSG
  },
  [`${Enum.RichMenuStatus.PUBLISHED}`]: {
    icon: <ErrorOutline />,
    color: COLORS.CXD_GRAY_500,
    text: RICHMENU.PUBLISHED_MSG
  },
  [`${Enum.RichMenuStatus.ERROR}`]: {
    icon: <Warning />,
    color: COLORS.RED_1,
    text: RICHMENU.ERROR_MSG
  }
}

interface RichmenuStatusProps {
  data: Domain.Richmenu.FetchedRichmenuType
}

export const RichmenuStatus = (props: RichmenuStatusProps) => {
  const { data } = props
  const classes = useStyles({})
  const dateFormat = 'yyyy/MM/dd HH:mm'
  return (
    <>
    {
      data?.status && 
        <div className={classes.wrapper} style={{color: colorList[`${data.status}`].color}}>
          <div className={classes.icon}>
            {colorList[`${data.status}`].icon}
          </div>
          <Typography variant="body2">
            {
              functions.common.changText(
                colorList[`${data.status}`].text,
                format(new Date(data.submittedDate as string), dateFormat),
                format(new Date(data.triggerTime as string), dateFormat)
              )
            }
          </Typography>
        </div>
    }
    </>
  )
}
