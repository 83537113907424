import React from 'react'
import {
  MODAL_CONFIRM_UPDATE_OPENED_TITLE,
  MODAL_CONFIRM_UPDATE_OPENED_CONTENT,
  MODAL_CONFIRM_UPDATE_OPENED_OK,
} from '../../../../constants/event'
import { PrimaryConfirmModal } from '../../../molecules/ModalContainer/PrimaryDialogTemplate/PrimaryConfirmDialog'

interface ConfirmCancelModalProps {
  onSubmit: any
}

export const ConfirmUpdateOpenedModal: React.FC<ConfirmCancelModalProps> = props => {
  return (
    <PrimaryConfirmModal
      header={MODAL_CONFIRM_UPDATE_OPENED_TITLE}
      content={MODAL_CONFIRM_UPDATE_OPENED_CONTENT}
      onSubmit={props.onSubmit}
      label={MODAL_CONFIRM_UPDATE_OPENED_OK}
      skipButton={true}
    />
  )
}
