import { combineReducers } from "redux";
import * as types from '../../constants/types';
import { AudienceInfo } from "./audience.action";

const initialState: any = {
  isLoading: false,
  isProcessing: false,
  hasError: false,
  data: undefined,
  error: undefined,
};

export function stores(state = initialState, action: {type: string, payload: any}) {
  switch (action.type) {
    case types.GET_STORES:
      return {
        ...state,
        isLoading: true,
      }
    case types.GET_STORES_SUCCESS:
      return {
        ...state,
        error: null,
        hasError: false,
        isLoading: false,
        data: action.payload
      }
    case types.GET_STORES_ERROR:
      return {
        ...state,
        data: null,
        isLoading: false,
        hasError: true,
        error: action.payload
      }
    default:
      return state
  }
}

export function newAudienceInfo(state = initialState, action: {type: string, payload: AudienceInfo}) {
  switch (action.type) {
    case types.SET_NEW_AUDIENCE_INFO:
      return {
        ...state,
        data: action.payload
      }
    default:
      return state
  }
}

const audienceReducer = combineReducers({
  stores,
  newAudienceInfo
})

export default audienceReducer
