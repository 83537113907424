import { Dispatch } from 'redux'
import {
  getRichMenu,
  getRichMenuSuccess,
  getRichMenuError,
  getSubMenu,
  getSubMenuSuccess,
  getSubMenuError,
} from './richmenu.action'
import { getThunk } from '../../libs/api'
import { setLoading } from '../loading/loading.action'

export function fetchRichmenu(accessToken: string | undefined, tenantId: string, subMenuLimit: number): any {
  return function (dispatch: Dispatch) {
    dispatch(setLoading(true))
    dispatch(getRichMenu())
    dispatch(getThunk(`rich-menu/submenus?limit=${subMenuLimit}`, accessToken, tenantId))
      .then((submenuRes: any) => {
        dispatch(getThunk('rich-menu/current', accessToken, tenantId))
          .then((res: any) => {
            dispatch(getSubMenuSuccess(res, submenuRes))
            dispatch(setLoading(false))
          })
          .catch((err: any) => {
            dispatch(getRichMenuError(err, submenuRes))
            dispatch(setLoading(false))
          })
      })
      .catch((err: any) => {
        dispatch(getSubMenuError(err))
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}
