import * as React from 'react'
import { Domain } from '../features'

/**
 * TenantContext API
 */

interface TenantValue {
  tenantId: Domain.Tenant.Id
}

interface GivenValue {
  tenantId: Domain.Tenant.Id
}

interface TenantProviderProps {
  value: GivenValue
}

/**
 * AuthContext API
 */
const TenantContext = React.createContext<TenantValue>({
  tenantId: ''
})

const useTenant = (givenValue: GivenValue): TenantValue => {
  const { tenantId } = givenValue

  return {
    tenantId
  }
}

export const TenantProvider: React.FC<TenantProviderProps> = props => {
  const { value: givenValue, children } = props
  const value = useTenant(givenValue)

  return <TenantContext.Provider value={value}>{children}</TenantContext.Provider>
}

export const TenantConsumer = TenantContext.Consumer

export default TenantContext
