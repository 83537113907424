import { Box, Typography } from '@material-ui/core'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { MODAL_NOTI_AGREE_BTN } from '../../../../../constants/richmenu'
import { PrimaryConfirmModal } from '../../../../molecules/ModalContainer/PrimaryDialogTemplate/PrimaryConfirmDialog'

interface NoticeCreateAudienceSuccessProps {
  pieces: number,
  onSubmit: any
}

export const NoticeCreateAudienceSuccessModal: FC<NoticeCreateAudienceSuccessProps> = (props) => {
  const { pieces } = props
  const { t } = useTranslation('audience')

  const Content = () => {
    return (
      <Box textAlign="center">
        <p>{t('modals.noticeCreateAudience.content')}</p>
        <Box color="grey.600" clone>
          <Typography className="nav-link" align="center" variant="h6">{t('modals.noticeCreateAudience.draw', { value: pieces || 0 })}</Typography>
        </Box>
      </Box>
    )
  }
  
  return (
    <PrimaryConfirmModal
      header={t('modals.noticeCreateAudience.title')}
      content={<Content/>}
      onSubmit={props.onSubmit}
      label={MODAL_NOTI_AGREE_BTN}
      skipButton={false}
    />
  )
}
