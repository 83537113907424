import React, { FC } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
//
import { Button } from '../../atoms'

/**
 * Interface
 */

interface SecondaryButtonPresenterProps {
  className?: string
  onClick: () => void
}

interface SecondaryButton {
  className?: string
  onClick: () => void
}

/**
 * Styles
 */

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      backgroundColor: theme.palette.common.white,
    },
  }),
)

/**
 * Presenter
 */

export const SecondaryButtonPresenter: FC<SecondaryButtonPresenterProps> = props => {
  const { className } = props
  const classes = useStyles({})
  return <Button className={`${classes.button} ${className ? className : ''}`} variant="outlined" {...props} />
}

/**
 * Combine
 */

export const SecondaryButton: FC<SecondaryButton> = props => {
  return <SecondaryButtonPresenter {...props} />
}
