export const ERR_TITLE = ' 入力した内容を確認してください。'
export const ERR_GET_DATA = 'データの取得に失敗しました。'
export const ERR_GET_EVENT = 'キャンペーン情報の取得に失敗しました。'
export const ERR_POST_EVENT = 'キャンペーン情報の登録に失敗しました。'
export const ERR_PUT_EVENT = 'キャンペーン情報の更新に失敗しました。'
export const ERR_DELETE_EVENT = 'キャンペーンの削除に失敗しました。'
export const ERR_UPDATE_CONFLICT = 'こちらのキャンペーンは他のユーザーによって更新されています。\n画面を開き直してから、改めて編集し直して保存して下さい。'
export const ERR_SUBMIT_VALIDATE = '未入力の項目があるため公開予約できません。'
export const ERR_SUBMIT_SAVE_VALIDATE = '未入力の項目があるため保存できません。'
export const ERR_PREVIEW_VALIDATE = '未入力の項目があるためプレビューできません。'
export const ERR_SAVE_VALIDATE = 'キャンペーンIDが未入力、または数字以外が入力されているため保存できません。'
export const ERR_EVENT_IMAGE_FILE_SIZE = '一覧用イメージのファイルサイズが1MBを超えています。'
export const ERR_EVENT_IMAGE_DETAIL_FILE_SIZE = '詳細用イメージのファイルサイズが2MBを超えています。'
export const ERR_GET_EVENT_CSV = 'キャンペーンエントリー結果のCSVファイルが存在しません。'

export const HELPER_TEXT = '必須項目です'
export const REQUIRED_LABEL = '(必須)'
export const ID_LABEL = 'キャンペーンID'
export const EVENT_START_DATE_LABEL = 'エントリー開始日時'
export const EVENT_END_DATE_LABEL = 'エントリー終了日時'
export const HEADLINE_TEXT_LABEL = '見出し説明文'
export const DESCRIPTION_LABEL = '詳細説明文'
export const DESCRIPTION_HELPER_TEXT_1 = '以下のタグが使えます。'
export const DESCRIPTION_HELPER_TEXT_2 = '強調　<b>対象文言</b>'
export const DESCRIPTION_HELPER_TEXT_3 = '改行　改行したい箇所に<br>'
export const DESCRIPTION_HELPER_TEXT_4 = 'リンク　<a href="https://www.tokyu.co.jp">リンク</a>'
export const DISPLAY_END_DATE_LABEL = '一覧表示終了日時'
export const DISPLAY_INDEX_LABEL = 'キャンペーン一覧表示有無'
export const DISPLAY_INDEX_ON_LABEL = '表示する'
export const DISPLAY_INDEX_HELPER_TEXT = 'ONにするとキャンペーンの一覧に表示されます'
export const DISPLAY_DURATION_LABEL = 'キャンペーンエントリー期間表示有無'
export const DISPLAY_DURATION_ON_LABEL = '表示する'
export const DISPLAY_DURATION_HELPER_TEXT = 'ONにするとキャンペーン一覧およびキャンペーン詳細画面にキャンペーンエントリー期間が表示されます'
export const CHECK_REGISTER_LABEL = 'TOKYU POINT連携必須チェック有無'
export const CHECK_REGISTER_ON_LABEL = '必須にする'
export const CHECK_REGISTER_HELPER_TEXT = 'ONにすると詳細画面を表示する際にTOKYU POINT連携が未連携の場合、カード登録画面へ遷移します'
export const DISPLAY_UPLOAD_LABEL = '画像投稿フォーム有無'
export const DISPLAY_UPLOAD_ON_LABEL = '表示する'
export const DISPLAY_UPLOAD_HELPER_TEXT = 'ONにするとキャンペーン詳細画面で画像の投稿が可能になります'
export const IMAGE_TEXT_LABEL = '画像投稿エリアのタイトル'
export const LIST_IMAGE_LABEL = '一覧イメージ'
export const DETAIL_IMAGE_LABEL = '詳細イメージ'
export const EMPTY_LIST_IMAGE_LABEL_01 = 'イベントイメージを添付してください'
export const EMPTY_LIST_IMAGE_LABEL_02 = '■推奨　＜一覧用＞'
export const EMPTY_LIST_IMAGE_LABEL_03 = '東急ストア　まる得以外'
export const EMPTY_LIST_IMAGE_LABEL_04 = '　width：1242Pixel　height：任意（例：621Pixel）'
export const EMPTY_LIST_IMAGE_LABEL_05 = '東急ストア　まる得'
export const EMPTY_LIST_IMAGE_LABEL_06 = '　width：568Pixel　height：568Pixel'
export const EMPTY_LIST_IMAGE_LABEL_07 = '■サイズ'
export const EMPTY_LIST_IMAGE_LABEL_08 = '　1MB以下'
export const EMPTY_LIST_IMAGE_LABEL_09 = '■ファイル形式'
export const EMPTY_LIST_IMAGE_LABEL_10 = '　png, jpg'
export const EMPTY_DETAIL_IMAGE_LABEL_01 = 'イベントイメージを添付してください'
export const EMPTY_DETAIL_IMAGE_LABEL_02 = '■推奨　＜詳細用＞'
export const EMPTY_DETAIL_IMAGE_LABEL_03 = '　width：1242Pixel　height：任意（例：1242Pixel）'
export const EMPTY_DETAIL_IMAGE_LABEL_04 = '■サイズ'
export const EMPTY_DETAIL_IMAGE_LABEL_05 = '　2MB以下'
export const EMPTY_DETAIL_IMAGE_LABEL_06 = '■ファイル形式'
export const EMPTY_DETAIL_IMAGE_LABEL_07 = '　png, jpg'
export const EMPTY_IMAGE_FILE_NAME_LABEL = 'イメージが選択されていません'

export const ADD_IMAGE = 'イメージを選択する'
export const PREVEW_DESCRIPTION = '入力内容を保存すると、プレビューができるようになります'
export const PREVIEW_LIST_BTN_TITLE = '一覧プレビュー'
export const PREVIEW_DETAIL_BTN_TITLE = '詳細プレビュー'
export const RESERVE_OPEN_BTN_TITLE = '公開予約'
export const RESERVE_CANCEL_BTN_TITLE = '予約取消'
export const DELETE_BTN_TITLE = '削除'
export const SAVE_BTN_TITLE = '保存'
export const DUPLICATE_BTN_TITLE = '複製'
export const CSV_BTN_TITLE = 'CSV'
export const TOOL_TIP_SUB_MENU_ID = 'サブメニューID'
export const TOOL_TIP_IMAGE_FILE_NAME = '画像ファイル名'

export const MODAL_CONFIRM_DELETE_TITLE = 'キャンペーンの削除'
export const MODAL_CONFIRM_DELETE_CONTENT = '現在のキャンペーンを削除しますか？'
export const MODAL_CONFIRM_DELETE_OK = '削除'
export const MODAL_CONFIRM_UPDATE_RESERVED_TITLE = '公開予約'
export const MODAL_CONFIRM_UPDATE_RESERVED_CONTENT = '作成中のキャンペーンを公開予約しますか？'
export const MODAL_CONFIRM_UPDATE_RESERVED_OK = '公開予約'
export const MODAL_CONFIRM_CANCEL_RESERVED_TITLE = '予約取消'
export const MODAL_CONFIRM_CANCEL_RESERVED_CONTENT = '現在の予約を取り消しますか？'
export const MODAL_CONFIRM_CANCEL_RESERVED_OK = '予約取消'
export const MODAL_CONFIRM_UPDATE_OPENED_TITLE = '更新の確認'
export const MODAL_CONFIRM_UPDATE_OPENED_CONTENT = '当キャンペーンは公開中です。更新を押すと変更が即時反映され、公開されます。更新しますか？'
export const MODAL_CONFIRM_UPDATE_OPENED_OK = '更新'
export const MODAL_CONFIRM_DELETE_CANCEL = 'キャンセル'
export const MODAL_CONFIRM_DOWNLOAD = 'ダウンロード'
export const MODAL_CONFIRM_DOWNLOAD_CANCEL = 'キャンセル'
export const MODAL_CONFIRM_DOWNLOAD_HEADER = 'CSVのダウンロード'
export const MODAL_CONFIRM_DOWNLOAD_TITLE = 'このキャンペーンのCSVファイルをダウンロードしますか？'
export const MODAL_PREVIEW_DOWNLOAD_LABEL_ID = 'ID'
export const MODAL_PREVIEW_DOWNLOAD_LABEL_HEADLINE_TEXT = '見出し'
export const MODAL_PREVIEW_DOWNLOAD_LABEL_DATE = 'イベント期間'
export const MODAL_PREVIEW_LIST_TITLE = '一覧プレビュー'
export const MODAL_PREVIEW_DETAIL_TITLE = '詳細プレビュー'
export const MODAL_CLOSE_BTN = '閉じる'

export const SAVE_MESSAGE = '{value0} に 以下の内容を 保存 しました'
export const RESERVED_MSG = '{value0} に 以下の内容を 予約 しました。{value1} に 公開予定 です'
export const CANCELED_MSG = '{value0} に 以下の内容が 予約取消 されました'
