import React, { FC } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
//
import { Box, Button, Typography } from '../../atoms'

/**
 * interface
 */

export interface NotFoundErrorSectionPresenterProps {
  actions: {
    handleDashboard: () => void
  }
}

export interface NotFoundErrorSectionProps {
  handleDashboard: () => void
}

/**
 * Style
 */

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    logoType: {
      textAlign: 'center',
    },
    inner: {
      width: '400px',
      padding: '40px',
    },
    sectionTitle: {
      textAlign: 'center',
      marginBottom: '2rem',
    },
    sectionSubTitle: {
      textAlign: 'center',
    },
    sectionBody: {
      textAlign: 'left',
      marginBottom: '2rem',
    },
    copyright: {
      display: 'block',
      marginTop: '1rem',
      textAlign: 'center',
    },
  }),
)

/**
 * Presenter
 */

export const NotFoundErrorSectionPresenter: FC<NotFoundErrorSectionPresenterProps> = props => {
  const {
    actions: { handleDashboard },
  } = props
  const classes = useStyles({})
  return (
    <div>
      <Box boxShadow={2}>
        <div className={classes.inner}>
          <section>
            <Typography variant="h3" component="h1" className={classes.sectionTitle}>
              Error Code 404
            </Typography>

            <Typography variant="h4" component="h2" className={classes.sectionSubTitle}>
              お探しのページが見つかりません。
            </Typography>

            <p className={classes.sectionBody}>
              大変申し訳ございませんが、お探しのページは、 削除されたか、URLが間違っている可能性があります。
              アドレスを確認してください。
            </p>
            <Button variant="contained" color="primary" fullWidth={true} size="large" onClick={handleDashboard}>
              トップページへ
            </Button>
          </section>
        </div>
      </Box>
    </div>
  )
}

/**
 * Combine
 */

export const NotFoundErrorSection: FC<NotFoundErrorSectionProps> = props => {
  const { handleDashboard } = props
  return <NotFoundErrorSectionPresenter actions={{ handleDashboard }} />
}
